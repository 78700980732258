var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "breakdownRef",
      staticClass: "charges-breakdown",
      attrs: { "data-test": "breakdown" }
    },
    [
      _c(
        "header",
        { staticClass: "charges-breakdown__header" },
        [
          _c(
            "div",
            { staticClass: "charges-breakdown__headline" },
            [
              _c(
                "h2",
                {
                  staticClass: "charges-breakdown__title subsection-title",
                  attrs: { "data-test": "breakdown-title" }
                },
                [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
              ),
              _vm._v(" "),
              _c("Chip", {
                staticClass: "chip",
                attrs: {
                  text: _vm.isClosed
                    ? _vm.$t("finance/charges-breakdown-closed-label", {
                        date: _vm.formattedDate,
                        time: _vm.formattedTime
                      })
                    : _vm.$t("finance/charges-breakdown-open-label"),
                  category: _vm.isClosed ? "green" : "primary",
                  "data-test": "breakdown-state"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.canAddSurcharge
            ? _c("Button", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.isCompact
                      ? _vm.$t(
                          "finance/charges-breakdown-add-surcharge-tooltip"
                        )
                      : undefined,
                    expression:
                      "isCompact ? $t('finance/charges-breakdown-add-surcharge-tooltip') : undefined"
                  }
                ],
                staticClass: "charges-breakdown__button",
                attrs: {
                  category: _vm.isCompact ? "tertiary" : "secondary",
                  text: _vm.isCompact
                    ? ""
                    : _vm.$t("finance/charges-breakdown-add-surcharge-text"),
                  materialIcon: "add",
                  "data-test": "surcharge-correction-button"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("add-charge")
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("table", { staticClass: "charges-breakdown__table" }, [
        _c(
          "tbody",
          [
            _c("BreakdownRow", {
              attrs: {
                title: _vm.baseTitle,
                value: _vm.formatMoneyWithZeroForNull(_vm.baseAmount)
              }
            }),
            _vm._v(" "),
            _vm.baseCharge
              ? [
                  _vm.baseChargeCorrections &&
                  _vm.baseChargeCorrections.length > 0
                    ? _c("BreakdownRow", {
                        attrs: {
                          title: _vm.$t(
                            "finance/charges-breakdown-initial-amount-label"
                          ),
                          value: _vm.formatMoneyWithZeroForNull(
                            _vm.baseCharge.initialAmount
                          ),
                          modifier: "correction"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.baseChargeCorrections, function(charge) {
                    return _c("BreakdownRow", {
                      key: "base-" + charge.id,
                      attrs: {
                        title: _vm.$t(
                          "finance/charges-breakdown-correction-label"
                        ),
                        value: _vm.formatMoneyWithZeroForNull(
                          charge.correctionAmount
                        ),
                        modifier: "correction"
                      }
                    })
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.extraCharges
              ? [
                  _vm._l(_vm.extraCharges, function(charge) {
                    return [
                      _c("BreakdownRow", {
                        key: "charge-" + charge.id,
                        attrs: {
                          title: _vm.$t(
                            _vm.getIssueCategoryTitle(charge.issueCategory)
                          ),
                          value: _vm.formatMoneyWithZeroForNull(charge.amount),
                          note: charge.summary
                        }
                      }),
                      _vm._v(" "),
                      charge.amountCharge &&
                      charge.amountCharge.chargeCorrections &&
                      charge.amountCharge.chargeCorrections.length > 0
                        ? [
                            _c("BreakdownRow", {
                              key: "initial-" + charge.id,
                              attrs: {
                                title: _vm.$t(
                                  "finance/charges-breakdown-initial-charge-label"
                                ),
                                value: _vm.formatMoneyWithZeroForNull(
                                  charge.amountCharge.initialAmount
                                ),
                                modifier: "correction"
                              }
                            }),
                            _vm._v(" "),
                            _vm._l(
                              charge.amountCharge.chargeCorrections,
                              function(correction) {
                                return _c("BreakdownRow", {
                                  key: "correction-" + correction.id,
                                  attrs: {
                                    title: _vm.$t(
                                      "finance/charges-breakdown-correction-label"
                                    ),
                                    value: _vm.formatMoneyWithZeroForNull(
                                      correction.correctionAmount
                                    ),
                                    modifier: "correction"
                                  }
                                })
                              }
                            )
                          ]
                        : _vm._e()
                    ]
                  })
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("BreakdownRow", {
              attrs: {
                title: _vm.totalTitle,
                value: _vm.formatMoneyWithZeroForNull(_vm.totalNetAmount),
                modifier: "strong",
                hasBorder: false
              }
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.canCloseBreakdown
        ? _c("Button", {
            staticClass: "charges-breakdown__close-button",
            attrs: {
              category: "secondary",
              text: _vm.$t("finance/charges-breakdown-close-button-label", {
                title: _vm.title
              }),
              "data-test": "close-breakdown-button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("close-breakdown")
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }