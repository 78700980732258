
import { defineComponent, computed, h, ref } from '@vue/composition-api'
import { Dialog } from '@sennder/plankton'
import { provideSetTitle } from './WizardModalProvideInject'
import Stepper from './Stepper.vue'

export default defineComponent({
  props: {
    activeStep: { type: Number, default: 0 }
  },
  setup(props, context) {
    const stepsCount = computed(
      () =>
        context.slots
          .default()
          .filter(vnode => vnode?.componentOptions?.tag === 'WizardModalStep').length
    )
    const isLastStep = computed(() => stepsCount.value - 1 === props.activeStep)
    const nextButtonText = computed(() => {
      const key = (isLastStep.value ? 'finance/wizard-modal-final-button-label' : 'finance/wizard-modal-next-button-label')
      return context.root.$t(key)
    })
    const backButtonText = computed(() => (props.activeStep === 0 ? null : context.root.$t('finance/wizard-modal-back-button-label')))
    const title = ref<string>('')

    const handleNext = () => {
      context.emit('nextStep', isLastStep.value)
    }
    const handleBack = () => {
      if (props.activeStep === 0) return
      context.emit('prevStep')
    }

    provideSetTitle(title)

    /**
     * Small render function needed to render active WizardModalStep dynamically
     * without putting each one in a new slot.
     */
    return () => {
      const wizardModalSteps = context.slots
        .default()
        .filter(item => item.componentOptions?.tag)
      return h(Dialog, {
        attrs: {
          ...context.attrs,
          title: `${props.activeStep + 1}. ${title.value}`,
          inPlace: true,
          confirmButtonText: nextButtonText.value,
          cancelButtonText: backButtonText.value
        },
        on: {
          ...context.listeners,
          cancel: handleBack,
          confirm: handleNext
        },
        scopedSlots: {
          default: () => [wizardModalSteps[props.activeStep]],
          'left-side-footer': () =>
            h(Stepper, {
              attrs: {
                currentStep: props.activeStep,
                stepsLength: wizardModalSteps.length
              }
            })
        }
      })
    }
  }
})
