var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "claim-status-view" },
    [
      _vm.editable && _vm.transitions.length > 0
        ? _c(
            "ContextMenu",
            {
              staticClass: "claim-status-view__menu",
              attrs: {
                placement: "bottom-start-claims-menu",
                "data-test": "claim-status-menu"
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "menu-items",
                    fn: function() {
                      return _vm._l(_vm.transitions, function(transition) {
                        return _c("ContextMenuItem", {
                          key: transition.code,
                          staticClass: "claim-status-view__item",
                          attrs: {
                            icon: "",
                            text: _vm.$t(
                              _vm.statusTranslationKeys[transition.code]
                            ),
                            "data-test":
                              "claim-status-menu-item--" + transition.code
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("update-status", transition.code)
                            }
                          }
                        })
                      })
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2792407159
              )
            },
            [
              _c(
                "div",
                { staticClass: "claim-status-view__trigger" },
                [
                  _c("ClaimStatusLabel", {
                    staticClass:
                      "claim-status-view__menu-label claim-status-view__menu-label--active",
                    attrs: {
                      status: _vm.status,
                      statusDescription: _vm.statusDescription
                    }
                  }),
                  _vm._v(" "),
                  _c("Icon", {
                    staticClass: "claim-status-view__down-icon",
                    attrs: { materialIcon: "expand_more" }
                  })
                ],
                1
              )
            ]
          )
        : _c("ClaimStatusLabel", {
            attrs: {
              status: _vm.status,
              statusDescription: _vm.statusDescription
            }
          }),
      _vm._v(" "),
      _vm.showMessageInfo
        ? _c("Icon", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.notes,
                expression: "notes"
              }
            ],
            staticClass: "claim-status-view__message-info",
            attrs: {
              name: "messageInfo",
              "data-status": _vm.status,
              "data-test": "claim-message-icon"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }