var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    _vm._b(
      {
        staticClass: "confirmation-dialog",
        attrs: { show: _vm.showDialog, inPlace: "" },
        on: { close: _vm.cancel, confirm: _vm.confirm, cancel: _vm.cancel }
      },
      "Dialog",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }