

















import { Dialog } from '@sennder/plankton'

export default {
  components: { Dialog },
  inheritAttrs: false,
  props: {
    orderId: { type: Number, required: true },
    title: { type: String, required: true }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
    }
  }
}
