







































































import { defineComponent, ref, computed } from '@vue/composition-api'
import useModal from '/@/compositions/useModal'
import {
  Claim,
  ClaimReporter,
} from '/@/services/types'
import { ApproveClaimPayload } from '/@/services/claim-service'
import {
  requiredValidator,
  nonZeroValueValidator,
  translateValidators
} from '/@/field-validators'
import useStateReset from '/@/compositions/useStateReset'
import { Dialog, Form, PrimitiveDropdownField, NumericInputField, LimitedTextAreaField } from '@sennder/plankton'
import { ClaimReporterLowerCase } from '../../services/types'
import { ClaimTypeTranslationKeys } from '/@/compositions/useClaims'

export default defineComponent({
  components: {
    Dialog,
    Form,
    PrimitiveDropdownField,
    NumericInputField,
    LimitedTextAreaField
  },
  setup(_props, context) {
    const form = ref(null)
    const amountModel = ref(null)
    const approvalNote = ref(null)
    const claimTypeOption = ref<{code: string, description: string}>(null)
    let respond: (value: ApproveClaimPayload) => void = null

    const claimTypeOptions = computed(() => {
      if (!claimTypeOption.value) return []
      else return [claimTypeOption.value]
    })

    const resetState = useStateReset([amountModel, approvalNote, claimTypeOption])
    const modal = useModal({
      onBeforeClose() {
        respond(null)
      },
      onConfirm() {
        respond({
          amount: amountModel.value,
          notes: approvalNote.value
        })
      },
      isInvalid: () => Boolean(form.value.submit().hasErrors)
    })

    const requestApproval = (claim: Claim, side: ClaimReporter) => {
      amountModel.value = getClaimSideAmount(claim, side)
      claimTypeOption.value = getClaimTypeOption(claim)
      modal.open()
      return new Promise<ApproveClaimPayload>(resolve => {
        respond = resolve
      })
    }

    const getClaimSideAmount = (claim: Claim, side: ClaimReporter) => {
      return claim[side.toLowerCase() as ClaimReporterLowerCase].requestedAmount
    }

    const getClaimTypeOption = (claim: Claim) => ({
      code: claim.claimType,
      description: context.root.$t(ClaimTypeTranslationKeys[claim.claimType]) as string
    })

    const $t = context.root.$t.bind(context.root)
    const amountValidators = translateValidators($t, [requiredValidator, nonZeroValueValidator])

    return {
      modal,
      amountModel,
      approvalNote,
      claimTypeOption,
      claimTypeOptions,
      requestApproval,
      amountValidators,
      form,
      resetState
    }
  }
})
