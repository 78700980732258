var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { staticClass: "finance-sidebar", attrs: { tag: "div", name: "sidebar" } },
    [
      _vm.getComponentOrder("RouteInfo") > -1
        ? _c("RouteInfo", {
            key: "RouteInfo",
            staticClass: "finance-sidebar__item",
            style: {
              "--widget-order": _vm.getComponentOrder("RouteInfo")
            },
            attrs: { order: _vm.order, stops: _vm.stops }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.getComponentOrder("CarrierVehicleInfo") > -1
        ? _c("CarrierVehicleInfo", {
            key: "CarrierVehicleInfo",
            staticClass: "finance-sidebar__item",
            style: {
              "--widget-order": _vm.getComponentOrder("CarrierVehicleInfo")
            },
            attrs: { transferId: _vm.order.transferId }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.getComponentOrder("CarrierContactInfo") > -1
        ? _c("CarrierContactInfo", {
            key: "CarrierContactInfo",
            staticClass: "finance-sidebar__item",
            style: {
              "--widget-order": _vm.getComponentOrder("CarrierContactInfo")
            },
            attrs: { order: _vm.order }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.getComponentOrder("CarrierBillingInfo") > -1
        ? _c("CarrierBillingInfo", {
            key: "CarrierBillingInfo",
            staticClass: "finance-sidebar__item",
            style: {
              "--widget-order": _vm.getComponentOrder("CarrierBillingInfo")
            },
            attrs: { carrierId: _vm.order.carrierCompanyId }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.getComponentOrder("LoadInfo") > -1
        ? _c("LoadInfo", {
            key: "LoadInfo",
            staticClass: "finance-sidebar__item",
            style: {
              "--widget-order": _vm.getComponentOrder("LoadInfo")
            },
            attrs: { order: _vm.order }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }