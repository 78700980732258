














import { computed, defineComponent } from '@vue/composition-api'
import range from 'lodash/range'

export default defineComponent({
  props: {
    currentStep: { type: Number, required: true },
    stepsLength: { type: Number, required: true }
  },
  setup(props) {
    const steps = computed(() =>
      range(props.stepsLength).map(i => i <= props.currentStep)
    )

    return { steps }
  }
})
