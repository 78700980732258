export const ORDER_STATES = Object.freeze([
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED'
] as const)

export type OrderState = typeof ORDER_STATES[number]

/**
 * Checks that the order in current state
 * has had been in the target state previously
 * @param currentState state of the order against which we check
 * @param targetState  state which the order had previously
 */
export const hasHadState = (currentState: OrderState, targetState: OrderState) => {
  return ORDER_STATES.indexOf(targetState) <= ORDER_STATES.indexOf(currentState)
}
