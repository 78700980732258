import { ServiceError } from './types';
import axios, { AxiosResponse } from 'axios'
import { appOptions } from '/@/app-options'
import { transformKeysToCamelCase } from './casing'

export * from './finance-service'
export * from './types'

export const isServiceError = (error: unknown): error is ServiceError  => {
    return (error instanceof Object) && ('errorData' in error) && ('errorCode' in error)
}

export function amendResponseErrorMessage(errorMapper: Record<string, string>) {
  return async <T>(responsePromise: Promise<AxiosResponse<T>>) => {
    try {
      return await responsePromise
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorObj = transformKeysToCamelCase(error.response.data)
        if (isServiceError(errorObj) && errorObj.errorCode in errorMapper) {
          const errorMessage = appOptions.i18n.t(errorMapper[errorObj.errorCode], errorObj.errorData)
          throw { ...errorObj, errorMessage } as ServiceError
        }
      }
      throw {
        errorCode: 'UNEXPECTED_ERROR',
        errorData: {},
        errorMessage: appOptions.i18n.t('finance/service-error-unexpected')
      } as ServiceError
    }
  }
}

