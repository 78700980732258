import { claimsAccesorialsClient } from '/@/services/api'
import { transformKeysToCamelCase } from '/@/services/casing'
import {ClaimBoardColumnData} from '/@/services/types'
import {transformClaims} from '/@/services/claimsAccessorialsMappers'

export const searchClaimBoardClaimsAccesorials = async (columnType: string, page = '1', size = '10', {
 search,
 date,
}: {
  search?: string
  date?: string
}): Promise<ClaimBoardColumnData> => {
  const params: {
    search?: string
    date?: string,
    page?: string,
    size?: string,
    state?: string
  } = {}
  if (search) {
    const upperCasedSearch = search.toUpperCase()
    params.search = upperCasedSearch
  }
  if (date) {
    params.date = date
  }
  if (page) {
    params.page = page
  }
  if (size) {
    params.size = size
  }
  params.state = columnType
  const response = (await claimsAccesorialsClient.get('/api/claims/', { params }))

  const transformedClaims = transformClaims(response.data.claims)
  const unifiedResponse = {
    count: response.data.count,
    nextUrl: response.data.next_url,
    previousUrl: response.data.previous_url,
    results: transformedClaims
  }
  return transformKeysToCamelCase<ClaimBoardColumnData>(unifiedResponse)
}
