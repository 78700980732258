


































import { defineComponent, toRef, computed, ref, PropType } from '@vue/composition-api'
import ChargesBreakdown from './ChargesBreakdown.vue'
import ExtraChargeCorrectionModal from './ExtraChargeCorrectionModal.vue'
import ConfirmationModal from '/@/components/shared/ConfirmationModal.vue'
import useExtraCharges from '/@/compositions/useExtraCharges'
import { OrderPaymentInformation } from '/@/services/types'
import useOrderPaymentStatus from '/@/compositions/useOrderPaymentStatus'

export default defineComponent({
  components: {
    ChargesBreakdown,
    ExtraChargeCorrectionModal,
    ConfirmationModal
  },
  props: {
    orderId: {
      type: Number,
      required: true
    },
    paymentInfo: {
      type: Object as PropType<OrderPaymentInformation>,
      default: null
    },
    compact: {
      type: Boolean,
      default: false
    },
    canAddSurcharge: {
      type: Boolean,
      default: false
    },
    canCloseBreakdown: {
      type: Boolean,
      default: false
    },
    basePrice: {
      type: Number,
      required: true
    },
    totalNetPrice: {
      type: Number,
      required: true
    },
    isCancelled: {
      type: Boolean,
      default: false
    },
    refreshInvoicesOnNewCharges: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const closePriceModal = ref(null)
    const orderId = toRef(props, 'orderId')
    const isCancelled = toRef(props, 'isCancelled')
    const { customerCharges } = useExtraCharges(orderId, ['CUSTOMER'], isCancelled)
    const { closeCustomerPrice } = useOrderPaymentStatus(orderId)

    const extraChargeCorrectionModal = ref(null)
    const addExtraChargeOrCorrection = () => extraChargeCorrectionModal.value.modal.open()
    const closePriceAfterConfirmation = async () => {
      const result: boolean = await closePriceModal.value.askConfirmation()
      if (result) {
        await closeCustomerPrice(orderId.value)
        context.emit('breakdown-closed')
      }
    }
    const customerPriceClosedTimestamp = computed(
      () => props.paymentInfo?.customerPriceClosedTimestamp
    )
    const isCustomerPriceClosed = computed(() => props.paymentInfo?.isCustomerPriceClosed)

    return {
      closePriceModal,
      extraChargeCorrectionModal,
      addExtraChargeOrCorrection,
      customerCharges,
      closePriceAfterConfirmation,
      customerPriceClosedTimestamp,
      isCustomerPriceClosed
    }
  }
})
