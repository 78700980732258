import Vue, { VueConstructor } from 'vue'
import * as Sentry from '@sentry/browser'
import createAuth0Client, {
  PopupLoginOptions,
  Auth0Client,
  RedirectLoginOptions,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from '@auth0/auth0-spa-js'

import { getJWTCookieValue, setJWTCookieValue } from '@/userAuthToken'

type Auth0PluginOptions = {
  onRedirectCallback: RedirectCallback
  redirectUri: string
  domain: string
  clientId: string
  audience?: string
  [key: string]: string | RedirectCallback | undefined
}
export type RedirectCallback = (appState: any) => void

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance: any

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: Auth0PluginOptions) => {
  if (instance) return instance

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true as boolean,
        isAuthenticated: false as boolean | undefined,
        user: {} as any,
        auth0Client: null as Auth0Client | null,
        popupOpen: false,
        error: null,
      }
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
      } as any)

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e

        Sentry.captureException(e, {
          tags: {
            auth: true,
          },
        })
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        // workaround till we fix MFE token
        if (this.isAuthenticated) {
          const token = await this.auth0Client.getTokenSilently()
          setJWTCookieValue(token)
        }
        this.loading = false
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(options: PopupLoginOptions, config: any) {
        this.popupOpen = true

        try {
          await this.auth0Client?.loginWithPopup(options, config)
          this.user = await this.auth0Client?.getUser()
          this.isAuthenticated = await this.auth0Client?.isAuthenticated()
          if (this.isAuthenticated) {
            const token = await this.auth0Client?.getTokenSilently()
            setJWTCookieValue(token)
          }
          this.error = null
        } catch (e) {
          this.error = e
          // eslint-disable-next-line
          console.error(e)

          Sentry.captureException(e, {
            tags: {
              auth: true,
            },
          })
        } finally {
          this.popupOpen = false
        }

        this.user = await this.auth0Client?.getUser()
        this.isAuthenticated = true
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client?.handleRedirectCallback()
          this.user = await this.auth0Client?.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e

          Sentry.captureException(e, {
            tags: {
              auth: true,
            },
          })
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: RedirectLoginOptions) {
        return this.auth0Client?.loginWithRedirect(o)
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: GetIdTokenClaimsOptions) {
        return this.auth0Client?.getIdTokenClaims(o)
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o: GetTokenSilentlyOptions) {
        return this.auth0Client?.getTokenSilently(o)
      },
      /** Gets the access token using a popup window */

      getTokenWithPopup(o: GetTokenWithPopupOptions) {
        return this.auth0Client?.getTokenWithPopup(o)
      },
      /** Logs the user out and removes their session on the authorization server */
      logout(o: LogoutOptions) {
        return this.auth0Client?.logout(o)
      },
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue: VueConstructor, options: Auth0PluginOptions) {
    Vue.prototype.$auth = useAuth0(options)
  },
}

export async function getJWTAuthTokenWithParams(
  audience?: string,
  scope?: string
): Promise<string> {
  const authService = getInstance()
  let token = ''
  let params = undefined
  if (audience) {
    params = {
      audience,
      scope: scope || null,
    }
  }
  token = await authService?.auth0Client?.getTokenSilently(params)
  return token
}

export async function getJWTAuthToken(): Promise<string> {
  const authService = getInstance()
  let token
  // if we already have instance and authentication has completed
  if (authService && !authService.loading) {
    if (!(await authService.auth0Client?.isAuthenticated())) {
      // reset the token if the user is not authenticated
      token = ''
    } else token = await authService.$auth.getTokenSilently()
    setJWTCookieValue(token)
  } else {
    token = getJWTCookieValue()
  }
  return token
}
