var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "claims-board" },
    [
      _c("ClaimsBoardHeader", {
        attrs: { "data-test": "claims-board-header" }
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "claims-board__main" },
        [
          _c("ClaimsColumn", {
            staticClass: "claims-board__col",
            attrs: {
              title: _vm.$t("finance/claims-board-column-header-submitted"),
              colName: "submitted",
              "data-test": "claims-col--submitted"
            }
          }),
          _vm._v(" "),
          _c("ClaimsColumn", {
            staticClass: "claims-board__col",
            attrs: {
              title: _vm.$t("finance/claims-board-column-header-processing"),
              colName: "processing"
            }
          }),
          _vm._v(" "),
          _c("ClaimsColumn", {
            staticClass: "claims-board__col",
            attrs: {
              title: _vm.$t("finance/claims-board-column-header-completed"),
              colName: "completed"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }