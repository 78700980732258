import { ref, Ref, computed } from '@vue/composition-api'
import {
  saveCarrierInvoice,
  cancelInvoiceById,
  saveShipperInvoice,
} from '/@/services/finance-service'
import {FinanceInvoiceData, ExtendedInvoice, Invoice, ShipperInvoiceAggregate } from '/@/services/types'
import { getDateHuman } from '@sennder/plankton'
import { ParsedInvoice } from '../components/invoices/types'
import { fetchShipperInvoices } from '../services/invoices-service'

export interface Breakdown {
  type: 'CUSTOMER' | 'CARRIER'
  invoices: ExtendedInvoice[]
  totalInvoiceAmount: number
  computedAmountToInvoice: number
  hasMissingInvoice: boolean
}

const invoiceData: Ref<FinanceInvoiceData> = ref(null)
const shipperInvoiceAggregate: Ref<ShipperInvoiceAggregate> = ref({
  remainingAmountToInvoiceForShipper: 0,
  totalShipperInvoiceAmount: 0,
  shipperInvoices: []
})

export default function useInvoices(orderId: Ref<number>) {
  const carrierFinancialBreakdown: Ref<Breakdown> = computed(() => ({
    type: 'CARRIER' as const,
    invoices:
      invoiceData.value?.carrierInvoices?.map(invoice => ({
        ...invoice,
        invoiceDateHuman: getDateHuman(invoice.issueDate)
      })) || [],
    totalInvoiceAmount: invoiceData.value?.totalCarrierInvoiceAmount || 0,
    computedAmountToInvoice: invoiceData.value?.computedAmountToInvoiceForCarrier || 0,
    hasMissingInvoice: invoiceData.value?.computedAmountToInvoiceForCarrier !== 0
  }))

  const addCarrierInvoice = async (invoice: Invoice) => {
    const addedInvoice = await saveCarrierInvoice(invoice)
    invoiceData.value.carrierInvoices.push(addedInvoice)
  }
  
  const addShipperInvoice = async (invoice: Invoice) => {
    const addedInvoice = await saveShipperInvoice(invoice)
    shipperInvoiceAggregate.value.shipperInvoices.push(addedInvoice)
  }

  const cancelInvoice = async (invoice: ParsedInvoice) => {
    await cancelInvoiceById(invoice.id)
  }

  const loadShipperInvoices = async () => {
    shipperInvoiceAggregate.value = await fetchShipperInvoices(orderId.value)
  }

  return {
    invoiceData,
    carrierFinancialBreakdown,
    addCarrierInvoice,
    cancelInvoice,
    loadShipperInvoices,
    shipperInvoiceAggregate,
    addShipperInvoice,
  }
}
