var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        title: _vm.$t("finance/claim-documents-modal-title", {
          count: _vm.documents.length
        }),
        confirmButtonText: _vm.editMode
          ? _vm.$t("finance/claim-documents-modal-confirm-label")
          : null,
        cancelButtonText: _vm.$t("finance/claim-documents-modal-cancel-label"),
        inPlace: ""
      },
      on: {
        confirm: _vm.modal.confirm,
        cancel: _vm.modal.close,
        close: _vm.modal.close,
        hidden: _vm.resetState
      }
    },
    [
      _c("p", { staticClass: "subtitle" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("finance/claim-documents-modal-description")) +
            "\n    " +
            _vm._s(
              _vm.editMode
                ? _vm.$t("finance/claim-documents-modal-description-edit-mode")
                : ""
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("ClaimDocumentsForm", {
        ref: "claimDocumentsForm",
        attrs: {
          disabled: !_vm.editMode,
          "can-delete": _vm.canDelete,
          "data-test": "claim-documents-form"
        },
        on: {
          loading: _vm.handleFileLoading,
          "file-removed": _vm.handleRemovedDocument
        },
        model: {
          value: _vm.documents,
          callback: function($$v) {
            _vm.documents = $$v
          },
          expression: "documents"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }