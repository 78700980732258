var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "invoices", attrs: { "data-test": "invoices-section" } },
    [
      _c("header", { staticClass: "invoices__header" }, [
        _c(
          "h2",
          {
            staticClass: "subsection-title",
            attrs: { "data-test": "invoices-section-title" }
          },
          [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "invoices__header-slot" },
          [_vm._t("default")],
          2
        )
      ]),
      _vm._v(" "),
      _c("table", { staticClass: "invoices__table" }, [
        _c(
          "thead",
          {
            staticClass: "invoices__table-head table-header",
            attrs: { "data-test": "invoices-table-head" }
          },
          [
            _c("tr", { staticClass: "invoices__table-row" }, [
              _c("th", [
                _vm._v(_vm._s(_vm.$t("finance/invoices-header-file")))
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("finance/invoices-header-number")))
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("finance/invoices-header-issue-date")))
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("finance/invoices-header-file-link")))
              ]),
              _vm._v(" "),
              _c("th", [
                _vm._v(_vm._s(_vm.$t("finance/invoices-header-net-cost")))
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.invoices, function(invoice) {
              return _c(
                "tr",
                {
                  key: invoice.id,
                  staticClass: "subtitle invoices__table-row",
                  class: {
                    "invoices__table-row--cancelled": invoice.isCancelled
                  },
                  attrs: { "data-test": "invoices-table-row" }
                },
                [
                  _c("td", [_vm._v(_vm._s(invoice.type))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(invoice.number))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(invoice.issueDate))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      invoice.filePath
                        ? _c(
                            "Button",
                            {
                              staticClass: "invoices__download-button",
                              attrs: { category: "link" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("download", invoice)
                                }
                              }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getFileName(invoice.filePath))
                                )
                              ]),
                              _vm._v(" "),
                              _c("Icon", {
                                attrs: { materialIcon: "cloud_download" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "invoices__last-cell" }, [
                    _vm._v(
                      "\n          " + _vm._s(invoice.netCost) + "\n          "
                    ),
                    _vm.allowCancelation && !invoice.isCancelled
                      ? _c(
                          "button",
                          {
                            staticClass: "invoices__remove-button",
                            attrs: { "data-test": "remove-invoice-button" },
                            on: {
                              click: function($event) {
                                return _vm.confirmRemoval(invoice)
                              }
                            }
                          },
                          [
                            _c("Icon", {
                              staticClass: "invoices__remove-icon",
                              attrs: { materialIcon: "close" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "tr",
              {
                staticClass: "subsection-title invoices__table-row--total",
                class: {
                  "invoices__table-row--negative": _vm.isTotalNetValueNegative
                },
                attrs: { "data-test": "invoices-table-footer" }
              },
              [
                _c("td", { attrs: { colspan: "4" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("finance/invoices-total-label")) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("td", { attrs: { "data-test": "invoices-total-net" } }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.totalNetValue) + "\n        "
                  )
                ])
              ]
            )
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.invoiceToRemove
        ? _c(
            "ConfirmationModal",
            {
              ref: "confirmRemovalModal",
              staticClass: "confirm-removal-modal",
              attrs: {
                title: _vm.$t("finance/invoices-remove-modal-title", {
                  invoiceType: _vm.invoiceToRemove.type.toLowerCase()
                }),
                confirmButtonText: _vm.$t(
                  "finance/invoices-remove-modal-confirm-label"
                ),
                cancelButtonText: _vm.$t(
                  "finance/invoices-remove-modal-cancel-label"
                ),
                "data-test": "confirm-removal-modal"
              }
            },
            [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("finance/invoices-remove-modal-description", {
                      invoice:
                        _vm.invoiceToRemove.type.toLowerCase() +
                        " " +
                        _vm.truncatedInvoiceNumber,
                      netCost: _vm.invoiceToRemove.netCost,
                      number: _vm.invoiceToRemove.number
                    })
                  )
                }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }