var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SidebarCard", {
    attrs: { "data-test": "route-info" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("finance/route-info-title")) + "\n  "
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "route section" },
              _vm._l(_vm.stops, function(stop, index) {
                return _c(
                  "VerticalStepper",
                  {
                    key: stop.id,
                    staticClass: "stops",
                    attrs: {
                      pointLabel: _vm.getAlphabetEquivalent(index),
                      showLine: index !== _vm.stops.length - 1,
                      "data-test": "order-stop-item",
                      invertedColorPoint: stop.stopoverType === "UNLOADING"
                    }
                  },
                  [
                    _c("p", { staticClass: "subtitle stops__title" }, [
                      _c("b", { attrs: { "data-test": "stop-type" } }, [
                        _vm._v(_vm._s(stop.stopoverType || "-") + ": ")
                      ]),
                      _vm._v(" "),
                      _c("span", { attrs: { "data-test": "stop-customer" } }, [
                        _vm._v(_vm._s(stop.customerCompany.name || "-"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "stops__address subtitle",
                        attrs: { "data-test": "stop-address" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(stop.warehouseAddress.fullAddress || "-") +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "plankton-grid stops__content",
                        attrs: { columns: "12" }
                      },
                      [
                        _c(
                          "LabeledText",
                          {
                            staticClass: "plankton-cell stops__field",
                            attrs: {
                              span: "12",
                              label: _vm.$t(
                                "finance/route-info-reference-num-label"
                              ),
                              "data-test": "customer-reference-number"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: {
                                      content: stop.referenceNumber,
                                      autoHide: false
                                    },
                                    expression:
                                      "{ content: stop.referenceNumber, autoHide: false }"
                                  }
                                ],
                                staticClass: "ref-number"
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(stop.referenceNumber || "-") +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "LabeledText",
                          {
                            staticClass: "plankton-cell stops__field",
                            attrs: {
                              span: "12",
                              label: _vm.$t(
                                "finance/route-info-date-time-label"
                              ),
                              "data-test": "scheduled-arrival-departure"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(stop.startDate) +
                                " " +
                                _vm._s(stop.startTime) +
                                " - " +
                                _vm._s(stop.endDate) +
                                " " +
                                _vm._s(stop.endTime) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isOrderDispatched && _vm.hasStepsLoaded
                          ? [
                              _c(
                                "LabeledText",
                                {
                                  staticClass: "plankton-cell stops__field",
                                  attrs: {
                                    span: "6",
                                    "data-test": "actual-arrival",
                                    label: _vm.$t(
                                      "finance/route-info-actual-arrival-label"
                                    )
                                  }
                                },
                                [
                                  _vm.steps[index * 2].actualTime.isValid
                                    ? _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-test": "labeled-text__text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.steps[index * 2].actualTime
                                                  .date
                                              ) +
                                              ",\n                " +
                                              _vm._s(
                                                _vm.steps[index * 2].actualTime
                                                  .time
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-test": "labeled-text__text"
                                          }
                                        },
                                        [_vm._v("-")]
                                      )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "LabeledText",
                                {
                                  staticClass: "plankton-cell stops__field",
                                  attrs: {
                                    span: "6",
                                    label: _vm.$t(
                                      "finance/route-info-actual-departure-label"
                                    ),
                                    "data-test": "actual-departure"
                                  }
                                },
                                [
                                  _vm.steps[index * 2 + 1].actualTime.isValid
                                    ? _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-test": "labeled-text__text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.steps[index * 2 + 1]
                                                  .actualTime.date
                                              ) +
                                              ",\n                " +
                                              _vm._s(
                                                _vm.steps[index * 2 + 1]
                                                  .actualTime.time
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-test": "labeled-text__text"
                                          }
                                        },
                                        [_vm._v("-")]
                                      )
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "LabeledText",
                          {
                            staticClass: "plankton-cell stops__field",
                            attrs: {
                              span: "12",
                              label: _vm.$t("finance/route-info-notes-label"),
                              "data-test": "stop-notes"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(stop.notes || "-") +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      2
                    )
                  ]
                )
              }),
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }