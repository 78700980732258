import {formatWithThousands} from '@sennder/plankton'
import moment from 'moment'
import {Currency} from '/@/constants'
import {getCurrencySign} from '/@/utils/cost'

export const DATE_FORMAT = 'DD.MM.YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

// Move this into plankton.
const basicFormatMoneyWithDashForNull = function(
  number: number,
  formattingFunction: (arg: number) => string,
  currency: Currency = Currency.EUR
) {
  if (number === null) return '-'
  // In case of bugs we show an X to our users so they can easily spot/report it
  if (number === undefined || isNaN(number)) return 'X'
  else {
    const sign = number < 0 ? '-' : ''
    const formattedValue = formattingFunction(Math.abs(number))
    if(currency) {
      return `${sign}${getCurrencySign(currency)}${formattedValue}`
    }
    return `${sign}€${formattedValue}`
  }
}

export const formatMoneyWithDashForNull = function(number: number) {
  return basicFormatMoneyWithDashForNull(number, formatWithThousands)
}

export const formatMoneyWithZeroForNull = function(number: number) {
  return formatMoneyWithDashForNull(number === null ? 0 : number)
}

export const formatDate = (date: string) => {
  return moment(date).format(DATE_FORMAT)
}

export const formatTime = (time: string) => {
  return moment(time).format(TIME_FORMAT)
}
