





























































import FinanceOrderHeader from './header/FinanceOrderHeader.vue';
import FinanceOrderSidebar from './FinanceOrderSidebar.vue';
import FinanceOrderFooter from './FinanceOrderFooter.vue';
import FinanceOrderMainWrapper from './FinanceOrderMainWrapper.vue';
import FinanceOrderMainCarrier from './FinanceOrderMainCarrier.vue';
import FinanceOrderMainCustomer from './FinanceOrderMainCustomer.vue';
import {
  ref,
  computed,
  watch,
  Ref,
  defineComponent,
} from '@vue/composition-api';
import { appOptions } from '/@/app-options';
import { FinanceView } from './types';
import { Route } from 'vue-router';
import useCurrency from '../compositions/useCurrency';
import { Currency } from '../constants';

export default defineComponent({
  components: {
    FinanceOrderHeader,
    FinanceOrderSidebar,
    FinanceOrderMainWrapper,
    FinanceOrderMainCarrier,
    FinanceOrderMainCustomer,
    FinanceOrderFooter,
    OrderActivitySidebar: () =>
      Promise.resolve(appOptions.components.OrderActivitySidebar),
  },
  async beforeRouteUpdate(to, _from, next) {
    this.loading = true;
    await this.loadOrder({
      orderGroupId: parseInt(to.params['orderGroupId']),
      letter: to.params['letter'],
    });
    next();
    this.loading = false;
    this.setFinanceView(to);
  },
  setup(props, context) {
    const loading = ref(true);
    const {
      order,
      orderStops,
      loadNewOrder,
      accountManager,
      juniorAccountManager,
      spotBidder,
      partnerManager,
      loadOrderManagers,
      loadOrderSpotBidder
    } = appOptions.useOrder();
    const { updateExchangeRate } = useCurrency();

    const {
      currentUserId,
      currentUserIsAccountManager,
      currentUserIsPartnerManager,
    } = appOptions.useCurrentUser();

    const { sidebarIsVisible } = appOptions.libraries.useOrderActivitySidebar();
    const financeView: Ref<FinanceView> = ref();

    const setFinanceView = (route: Route) =>
      (financeView.value =
        route.params.financeView?.toUpperCase() as FinanceView);

    const firstStop = computed(() => {
      if (!orderStops.value.length) return;
      return orderStops.value[0]?.warehouseAddress;
    });
    const lastStop = computed(() => {
      if (!orderStops.value.length) return;
      return orderStops.value[orderStops.value.length - 1]?.warehouseAddress;
    });

    const loadOrder = async ({
      orderGroupId,
      letter,
    }: {
      orderGroupId: number;
      letter: string;
    }) => {
      await loadNewOrder(orderGroupId, letter);
      await loadOrderManagers();
      if (
        currentUserIsAccountManager.value ||
        currentUserIsPartnerManager.value
      ) {
        await loadOrderSpotBidder();
      }
      loading.value = false;
    };

    const setPageTitle = () => {
      const first = firstStop.value
        ? `${firstStop.value.countryCode}-${firstStop.value.zipCode}`
        : '';
      const last = lastStop.value
        ? `${lastStop.value.countryCode}-${lastStop.value.zipCode}`
        : '';

      const separator = first && last ? '->' : '';
      const inner = first && last ? first : order.value?.idForStaff;
      document.title = `${inner} ${separator} ${last}`;
    };

    watch([order, orderStops], () => {
      context.emit('updateHead');
      setPageTitle();
    });

    setPageTitle();

    return {
      currentUserId,
      loading,
      order,
      orderStops,
      firstStop,
      lastStop,
      loadOrder,
      financeView,
      setFinanceView,
      accountManager,
      juniorAccountManager,
      partnerManager,
      spotBidder,
      sidebarIsVisible,
      updateExchangeRate,
      enableFullScreen: !appOptions.isMicrofrontend,
    };
  },
  async created() {
    try {
      await this.updateExchangeRate(Currency.EUR);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // async setup is possible with Suspense only,
    // which is supported in Vue 3
    await this.loadOrder(this.$route.params);
    this.setFinanceView(this.$route);
  },
});
