




































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRef,
  onBeforeMount,
  watch
} from '@vue/composition-api'
import useModelUpdateEmitter from '/@/compositions/useModelUpdateEmitter'
import useClaims, { SubmitClaim } from '/@/compositions/useClaims'
import {
  ClaimReporter,
  ClaimFaultyParty,
  ClaimSubtype
} from '/@/services/types'
import {
  requiredValidatorWhen,
  requiredValidator,
  beforeCurrentDateTimeValidator,
  validatorWithValue,
  translateValidators
} from '/@/field-validators'
import { PrimitiveDropdownField, Form, DropdownField } from '@sennder/plankton'
import DateTimeInputField from '/@/components/shared/DateTimeInputField.vue'
import { appOptions } from '/@/app-options'

const modelOptions = { prop: 'claim', event: 'input' }
const dateTimeFormat = 'DD.MM.YYYY, HH:mm'
type DateRange = { startDate: string; endDate: string }

export default defineComponent({
  components: { PrimitiveDropdownField, Form, DropdownField, DateTimeInputField },
  model: modelOptions,
  props: {
    claim: { type: Object as PropType<SubmitClaim>, required: true },
    orderId: { type: Number, required: true },
    disabled: { type: Boolean, default: false }
  },
  setup(props, context) {
    const reporterOptions: { label: string; value: ClaimReporter }[] = [
      { label: context.root.$t('finance/global-claim-reporter-option-carrier') as string, value: 'CARRIER' },
      { label: context.root.$t('finance/global-claim-reporter-option-shipper') as string, value: 'SHIPPER' }
    ]
    const faultyPartyOptions: { label: string; value: ClaimFaultyParty }[] = [
      { label: context.root.$t('finance/global-claim-faulty-party-option-carrier') as string, value: 'CARRIER' },
      { label: context.root.$t('finance/global-claim-faulty-party-option-shipper') as string, value: 'SHIPPER' },
      { label: context.root.$t('finance/global-claim-faulty-party-option-internal') as string, value: 'INTERNAL' },
      { label: context.root.$t('finance/global-claim-faulty-party-option-third-party') as string, value: 'THIRD_PARTY' }
    ]

    const orderId = toRef(props, 'orderId')
    const { currentUserIsAccountManager, currentUserIsPartnerManager } = appOptions.useCurrentUser()
    const { claimTypes, loadClaimTypes } = useClaims(orderId)
    const claimTypeOptions = computed(() => {
      return claimTypes.value.map(claimType => ({
        label: context.root.$t(claimType.labelTranslationKey),
        value: claimType.code
      }))
    })
    const claimSubtypeOptions = computed(() => {
      if (!props.claim.claimType) return []
      const selectedClaimType = claimTypes.value.find(
        claimType => claimType.code === props.claim.claimType
      )
      if (!selectedClaimType) return []
      return selectedClaimType.subtypes.map(subtype => ({
        ...subtype,
        description: context.root.$t(subtype.labelTranslationKey, subtype.translationValues)
      }))
    })

    const claimSubtypeForDropdown = computed<ClaimSubtype>({
      get: () => {
        const { claimSubtype: claimSubtypeId, stopoverId = null } = props.claim
        return claimSubtypeOptions.value.find(
          item => item.code === claimSubtypeId && item.stopoverId === stopoverId
        )
      },
      set: claimSubtype => {
        let claimSubtypeId: string = null
        let stopoverId: number = null
        if (claimSubtype) {
          claimSubtypeId = claimSubtype.code
          stopoverId = claimSubtype.stopoverId
        }
        context.emit(modelOptions.event, {
          ...props.claim,
          claimSubtype: claimSubtypeId,
          stopoverId: stopoverId
        } as SubmitClaim)
      }
    })
    const withStartDate = validatorWithValue<DateRange, string>(value => value?.startDate)
    const incidentTimeValidators = [
      withStartDate(requiredValidator),
      withStartDate(beforeCurrentDateTimeValidator(dateTimeFormat))
    ]

    const emitValueUpdate = useModelUpdateEmitter(props, context, modelOptions)
    const form = ref(null)
    const submit = () => form.value.submit()
    const isReporterFieldDisabled = computed(() => {
      if (props.disabled) return true
      if (currentUserIsAccountManager.value) return false
      return currentUserIsAccountManager.value !== currentUserIsPartnerManager.value
    })

    onBeforeMount(() => {
      if (props.claim.reporter !== null) {
        loadClaimTypes(props.claim.reporter)
      }
    })

    watch(
      () => props.claim.reporter,
      (newValue, oldValue) => {
        if (newValue === oldValue) return
        loadClaimTypes(newValue)
      }
    )

    const $t = context.root.$t.bind(context.root)

    return {
      form,
      reporterOptions,
      faultyPartyOptions,
      claimTypeOptions,
      claimSubtypeOptions,
      claimSubtypeForDropdown,
      dateTimeFormat,
      emitValueUpdate,
      submit,
      reporterValidators: translateValidators($t, [requiredValidator]),
      faultyPartyValidators: translateValidators($t, [requiredValidator]),
      claimTypeValidators: translateValidators($t, [requiredValidator]),
      claimSubtypeValidators: translateValidators($t, [
        requiredValidatorWhen(() => props.claim.claimType !== 'OTHER')
      ]),
      incidentTimeValidators: translateValidators($t, incidentTimeValidators),
      isReporterFieldDisabled
    }
  }
})
