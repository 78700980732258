import { Auth0Plugin } from './auth0'
import auth0Config from './auth0Config.json'

export { getJWTAuthToken, getInstance } from './auth0'

export {
  getJWTCookieValue,
  getAuthCookieValue,
  setAuthCookieValue,
  setJWTCookieValue,
  removeJWTCookieValue,
  removeAuthCookieValue,
  JWT_TOKEN_OCTOPUS_COOKIE_NAME,
  AUTH_TOKEN_OCTOPUS_COOKIE_NAME,
  getAuthorizationHeader,
  getAuthorizationToken,
  getAuthorizationHeaderWithParams,
} from './userAuthToken'

export { auth0Config }

export default Auth0Plugin
