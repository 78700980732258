import {
  BaseClaim,
  ClaimStatus,
  ClaimReporter,
  Claim,
  ClaimType,
  ClaimSubtype,
  ClaimFaultyParty,
} from '/@/services/types'
import { appOptions } from '/@/app-options'

import { ref, Ref, onUnmounted, watch } from '@vue/composition-api'
import * as claimsService from '/@/services/claim-service'
import { FileUpload } from './useUploadingFiles'

export interface ClaimFileUpload extends FileUpload {
  uploadDirectory: string
  // indicates if the file has been submitted alongside a claim.
  // It does not indicate if the file has been uploaded to the remote storage.
  submitted: boolean
}

/**
 * Define the shape of claim objects that SubmitClaimModal component expects.
 * Either for creating new claims or editing existing ones, both type of objects has to follow this interface
 * if they want to be used in SubmitClaimModal.
 */
export interface SubmitClaim extends BaseClaim {
  id: number
  requestedAmount: number
  description: string,
  files?: ClaimFileUpload[]
}

export const WIP_CLAIM_STATUSES: ClaimStatus[] = [
  'SUBMITTED',
  'PENDING',
  'MORE_INFORMATION_REQUIRED',
  'MORE_INFO_REQUIRED'
]

export const isClaimStatusWIP = (status: ClaimStatus) =>
  WIP_CLAIM_STATUSES.includes(status)

export interface ClaimSubtypeOption extends ClaimSubtype {
  labelTranslationKey: string,
  translationValues?: unknown
}

export interface ClaimTypeOption extends ClaimType {
  labelTranslationKey: string
  subtypes: ClaimSubtypeOption[]
}

export const ClaimTypeTranslationKeys: {[key: string]: string } = {
  CANCELLATION: 'finance/use-claims-types-cancellation',
  CARRIER_DETENTION: 'finance/use-claims-types-carrier-detention',
  DAMAGE: 'finance/use-claims-types-carrier-damage',
  ORDER_MODIFICATION: 'finance/use-claims-types-order-modification',
  SHIPPER_DEMURRAGE: 'finance/use-claims-types-shipper-demurrage',
  THEFT: 'finance/use-claims-types-theft',
  OTHER: 'finance/use-claims-types-other',
  NON_COMPLIANCE: 'finance/use-claims-types-non-compliance',
  MODIFICATION: 'finance/use-claims-types-modification',
  WAITING_TIME: 'finance/use-claims-types-waiting-time',
  CARRIER_DELAY: 'finance/use-claims-types-carrier-delay'
}

export const claimSubtypeTranslationKeys: {[key: string]: string } = {
  CARRIER: 'finance/use-claims-subtypes-carrier',
  SENNDER: 'finance/use-claims-subtypes-sennder',
  SHIPPER: 'finance/use-claims-subtypes-shipper',
  STOPOVER: 'finance/use-claims-subtypes-stopover',
  INSURANCE: 'finance/use-claims-subtypes-insurance',
  NO_INSURANCE: 'finance/use-claims-subtypes-no-insurance',
  ADDITIONAL_STOPS: 'finance/use-claims-subtypes-additional-stops',
  DIVERSION_KILOMETRES: 'finance/use-claims-subtypes-diversion-kms',
  LUMPER_FEE_DRIVER_LOAD_UNLOAD: 'finance/use-claims-subtypes-lumper-fee',
  REDELIVERY: 'finance/use-claims-subtypes-redelivery',
  EQUIPMENT: 'finance/use-claims-subtypes-equipment',
  WRONG_IDENTIFICATION: 'finance/use-claims-subtypes-wrong-identification',
  DRIVER_LOAD_UNLOAD: 'finance/use-claims-subtypes-driver-load-unload',
  EXTRA_KILOMETRES: 'finance/use-claims-subtypes-extra-kilometers'
}

export const reporterTranslationKeys: Record<ClaimReporter, string> = {
  CARRIER: 'finance/use-claims-reporter-carrier',
  SHIPPER: 'finance/use-claims-reporter-shipper'
}

export const faultyPartyTranslationKeys: Record<ClaimFaultyParty, string> = {
  CARRIER: 'finance/use-claims-faulty-party-carrier',
  SHIPPER: 'finance/use-claims-faulty-party-shipper',
  INTERNAL: 'finance/use-claims-faulty-party-internal',
  THIRD_PARTY: 'finance/use-claims-faulty-party-third-party'
}

export const statusTranslationKeys: Record<ClaimStatus, string> = {
  SUBMITTED: 'finance/use-claims-status-submitted',
  PENDING: 'finance/use-claims-status-pending',
  MORE_INFORMATION_REQUIRED: 'finance/use-claims-status-info-required',
  MORE_INFO_REQUIRED: 'finance/use-claims-status-info-required',
  APPROVED: 'finance/use-claims-status-approved',
  REJECTED: 'finance/use-claims-status-rejected',
  NOT_SUBMITTED: 'finance/claims-board-card-status-info-not-submitted'
}

const claimTypes = ref<ClaimTypeOption[]>([])
const claims = ref<Claim[]>([])
export default (orderId: Ref<number>) => {
  // TODO: import typing information from octopus somehow
  const { currentUserIsAccountManager, currentUserIsPartnerManager } = appOptions.useCurrentUser()

  const createClaim = (side: ClaimReporter = null): SubmitClaim => {
    const claim = <Partial<SubmitClaim>>{
      id: null,
      orderId: orderId.value,
      reporter: side === null ? getDefaultClaimSide() : side,
      faultyParty: null,
      claimType: null,
      claimSubtype: null,
      stopoverId: null,
      incidentTime: null,
      files: []
    }
    return {
      ...claim,
      requestedAmount: null,
      description: null,
    } as SubmitClaim
  }
  const submitClaim = async (claim: SubmitClaim, files: ClaimFileUpload[]) => {
    const claimFiles = files.map(f => ({
      originalName: f.name,
      fileSize: f.sizeBytes,
      uploadDirectory: f.uploadDirectory
    }))
    await claimsService.createClaim({ ...claim, files: claimFiles })
    await Promise.all([fetchClaims(), appOptions.refreshOrderLogs()])
  }

  const fetchClaims = async () => {
    claims.value = await claimsService.getClaims(orderId.value)
  }
  const loadClaims = async () => {
    watch(orderId, fetchClaims, { immediate: true })
    onUnmounted(() => {
      claims.value = []
    })
  }
  const loadClaimTypes = async (reporter: ClaimReporter) => {
    const fetchClaimTypes =
      reporter === 'SHIPPER'
        ? claimsService.fetchShipperClaimTypes
        : claimsService.fetchCarrierClaimTypes
    watch(
      orderId,
      async () => {
        claimTypes.value = await fetchClaimTypes(orderId.value)
          .then(claimTypes => {
            return claimTypes.map(ct => ({
              ...ct,
              labelTranslationKey: ClaimTypeTranslationKeys[ct.code],
              subtypes: ct.subtypes.map(st => ({
                ...st,
                labelTranslationKey: claimSubtypeTranslationKeys[st.code],
                translationValues: { stopoverDescription: st.stopoverDescription }
              }))
            }))
          })
      },
      { immediate: true }
    )
    onUnmounted(() => {
      claimTypes.value = []
    })
  }

  const updateClaimStatus = async (
    claimId: number,
    side: ClaimReporter,
    status: ClaimStatus,
    payload?: claimsService.SetClaimStatusPayload
  ) => {
    await claimsService.setClaimStatus({
      claimId,
      side,
      status,
      payload
    })

    await Promise.all([fetchClaims(), appOptions.refreshOrderLogs()])
  }

  const updateClaim = async (claim: SubmitClaim, side: ClaimReporter) => {
    const claimId = claim.id
    const params = {
      claimId,
      requestedAmount: claim.requestedAmount,
      description: claim.description || '',
      side
    }
    await claimsService.updateClaim(params)
    await fetchClaims()
  }

  const getDefaultClaimSide = (): ClaimReporter => {
    if (currentUserIsAccountManager.value && !currentUserIsPartnerManager.value) {
      return 'SHIPPER'
    }
    if (currentUserIsPartnerManager.value && !currentUserIsAccountManager.value) {
      return 'CARRIER'
    }

    return null
  }

  return {
    claimTypes,
    loadClaimTypes,
    createClaim,
    submitClaim,
    loadClaims,
    updateClaimStatus,
    claims,
    updateClaim
  }
}
