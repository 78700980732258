

















































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api'
import ConfirmationModal from '/@/components/shared/ConfirmationModal.vue'
import { Icon, DropdownField } from '@sennder/plankton'
import { CostCentre } from '/@/services'
import { fetchCostCentres, fetchOrderCostCentre, updateOrderCostCentre } from '/@/services/cost-centre-service'
import useCostCentre from '/@/compositions/useCostCentre'

type CostCentreOption = CostCentre & { label: string }

export default defineComponent({
  components: { Icon, DropdownField, ConfirmationModal },
  props: {
    orderId: { type: Number, required: true },
    orderIdForStaff: { type: String, required: true },
    carrierId: { type: Number, required: true },
    partnerManagerId: { type: Number, required: true }
  },
  setup(props) {
    const confirmCostCentreChangeModalRef = ref<InstanceType<typeof ConfirmationModal>>(null)
    const costCentre = ref<CostCentreOption>(null) 
    const costCentreChange = ref<CostCentreOption>(null)
    const costCentres = ref<CostCentre[]>([])

    const { suggestOrderCostCentre } = useCostCentre()

    const costCentreChangeDescription = computed(() => {
      if (!costCentreChange.value) return null
      return `${costCentreChange.value.code} - ${costCentreChange.value.shortDescription}`
    })

    const handleCostCentreChange = async (newCostCentre: CostCentreOption) => {
      if (!newCostCentre || newCostCentre.code === costCentre.value?.code) return

      costCentreChange.value = newCostCentre
      const confirmed = await confirmCostCentreChangeModalRef.value.askConfirmation()
      if (confirmed) {
        const oldCostCentre = costCentre.value
        costCentre.value = newCostCentre
        try {
          await updateOrderCostCentre(props.orderId, newCostCentre.code)
        } catch (error) {
          costCentre.value = oldCostCentre
          throw error
        }
      }
    }

    const mapToCostCentreOption = (cc: CostCentre): CostCentreOption => ({
      ...cc,
      label: `${cc.code} - ${cc.shortDescription}`,
    })

    const costCentreOptions = computed<CostCentreOption[]>(() =>{
      return costCentres.value.map(mapToCostCentreOption)
    })

    const loadOrderCostCentre = async () => {
      let cc = await fetchOrderCostCentre(props.orderId)
      if (!cc) {
        cc = await suggestOrderCostCentre(props.orderId, props.carrierId, props.partnerManagerId)
      }

      if (cc) {
        costCentre.value = mapToCostCentreOption(cc)
      }
    }

    onMounted(() => {
      fetchCostCentres().then(
        result => costCentres.value = result
      )
      loadOrderCostCentre()
    })

   return {
     costCentre,
     costCentres,
     costCentreChange,
     costCentreOptions,
     costCentreChangeDescription,
     confirmCostCentreChangeModalRef,
     handleCostCentreChange
    }
  },
})
