















import Vue from 'vue'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  setup() {
    const inputFileRef = ref<HTMLInputElement>(null)
    const downloadLinkRef = ref<HTMLAnchorElement>(null)
    const csvContent = ref<string>(null)
    let resolveRequestedFileCb: (file: File) => void 

    const requestFile = () => {
      inputFileRef.value.value = null
      inputFileRef.value.click()
      return new Promise<File>(resolve => {
        resolveRequestedFileCb = resolve
      })
    }

    const handleUploadedFile = async (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (target.files.length === 0) return
      resolveRequestedFileCb(target.files[0])
    }

    const download = async (content: string) => {
      csvContent.value = content
      await Vue.nextTick()
      downloadLinkRef.value.click()
    }

    return { 
      inputFileRef, 
      downloadLinkRef, 
      csvContent,
      requestFile, 
      download,
      handleUploadedFile
    }
  },
})
