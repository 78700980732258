import { Timestamp } from '@sennder/plankton'
import { Ref, ComputedRef } from '@vue/composition-api'
import { defineComponent } from '@vue/composition-api'
import VueI18n from 'vue-i18n'
import { Currency } from './constants'
declare type ErrorHandler = (error: Error) => never | void

export interface ICommonHeaders {
  Authorization: string | null;
  'X-Org-Type': OrgType | null;
  'X-Org-Id': string | null;
}

export declare enum OrgType {
  CARRIER = 'carrier',
  CHARTERING_OFFICE = 'chartering-office',
  ORGANIZATION = 'organization'
}

export interface Options {
  baseURL: string
  claimsUrl: string
  costCentreEnabled: boolean
  shipperInvoicesUploadEnabled: boolean
  costCentreURL: string
  fleetServiceURL: string
  exchangeServiceURL: string
  podUploadServiceURL: string
  getAuthorizationHeaderWithParams: (audience?: string, scope?: string) => Promise<string>
  getCommonHeaders: () =>  Promise<ICommonHeaders>
  tenant: 'sennder' | 'poste' | 'demo'
  documentUploadEnabled: boolean
  ownCarrierAssetIds: string[]
  authorizationServiceURL: string
  isSennderTheme: boolean
  i18n: VueI18n
  useCurrentUser: UseCurrentUser
  useOrder: UseOrder
  useFeatureFlag: UseFeatureFlag
  // TODO: remove this
  refreshOrderLogs: RefreshOrderLogsApi
  orderingService: OrderingService
  // TODO: migrate composition functions above into the libraries property.
  libraries: {
    useOrderActivitySidebar: UseOrderActivitySidebar
    useSteps: UseSteps
  }
  components: {
    OrderStateChip: ReturnType<typeof defineComponent>
    OrderActivitySidebar: ReturnType<typeof defineComponent>
    OrderStageFooter: ReturnType<typeof defineComponent>
    RenderWithLaunchDarkly: ReturnType<typeof defineComponent>
  }
  incidentManagementParams: {
    baseURL: string
    authHost: string
    token?: string
    apiErrorHandler?: ErrorHandler
  }
  isProduction: boolean,
  trackEvent: (eventName: string, properties?: Record<string, unknown>) => void,
  isMicrofrontend: boolean
}

type UseCurrentUser = () => {
  user: Ref<{
    id: number
    groupNames: string[]
    permissions: string[]
  }>
  loadCurrentUser: () => Promise<void>
  currentUserId: ComputedRef<number>
  currentUserIsAccountManager: ComputedRef<boolean>
  currentUserIsPartnerManager: ComputedRef<boolean>
  currentUserIsPMTeamAssigner: ComputedRef<boolean>
  currentUserIsDirectDispatcher: ComputedRef<boolean>
  currentUserIsOps: ComputedRef<boolean>
  currentUserIsFinance: ComputedRef<boolean>
  currentUserIsCallCenter: ComputedRef<boolean>
  currentUserIsClaimAccessorialManager: ComputedRef<boolean>
  hasPermission: (permission: string) => boolean
}

type UseFeatureFlag = () => {
  isActive: (flag: string) => ComputedRef<boolean>
  removeListener: () => void
  identifyAnonymous: () => Promise<void>
}

export type UseOrder = () => {
  order: Ref<FinanceOrder>
  orderStops: Ref<OrderStop[]>
  accountManager: Ref<ExtendedPerson>
  juniorAccountManager: Ref<ExtendedPerson>
  partnerManager: Ref<ExtendedPerson>
  spotBidder: Ref<ExtendedPerson>
  loadOrderManagers: () => Promise<void>
  loadOrderSpotBidder: () => Promise<void>
  loadNewOrder: (orderGroupId: number, letter: string) => Promise<void>
  loadOrderDetail: (id?: number) => Promise<void>
}
export interface FinanceOrder extends OrderDetail {
  carrierCompanyId: number
  carrierContactId: number
  carrierContact: CarrierContact & { name: string }
  baseCost?: number
  basePrice?: number
}

export interface OrderDetail {
  accountManagerId?: number
  juniorAccountManagerId: number
  activePartnerManagersIds?: number[]
  allowedVehicleTypes?: VehicleType[]
  arrivalNotification?: boolean
  baseCost?: number
  basePrice?: number
  carrierCompany?: CarrierCompany
  carrierContact?: CarrierContact
  codeXl?: boolean
  confirmationDocumentId?: number
  customerBillingAddress?: CustomerAddress
  customerCompanyAddress?: CustomerAddress
  customerContact?: CustomerContact
  customerId?: number
  customerName?: string
  directTransfer?: boolean
  dockLoading?: boolean
  editableForVariations?: boolean
  id: number
  idForStaff?: string
  isCancelled?: boolean
  isTemperatureControlled?: boolean
  lineCode?: string
  loadDescription?: string
  loadHeight?: number
  loadLength?: number
  loadMaximumTemperature?: number
  loadMinimumTemperature?: number
  loadQuantity?: number
  loadType?: unknown
  loadUnitType?: LoadUnitType
  loadWeight?: number
  loadWidth?: number
  marginPercentage?: number
  orderGroupId?: number
  partnerManagerId?: number
  preDispatchCarrierExtraChargeAmount?: number
  preDispatchCarrierExtraChargeDescription?: string
  preDispatchCarrierExtraChargeId?: number
  preSaleCustomerExtraChargeAmount?: number
  preSaleCustomerExtraChargeDescription?: string
  preSaleCustomerExtraChargeId?: number
  pickUpDate?: string
  referenceNumber?: string
  regularity?: string
  sealable?: boolean
  sideLoading?: boolean
  state?: OrderState
  topLoading?: boolean
  totalNetCost?: number
  totalNetPrice?: number
  trackingIdForShipper?: string
  transferId?: number
  version: number
  expectedCost: number
  targetAchievementPercentage: number
  carrierPaymentCurrency?: Currency
}

export interface OrderStopFetch {
  id: number
  orderId: number
  index: number
  warehouseAddressId: number
  warehouseAddress: CustomerAddress
  warehouseIndex?: number
  customerCompanyId: number
  customerCompany: Company
  contactPersonId?: number | null
  contactPerson?: ContactBase
  stopoverType: StopoverType
  arrivalToWarehouse: string
  departureFromWarehouse: string
  referenceNumber?: string
  notes?: string | null
}

export enum StopoverType {
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING'
}

export interface OrderStop extends OrderStopFetch {
  arrivalToWarehouse: typeof Timestamp
  departureFromWarehouse: typeof Timestamp
  startDate: string | null
  endDate: string | null
  startTime: string
  endTime: string
  isOvernightLoad?: boolean
  contactPerson?: CustomerContact
}

export type VehicleType =
  | 'VAN'
  | 'TRUCK_3_5'
  | 'TRUCK_7_5'
  | 'TRUCK_12'
  | 'TRUCK_40_TAUTLINER'
  | 'TRUCK_40_BOX'
  | 'TRUCK_40_DROP'
  | 'TRUCK_40_FRIGO'
  | 'TRUCK_40_MEGA'
  | 'TRUCK_40_OPEN'
  | 'TRUCK_40_JUMBO'

export interface ContactBase {
  id: number
  firstName: string
  lastName: string
  email?: string
  phone: string
}

export interface CustomerContact extends ContactBase {
  name: string
}

export type CarrierContact = ContactBase

export interface CarrierKpi {
  carrierId: number
  isTrackable: boolean
  onTimeDeliveryPercentage: number
  onTimePickupPercentage: number
  totalOnTimeDelivery: number
  totalOnTimePickup: number
  totalOrders: number
  totalOrdersSameLane: number
  totalOrdersWithOnTimeData: number
  totalOrdersWithSameCustomer: number
}

export interface Company {
  id: number
  name: string
}

export interface CarrierCompany extends Company {
  gatehouseCarrierId?: number
  hasOrcasAccess?: boolean
}

export interface CustomerAddress {
  id: number
  fullAddress: string
  companyAddressId: number
  zipCode?: string
  timezone?: string
  city?: string
  latitude?: string
  longitude?: string
  countryCode: string
}

export type LoadUnitType =
  | 'Europallets'
  | 'Europallets'
  | 'Industrial_pallets'
  | 'Colli'
  | 'Cage_Pallets'
  | 'Parcels'
  | 'Duesseldorfer_pallets'
  | 'Chep_pallets'

export const ORDER_STATES = Object.freeze([
  'NEW',
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'EXECUTED',
  'OPERATIONS_COMPLETED'
] as const)

export type OrderState = typeof ORDER_STATES[number]

export const ORDER_REGULARITY = Object.freeze([
  'SPOT',
  'REGULAR',
  'RECURRING',
  'SCALED_SPOT'
] as const)

export type OrderRegularity = typeof ORDER_REGULARITY[number]

type RefreshOrderLogsApi = () => void

type UseOrderActivitySidebar = () => {
  toggleSidebar: () => void
  expandSidebar: () => void
  refreshOrderLogs: () => Promise<void>
  refreshOrderIncidents: () => Promise<void>
  sidebarIsVisible: Ref<boolean>
}

type UseSteps = () => {
  steps: Ref<TransferStepActualTime[]>
  loadSteps: (transferId: number) => Promise<void>
}

export interface TransferStepActualTime {
  id: number
  actualTime: typeof Timestamp
  timezone: string
  warehouseIndex: number
}

export interface OrderingService {
  closeCustomerPrice: (param: number) => Promise<void>
  closeCarrierCost: (param: number) => Promise<void>
  cancelNotOpsCompletedOrder: (id: number) => Promise<void>
  cancelOpsCompletedOrder: (id: number) => Promise<void>
  fetchTransferById: (id: number) => Promise<Transfer>
}

export interface Transfer {
  id: number
  version: number
  code: string
  licensePlate: string
  trailerLicensePlate: string
  notes: string
  dispatchedVehicle: string
  operator: string
  driver: string
}

export interface ExtendedPerson extends Person {
  fullName: string
}

interface Person {
  firstName: string
  id: number
  lastName: string
}

export let appOptions: Options = null

export const setAppOptions = (options: Options) => {
  appOptions = options
}

export interface ICurrencyExchangeRate {
  base: Currency
  rates: {
    [key in Currency]: number
  }
  date: string
}
