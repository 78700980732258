import { ref, Ref, watch, onBeforeMount } from '@vue/composition-api'
import { ExtraChargeType } from '/@/services/types'
import {
  fetchCarrierExtraCharges,
  fetchCustomerExtraCharges,
  addExtraCharge,
  addBaseAmountCorrection,
  addExtraChargeCorrection
} from '/@/services/finance-service'
import { UnifiedCharges } from '/@/services/types'

const carrierCharges: Ref<UnifiedCharges> = ref(null)
const customerCharges: Ref<UnifiedCharges> = ref(null)
const allCharges = {
  CARRIER: carrierCharges,
  CUSTOMER: customerCharges
}

/**
 * A composition for working with breakdowns' extra charges
 * If you set types parameter, the charges will be loaded on the component mount and updated
 * on orderId change
 *
 * @param orderId   order id for getting and adding extra charges
 * @param types     set extra charge types for loading and updating charges automatically
 */
export default function useExtraCharges(
  orderId: Ref<number>,
  types: ExtraChargeType[] = null,
  isCancelled?: Ref<boolean>
) {
  const loadCarrierCharges = async (): Promise<void> => {
    carrierCharges.value = await fetchCarrierExtraCharges(orderId.value)
  }
  const loadCustomerCharges = async (): Promise<void> => {
    customerCharges.value = await fetchCustomerExtraCharges(orderId.value)
  }
  const loadCharges = async (type: ExtraChargeType) => {
    if (type === 'CARRIER') return await loadCarrierCharges()
    if (type === 'CUSTOMER') return await loadCustomerCharges()
  }

  onBeforeMount(() => {
    if (!types) return
    types.forEach(async type => {
      await loadCharges(type)

      watch(orderId, async () => {
        await loadCharges(type)
      })

      if (isCancelled) {
        watch(isCancelled, async value => {
          if (value) await loadCharges(type)
        })
      }
    })
  })

  return {
    allCharges,
    loadCarrierCharges,
    loadCharges,
    carrierCharges,
    loadCustomerCharges,
    customerCharges,
    addExtraCharge,
    addBaseAmountCorrection,
    addExtraChargeCorrection
  }
}
