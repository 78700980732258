/**
 * Contains reusable validators for *Field components.
 * Additionally it provides utility functions to compose validators with custom logic.
 */

import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'
import VueI18n from 'vue-i18n'

export type Validator<T> = {
  errorMessage: string
  isInvalid: (value: T) => boolean,
  messageValues?: {[key: string]: unknown}
}

export const REQUIRED_FIELD_MSG = 'This field cannot be empty'
export const REQUIRED_FIELD_KEY = 'finance/global-field-validator-required'

/**
 * TODO: Remove this function and replace its use by directing using applyValidatorWhen.
 *
 * it creates a required validator that can be enabled/disabled based on the return
 * value of the function argument.
 * @param isMandatory - function that enable/disable the required validator.
 */
export const requiredValidatorWhen = (isMandatory: () => boolean) =>
  applyValidatorWhen(isMandatory)(requiredValidator)

/**
 * set field as invalid when its string value is empty.
 */
export const requiredValidator: Validator<unknown> = {
  errorMessage: REQUIRED_FIELD_KEY,
  isInvalid: isEmpty
}

/**
 * set field as invalid when its value is zero.
 */
export const nonZeroValueValidator: Validator<string> = {
  isInvalid: (value: string) => !isNil(value) && parseFloat(value) === 0,
  errorMessage: 'finance/global-field-validator-non-zero'
}

/**
 * set field as invalid when the amount of non-whitespace characters is less than minChars.
 * @param minChars - minimum characters required.
 * @returns Validator
 */
export const minimumCharsValidator = (minChars: number): Validator<string> => ({
  errorMessage: 'finance/global-field-validator-min-chars',
  isInvalid: (value: string) => {
    return value && value.replace(/\s*/g, '').length < minChars
  },
  messageValues: { minChars }
})

/**
 * set dateTime field as invalid when its value is after the system current time.
 * @param dateTimeFormat - the format in which the component will provide the value.
 * @returns Validator
 */
export const beforeCurrentDateTimeValidator = (
  dateTimeFormat: string
): Validator<string> => ({
  errorMessage: 'finance/global-field-validator-before-today',
  isInvalid: (value: string) => {
    if (!value) return false
    return moment()
      .set('seconds', 0)
      .set('milliseconds', 0)
      .isBefore(
        moment(value, dateTimeFormat)
          .set('seconds', 0)
          .set('milliseconds', 0)
      )
  }
})

/**
 * Allow to transform the value of a field before sent to the validation.
 * @param transformValue - function than transform the field's value from type T to U.
 */
export const validatorWithValue = <T, U>(transformValue: (value: T) => U) => (
  validator: Validator<U>
) => {
  return {
    ...validator,
    isInvalid: (value: T) => validator.isInvalid(transformValue(value))
  }
}

/**
 * allows to apply existing validators with custom preconditions.
 *
 * Example:
 * the following code creates a validator that does not accept zero value in a field
 * when the order's state is NEW.
 *
 * const orderIsNew = () => props.order.state === 'NEW'
 * applyValidatorWhen(orderIsNew)(nonZeroValueValidator)
 *
 * @param preCondition - The condition used to whether or not execute the validator.
 */
export const applyValidatorWhen = <T>(preCondition: () => boolean) => (
  v: Validator<T>
): Validator<T> => ({
  ...v,
  isInvalid: (value: T) => preCondition() && v.isInvalid(value)
})


export function translateValidators<T>($t: VueI18n['t'], validators: Validator<T>[]) {
  return validators.map(v => ({ ...v, errorMessage: $t(v.errorMessage, v.messageValues)}))
}
