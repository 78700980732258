var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "claims-board__header header header--fixed" },
    [
      _c("div", { staticClass: "plankton-cell" }, [
        _vm.showHeader
          ? _c(
              "h1",
              { staticClass: "claims-board__header-title header__title" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("finance/claims-board-header-title")) +
                    "\n    "
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "claims-board__filters" },
        [
          _c("InputField", {
            staticClass: "claims-board__filter claims-board__filter--id",
            attrs: {
              title: _vm.$t("finance/claims-board-header-search-field-title"),
              noLabel: "",
              materialIcon: "search",
              "visible-selected-options-limit": 5,
              "height-fixed": false,
              placeholder: _vm.searchInputPlaceHolder,
              optionLabelWhenSelected: "id",
              "data-test": "claims-search-field"
            },
            on: { input: _vm.handleIdFilterInput },
            model: {
              value: _vm.idFilterValue,
              callback: function($$v) {
                _vm.idFilterValue = $$v
              },
              expression: "idFilterValue"
            }
          }),
          _vm._v(" "),
          _c("DateRangeInputField", {
            staticClass: "claims-board__filter claims-board__filter--date",
            attrs: {
              timePicker: "",
              autoApply: false,
              title: _vm.$t("finance/claims-board-header-date-field-title"),
              clearSelectionLabel: _vm.$t(
                "finance/claims-board-header-date-field-clear-label"
              ),
              validators: _vm.claimTimeValidators,
              inputDateFilter: _vm.dateTimeFormat,
              singleDatePicker: "",
              placeholder: _vm.$t(
                "finance/claims-board-header-date-field-placeholder"
              ),
              "data-test": "claims-date-field",
              "open-direction": "left",
              noLabel: ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                    "Backspace",
                    "Delete",
                    "Del"
                  ])
                ) {
                  return null
                }
                _vm.claimTimeForDateRange = {
                  startDate: null,
                  endDate: null
                }
              },
              input: _vm.handleDateInput
            },
            model: {
              value: _vm.claimTimeForDateRange,
              callback: function($$v) {
                _vm.claimTimeForDateRange = $$v
              },
              expression: "claimTimeForDateRange"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }