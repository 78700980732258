




























import { defineComponent, ref } from '@vue/composition-api'
import useModal from '/@/compositions/useModal'
import useStateReset from '../../compositions/useStateReset'
import ClaimDocumentsForm from './submit-claim-forms/ClaimDocumentsForm.vue'
import * as claimService from '/@/services/claim-service'
import { ClaimFileUpload } from '/@/compositions/useClaims'
import { Dialog } from '@sennder/plankton'

export default defineComponent({
  components: { Dialog, ClaimDocumentsForm },
  props: {
    canDelete: {type: Boolean, default: true }
  },
  setup() {
    const claimId = ref<number>(null)
    const documents = ref<ClaimFileUpload[]>([])
    const documentsToRemove = ref<ClaimFileUpload[]>([])
    const editMode = ref(false)
    const claimDocumentsForm = ref<InstanceType<typeof ClaimDocumentsForm>>(null)

    const modal = useModal({
      onConfirm: () => updateClaimDocuments()
    })
    const resetState = useStateReset([claimId, documents, documentsToRemove, editMode])

    const updateClaimDocuments = async () => {
      if (claimDocumentsForm.value.submit().hasErrors) return
      await Promise.all([
        deleteClaimFiles(documentsToRemove.value),
        submitNewClaimFiles(documents.value.filter(doc => !doc.submitted))
      ])
    }

    const deleteClaimFiles = (documents: ClaimFileUpload[]) => {
      if (documents.length > 0) {
        return claimService.deleteClaimFiles(documents.map(doc => parseInt(doc.id)))
      }
    }

    const submitNewClaimFiles = (docs: ClaimFileUpload[]) => {
      if (docs.length > 0) {
        return claimService.addClaimFiles(
          claimId.value,
          docs.map(doc => ({
            originalName: doc.name,
            uploadDirectory: doc.uploadDirectory,
            fileSize: doc.sizeBytes
          }))
        )
      }
    }

    const open = async (id: number, allowEditing: boolean) => {
      await loadSubmittedDocuments(id)
      editMode.value = allowEditing
      claimId.value = id
      return modal.open()
    }

    const loadSubmittedDocuments = async (claimId: number) => {
      const existingDocuments = await claimService.fetchClaimDocuments(claimId)
      documents.value = existingDocuments.map(item => ({
        id: item.id.toString(),
        name: item.businessName,
        sizeBytes: item.fileSize,
        uploadedDateTime: new Date(item.created),
        error: null,
        uploadDirectory: null,
        submitted: true
      }))
    }

    const handleRemovedDocument = (document: ClaimFileUpload) => {
      if (document.submitted) {
        documentsToRemove.value = [...documentsToRemove.value, document]
      }
    }

    const handleFileLoading = (loading: boolean) => {
      modal.loading.value = loading
    }

    return {
      modal,
      resetState,
      documents,
      editMode,
      open,
      handleRemovedDocument,
      handleFileLoading,
      claimDocumentsForm
    }
  }
})
