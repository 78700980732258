export const initials = person => {
  return (firstCharOf(person.firstName) + firstCharOf(person.lastName)).toUpperCase()
}

function firstCharOf(string) {
  return string ? string.charAt(0) : ''
}

export const fullName = person => {
  return person.fullName || [person.firstName, person.lastName].join(' ').trim()
}
