import { getCookie, setCookie, removeCookie } from 'tiny-cookie'
import { getJWTAuthToken, getJWTAuthTokenWithParams } from '@/auth0'

export const AUTH_TOKEN_OCTOPUS_COOKIE_NAME = 'auth_token_octopus'
export const JWT_TOKEN_OCTOPUS_COOKIE_NAME = 'jwt_token_octopus'

export const getAuthCookieValue = () => getCookie(AUTH_TOKEN_OCTOPUS_COOKIE_NAME)
export const getJWTCookieValue = () => getCookie(JWT_TOKEN_OCTOPUS_COOKIE_NAME)

export const setAuthCookieValue = (token: string) =>
  setCookie(AUTH_TOKEN_OCTOPUS_COOKIE_NAME, token, { expires: '7D' })

export const setJWTCookieValue = (token: string) =>
  setCookie(JWT_TOKEN_OCTOPUS_COOKIE_NAME, token, { expires: '7D' })

export const removeAuthCookieValue = () => removeCookie(AUTH_TOKEN_OCTOPUS_COOKIE_NAME)
export const removeJWTCookieValue = () => removeCookie(JWT_TOKEN_OCTOPUS_COOKIE_NAME)

export async function getAuthorizationHeader() {
  if (getJWTCookieValue()) {
    const JWTToken = await getJWTAuthToken()
    return `Bearer ${JWTToken}`
  }
  return `Token ${getAuthCookieValue()}`
}

export async function getAuthorizationHeaderWithParams(audience: string, scope?: string) {
  if (getAuthCookieValue()) {
    return `Token ${getAuthCookieValue()}`
  }
  const JWTToken = await getJWTAuthTokenWithParams(audience, scope)
  return `Bearer ${JWTToken}`
}

export function getAuthorizationToken() {
  if (getJWTCookieValue()) {
    return `Bearer ${getJWTCookieValue()}`
  }
  return `Token ${getAuthCookieValue()}`
}
