















import moment from 'moment'
import { computed, defineComponent } from '@vue/composition-api';
import { DateRangeInputField } from '@sennder/plankton'

type DateRange = { startDate: string; endDate: string };

export default defineComponent({
  components: { DateRangeInputField },
  props: {
    value: { type: String, default: null },
    dateTimeFormat: { type: String, required: true }
  },
  setup(props, context) {
    const adaptedValue = computed<DateRange>({
      get: () => {
        // transform date/time to its equivalent in user's local time.
        const formattedDateTime = props.value
          ? moment(moment(props.value).toDate()).format(props.dateTimeFormat)
          : null
        return { startDate: formattedDateTime, endDate: formattedDateTime }
      },
      set: (value) => {
        const newValue = moment(value.startDate, props.dateTimeFormat)
          // Transform date/time to its utc equivalent so it's send to the BE in that format.
          .utc()
          .toISOString()

        context.emit('input', newValue)
      },
    })

    return { adaptedValue }
  },
});
