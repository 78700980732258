// the code is basically from https://dev.to/muratkemaldar/using-vue-3-with-d3-composition-api-3h1g
import { reactive, onMounted, onBeforeUnmount, Ref } from '@vue/composition-api'

export const useResizeObserver = (resizeRef: Ref) => {
  const resizeState = reactive({
    contentRect: {} as DOMRectReadOnly
  })

  const observer = new ResizeObserver(entries => {
    entries.forEach(entry => {
      resizeState.contentRect = entry.contentRect
    })
  })

  onMounted(() => {
    resizeState.contentRect = resizeRef.value.getBoundingClientRect()
    observer.observe(resizeRef.value)
  })

  onBeforeUnmount(() => {
    observer.unobserve(resizeRef.value)
  })

  return { resizeState }
}

export default useResizeObserver
