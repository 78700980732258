var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "send-shipper-invoices-modal",
      attrs: {
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        confirmButtonDisabled: !_vm.hasRequirementsLoaded,
        title: _vm.$t("finance/send-shipper-invoices-modal-title"),
        confirmButtonText: _vm.$t(
          "finance/send-shipper-invoices-modal-confirm"
        ),
        cancelButtonText: _vm.$t("finance/send-shipper-invoices-modal-cancel"),
        inPlace: ""
      },
      on: {
        confirm: _vm.modal.confirm,
        cancel: _vm.modal.close,
        close: _vm.modal.close,
        hidden: _vm.resetState
      }
    },
    [
      _vm.errorMessage
        ? _c(
            "AlertMessage",
            {
              staticClass: "send-shipper-invoices-modal--alert",
              attrs: { type: "error" }
            },
            [_c("p", [_vm._v(_vm._s(_vm.errorMessage))])]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Form",
        {
          ref: "form",
          staticClass: "claim-details-form",
          attrs: {
            "data-test": "claim-details-form",
            disabled: !_vm.hasRequirementsLoaded
          }
        },
        [
          _c("div", { staticClass: "plankton-grid" }, [
            _c(
              "div",
              { staticClass: "plankton-cell", attrs: { span: "12" } },
              [
                _c("PrimitiveDropdownField", {
                  attrs: {
                    options: _vm.languageOptions,
                    validators: _vm.languageValidators,
                    optionKey: "value",
                    optionLabel: "label",
                    fullWidth: "",
                    title: _vm.$t(
                      "finance/send-shipper-invoices-modal-language-field-title"
                    ),
                    "data-test": "language-field"
                  },
                  model: {
                    value: _vm.language,
                    callback: function($$v) {
                      _vm.language = $$v
                    },
                    expression: "language"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "plankton-cell", attrs: { span: "12" } },
              [
                _c("DateTimeInputField", {
                  attrs: {
                    validators: _vm.dueDateValidators,
                    dateTimeFormat: _vm.dateFormat,
                    disabled: !_vm.hasRequirementsLoaded,
                    title: _vm.$t(
                      "finance/send-shipper-invoices-modal-due-date-field-title"
                    ),
                    placeholder: _vm.dateFormat.toLowerCase(),
                    "data-test": "due-date-field"
                  },
                  model: {
                    value: _vm.dueDate,
                    callback: function($$v) {
                      _vm.dueDate = $$v
                    },
                    expression: "dueDate"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }