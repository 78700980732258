















































import {
  ClaimStatus,
  ClaimTransition
} from '/@/services/types'
import { defineComponent, PropType, computed } from '@vue/composition-api'
import ClaimStatusLabel from './ClaimStatusLabel.vue'
import { ContextMenu, ContextMenuItem, Icon } from '@sennder/plankton'
import { statusTranslationKeys } from '/@/compositions/useClaims'

const SHOW_MESSAGE_INFO_STATUSES: ClaimStatus[] = ['APPROVED', 'REJECTED']

export default defineComponent({
  components: {
    ClaimStatusLabel,
    ContextMenu,
    ContextMenuItem,
    Icon
  },
  props: {
    status: {
      type: String as PropType<ClaimStatus>,
      default: 'SUBMITTED' as ClaimStatus
    },
    statusDescription: {
      type: String,
      default: 'Submitted'
    },
    editable: {
      type: Boolean,
      default: false
    },
    transitions: {
      type: Array as PropType<ClaimTransition[]>,
      default: [] as ClaimTransition[]
    },
    notes: {
      type: String,
      default: null
    }
  },
  setup(props) {
    return {
      showMessageInfo: computed(() => {
        return SHOW_MESSAGE_INFO_STATUSES.includes(props.status) && Boolean(props.notes)
      }),
      statusTranslationKeys
    }
  }
})
