var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "no-cost-centre-placeholder" },
    [
      _c("img", {
        staticClass: "no-cost-centre-placeholder__illustration",
        attrs: { src: require("@/assets/images/cost-centre-illustration.svg") }
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "no-cost-centre-placeholder__subtitle" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("finance/cost-centre-welcome-message")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("i18n", {
        staticClass: "no-cost-centre-placeholder__description",
        attrs: { path: "finance/cost-centre-description", tag: "p" },
        scopedSlots: _vm._u([
          {
            key: "faqLink",
            fn: function() {
              return [
                _c("Button", {
                  attrs: {
                    category: "link",
                    text: _vm.$t("finance/cost-centre-faq")
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("showFAQ")
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("Button", {
        staticClass: "no-cost-centre-placeholder__upload-button",
        attrs: {
          text: _vm.$t("finance/cost-centre-upload-button"),
          category: "secondary",
          materialIcon: "cloud_upload"
        },
        on: {
          click: function($event) {
            return _vm.$emit("upload")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }