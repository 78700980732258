var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "labeled-text",
      class: { "plankton-cell": _vm.span },
      attrs: { span: _vm.span }
    },
    [
      _vm.label
        ? _c("p", { staticClass: "paragraph labeled-text__label" }, [
            _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.text || _vm.$slots.default
        ? _c(
            "p",
            {
              staticClass: "subtitle labeled-text__text",
              attrs: { "data-test": "labeled-text--text" }
            },
            [
              _vm._t("default", function() {
                return [_vm._v(_vm._s(_vm.text))]
              })
            ],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }