var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.top",
          value: _vm.tooltipOptions,
          expression: "tooltipOptions",
          modifiers: { top: true }
        }
      ],
      staticClass: "claim-chip"
    },
    [
      _vm.$slots.default
        ? _c(
            "span",
            { staticClass: "claim-chip__icon" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "claim-chip__text label-text" }, [
        _vm._v(_vm._s(_vm.text))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }