var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "claim-status",
      class: "claim-status--" + _vm.status.toLowerCase(),
      attrs: { "data-test": "claim-status-label" }
    },
    [
      _c("span", { staticClass: "claim-status__indicator" }),
      _vm._v("\n  " + _vm._s(_vm.statusDescription) + "\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }