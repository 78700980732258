









import { CarrierCostCloseSideEffect, CarrierCostUpdateSideEffect, MicrofrontendEvents, subscribe } from '@sennder/senn-node-microfrontend-event-bus'
import {
  defineComponent,
  toRef,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import useChargeCorrectionSideEffects from '/@/compositions/useChargeCorrectionSideEffects'

export default defineComponent({
  props: {
    orderId: { type: Number, required: true },
  },
  emits: ['breakdown-closed'],
  setup(props, context) {
    // In theory, we should be able to get the same id from useOrder composition
    const orderId = toRef(props, 'orderId')

    const { triggerChargeCorrectionSideEffects } =
      useChargeCorrectionSideEffects(orderId)

    const handlePaymentsCostRefresh = ({ refreshInvoices }: { refreshInvoices: boolean }) => {
      triggerChargeCorrectionSideEffects(
        refreshInvoices ? 'CARRIER' : null
      )
    }

    // Event is handled by ClosingOrder.vue
    const handlePaymentsCostClose = () => {
      context.emit('breakdown-closed')
    }

    const eventsAbortController = new AbortController()

    onMounted(() => {
      subscribe<CarrierCostUpdateSideEffect>(MicrofrontendEvents.CarrierCostUpdateSideEffect, handlePaymentsCostRefresh, {
        signal: eventsAbortController.signal
      })
      
      subscribe<CarrierCostCloseSideEffect>(MicrofrontendEvents.CarrierCostCloseSideEffect, handlePaymentsCostClose, {
        signal: eventsAbortController.signal
      })
    })

    onUnmounted(() => {
      // unsubscribe from all events
      eventsAbortController.abort()
    })

    return {
      handlePaymentsCostRefresh,
    }
  },
})
