var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "carrier-order-payment" },
    [
      _c(
        "h4",
        { staticClass: "subsection-title carrier-order-payment__title" },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("finance/carrier-order-payment-title")) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("PaymentMessage", {
        staticClass: "carrier-order-payment__message",
        attrs: { paymentInfo: _vm.paymentInfo }
      }),
      _vm._v(" "),
      _c("BillingInfo", {
        attrs: {
          carrierId: _vm.carrierId,
          "data-test": "billing-info",
          "data-plankton-test": "billing-info"
        }
      }),
      _vm._v(" "),
      _c("PODReviewWidget", {
        attrs: { orderId: _vm.orderId, editable: _vm.editable }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }