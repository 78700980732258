var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "no-claims text-center pt-4 pb-4" }, [
    _vm.canSubmitShipperClaim
      ? _c(
          "div",
          [
            _c("img", {
              staticClass: "no-claims__pic",
              attrs: { src: require("@/assets/images/no_claims.svg") }
            }),
            _vm._v(" "),
            _c("h5", { staticClass: "page-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("finance/no-claims-shipper-side-title")) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("finance/no-claims-description")) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("Button", {
              staticClass: "mt-2",
              attrs: {
                category: "tertiary",
                text: _vm.$t("finance/no-claims-add-claim-label")
              },
              on: { click: _vm.fileShipperClaim }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.canSubmitCarrierClaim
      ? _c(
          "div",
          [
            _c("img", {
              staticClass: "no-claims__pic",
              attrs: { src: require("@/assets/images/no_claims_carrier.svg") }
            }),
            _vm._v(" "),
            _c("h5", { staticClass: "page-header" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("finance/no-claims-carrier-side-title")) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("finance/no-claims-description")) +
                  "\n    "
              )
            ]),
            _vm._v(" "),
            _c("Button", {
              staticClass: "mt-2",
              attrs: {
                category: "tertiary",
                text: _vm.$t("finance/no-claims-add-claim-label")
              },
              on: { click: _vm.fileCarrierClaim }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }