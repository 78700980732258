var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DateRangeInputField",
    _vm._b(
      {
        attrs: { inputDateFormat: _vm.dateTimeFormat, singleDatePicker: "" },
        model: {
          value: _vm.adaptedValue,
          callback: function($$v) {
            _vm.adaptedValue = $$v
          },
          expression: "adaptedValue"
        }
      },
      "DateRangeInputField",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }