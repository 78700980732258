import { fetchCostCentreAssignedToTruck, fetchCostCentreAssignedToUser } from '/@/services/cost-centre-service'
import { appOptions } from '/@/app-options'
import { fetchOrderAssignmentTruck } from '../services/fleet-service'

export default () => {
  const suggestOrderCostCentre = (orderId: number, carrierId: number, partnerManagerId: number) => {
    if (!carrierId) return null
    if (isOwnCarrierAsset(carrierId)) {
      return getCostCentreAssignedToCarrierTruck(orderId)
    } else {
      if (!partnerManagerId) return null
      return fetchCostCentreAssignedToUser(partnerManagerId)
    }
  }

  const getCostCentreAssignedToCarrierTruck = async (orderId: number) => {
    const truckId = await fetchOrderAssignmentTruck(orderId)
    if (!truckId) return null

    return fetchCostCentreAssignedToTruck(truckId)
  }

  const isOwnCarrierAsset = (carrierId: number) => {
    return appOptions.ownCarrierAssetIds.includes(carrierId.toString())
  }


  return {
    suggestOrderCostCentre
  }
}