import axios from 'axios'
import { CostCentre } from '.'
import { costCentreServiceApi } from './api'
import { transformKeysToCamelCase } from './casing'

export const uploadCostCentre = async (file: File) => {
  const data = new FormData()
  data.append('csv_file', file)
  await costCentreServiceApi.post('api/cost-centres/', data)
}

export const fetchCostCentres = async () => {
  const response = await costCentreServiceApi.get(
    'api/cost-centres/',
    // it manually set the content type to avoid the default behavior
    // of multiple content types(accept: application/json, text/plain, */*),
    // which triggers an bug in the API. 
    { headers: { accept: 'application/json' }}
  )
  return transformKeysToCamelCase<CostCentre[]>(response.data)
}


export const fetchCostCentresCSV = async () => {
  const response = await costCentreServiceApi.get(
    'api/cost-centres/',
    { headers: { accept: 'text/csv' }}
  )
  return response.data as string
}

export const fetchOrderCostCentre = async (orderId: number) => {
  try {
    const response = await costCentreServiceApi.get(
      `api/orders/${orderId}/cost-centre/`
    )
    return transformKeysToCamelCase<CostCentre>(response.data)
  } catch(error) {
    if (axios.isAxiosError(error) && error.response.status === 404) {
      return null
    }
    throw error
  }
}

export const updateOrderCostCentre = async (orderId: number, costCentreCode: string) => {
  await costCentreServiceApi.post(
    `api/orders/${orderId}/cost-centre/`,
    { code: costCentreCode }
  )
}

export const fetchCostCentreAssignedToTruck = async (truckId: number) => {
  try {
    const response = await costCentreServiceApi.get(
      `/api/trucks/${truckId}/cost-centre/`
    )
    return transformKeysToCamelCase<CostCentre>(response.data)
  } catch(error) {
    if (axios.isAxiosError(error) && error.response.status === 404) {
      return null
    }
    throw error
  }
}

export const fetchCostCentreAssignedToUser = async (userId: number) => {
  try {
    const response = await costCentreServiceApi.get(
      `/api/users/${userId}/cost-centre/`
    )
    return transformKeysToCamelCase<CostCentre>(response.data)
  } catch(error) {
    if (axios.isAxiosError(error) && error.response.status === 404) {
      return null
    }
    throw error
  }
}