

















































































































































import {
  defineComponent,
  PropType,
  ref,
  computed,
  toRef,
  Ref
} from '@vue/composition-api'
import useInvoices from '/@/compositions/useInvoices'
import {
  isValidDateWithDots,
  toIsoDate,
  getDateInApiFormat,
  humanizeIsoDate
} from '@sennder/plankton'
import { useModal } from '/@/compositions'
import { Invoice, PresignedUpload } from '/@/services/types'
import { Dialog, Form, DropdownField, DateInputField, InputField, MoneyInputField } from '@sennder/plankton'
import { requiredValidator, translateValidators } from '/@/field-validators'
import { DropzoneFile } from 'dropzone'
import { fetchPresignedUpload } from '../../services/finance-service'
import PresignedUploadSection from '/@/components/shared/PresignedUploadSection.vue'
import {
  FileUpload,
  InProgressFileUpload,
  FileWithDirectory,
  default as useUploadingFiles,
} from '/@/compositions/useUploadingFiles'
import { AlertMessage, UploadedFile } from '@sennder/plankton'
import { BYTES_IN_ONE_MB, formatBytesToMB } from '/@/utils/formatBytesToMB'
import toASCII from '/@/utils/toASCII'

const MAX_TOTAL_FILES = 1
const MAX_TOTAL_SIZE_BYTES = 10 * BYTES_IN_ONE_MB
const MAX_FILE_NAME_LENGTH = 128
const SUPPORTED_MIME_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/gif',
  '.pdf',
  '.png',
  '.jpeg',
  '.gif'
]

export default defineComponent({
  components: {
    Dialog,
    Form,
    DropdownField,
    DateInputField,
    InputField,
    MoneyInputField,
    PresignedUploadSection,
    AlertMessage,
    UploadedFile
  },
  props: {
    orderId: { type: Number, required: true },
    invoiceType: {
      required: true,
      type: String as PropType<'CARRIER' | 'SHIPPER'>
    }
  },

  setup(props, { emit, root }) {
    const form = ref()
    const orderId = toRef(props, 'orderId')
    const invoiceType = toRef(props, 'invoiceType')
    const invoice: Ref<Invoice> = ref(buildNewInvoice())
    const loading = ref(false)
    const creditNoteOptions = [
      { label: root.$t('finance/add-invoice-modal-type-invoice-label'), value: 0 },
      { label: root.$t('finance/add-invoice-modal-type-credit-note-label'), value: 1 }
    ]
    const modal = useModal({
      onBeforeClose() {
        files.value = []
      }
    })
    

    const isCreditNote = computed({
      get: () => {
        if (invoice.value.isCreditNote === null) return null
        return creditNoteOptions.find(el => el.value === +invoice.value.isCreditNote)
      },
      set: val => {
        invoice.value.isCreditNote = Boolean(val?.value)
      }
    })

    const invoiceIsCNValidator = translateValidators(root.$t.bind(root), [requiredValidator])

    const { addCarrierInvoice, addShipperInvoice } = useInvoices(orderId)

    const canCreate = computed(() => {
      return (
        invoice.value?.amount &&
        invoiceHasValidDate.value &&
        invoice.value?.number &&
        isCreditNote.value !== null &&
        aggregateErrors.value.length === 0
      )
    })

    const invoiceHasValidDate = computed(() => {
      return invoiceDateHuman.value && isValidDateWithDots(invoiceDateHuman.value)
    })

    const clearFormInputs = () => {
      invoiceDateHumanRef.value = ''
      invoice.value = buildNewInvoice()
    }

    const addInvoice = async () => {
      loading.value = true
      if (files.value.length > 0) {
        invoice.value.filePath = `${files.value[0].uploadDirectory}/${files.value[0].name}`
      }
      if (invoiceType.value === 'CARRIER') await addCarrierInvoice(invoice.value)
      if (invoiceType.value === 'SHIPPER') await addShipperInvoice(invoice.value)
      emit('added', invoiceType.value)
      loading.value = false
      modal.close()
      clearFormInputs()
    }

    function buildNewInvoice() {
      return {
        orderId: props.orderId,
        number: '',
        amount: 0,
        issueDate: '',
        isCreditNote: null,
      } as Invoice
    }

    const invoiceDateHumanRef = ref('')
    const invoiceDateHuman: Ref<string> = computed({
      set(val) {
        invoiceDateHumanRef.value = val
        invoice.value.issueDate = getDateInApiFormat(humanizeIsoDate(toIsoDate(val)))
      },
      get() {
        return invoiceDateHumanRef.value
      }
    })

    /* Upload section */

    const files = ref<FileWithDirectory[]>([])

    const presignedUploads: { [id: string]: PresignedUpload } = {}

    const addUploadedFile = (uploadedFile: FileUpload) => {
      const presignedUpload = presignedUploads[uploadedFile.id]
      const FileWithDirectory = {
        ...uploadedFile,
        uploadDirectory: presignedUpload ? presignedUpload.uploadDirectory : null
      }
      files.value.push(FileWithDirectory)
    }

    const fetchPresignedFileUpload = async (file: DropzoneFile) => {
      const presignedUpload = await fetchPresignedUpload(file.upload.filename)
      presignedUploads[file.upload.uuid] = presignedUpload

      return {
        url: presignedUpload.url,
        fields: presignedUpload.fields
      }
    }

    const {
      inProgressFiles,
      addFile,
      removeFile,
      handleFileError,
      handleUploadProgress,
      handleUploadSuccess
    } = useUploadingFiles({ onFileUploaded: file => addUploadedFile(file) })

    const fileValidator = (file: DropzoneFile) => {
      if (file.upload.filename.length > MAX_FILE_NAME_LENGTH) {
        return Promise.reject(
          root.$t(
            'finance/add-invoice-modal-error-exceeded-file-name',
            { nameLen: file.upload.filename.length, maxNameLen: MAX_FILE_NAME_LENGTH }
          )
        )
      }
      return Promise.resolve()
    }

    const renameFile = (file: DropzoneFile) => {
      return toASCII(file.name)
    }

    const completefiles = computed(() => [
      ...inProgressFiles.value,
      ...files.value
    ])

    const aggregateErrors = computed(() => {
      const errors = []
      if (files.value.length > MAX_TOTAL_FILES) {
        errors.push(
          root.$t(
            'finance/add-invoice-modal-error-exceeded-total-files',
            { filesCount: files.value.length, maxFilesCount: MAX_TOTAL_FILES }
          )
        )
      }
      const totalSizeBytes = files.value.reduce((acc, f) => acc + f.sizeBytes, 0)
      if (totalSizeBytes > MAX_TOTAL_SIZE_BYTES) {
        errors.push(
          root.$t(
            'finance/add-invoice-modal-error-exceeded-total-size',
            { totalSize: formatBytesToMB(totalSizeBytes) }
          )
        )
      }

      return errors
    })

    const deleteFile = (file: FileWithDirectory) => {
      if (isInProgressFile(file)) removeFile(file)
      else removeUploadedFile(file.id)
    }

    const removeUploadedFile = (id: string) => {
      files.value = files.value.filter(f => f.id !== id)
    }

    const isInProgressFile = (
      file: FileWithDirectory | InProgressFileUpload
    ): file is InProgressFileUpload => {
      return (file as InProgressFileUpload).uploadedBytes !== undefined
    }

    const getReadableErrorMessage = (error: string) => {
      if (!error) return null
      if (error === 'INVALID_FILE_TYPE') return root.$t('finance/add-invoice-modal-error-invalid-file')
      return error.toString()
    }

    return {
      ...modal,
      invoiceDateHuman,
      form,
      invoiceIsCNValidator,
      invoice,
      loading,
      creditNoteOptions,
      canCreate,
      clearFormInputs,
      addInvoice,
      invoiceHasValidDate,
      isCreditNote,
      SUPPORTED_MIME_TYPES,
      addFile,
      handleFileError,
      fetchPresignedFileUpload,
      handleUploadProgress,
      handleUploadSuccess,
      renameFile,
      completefiles,
      fileValidator,
      MAX_TOTAL_FILES,
      aggregateErrors,
      deleteFile,
      getReadableErrorMessage
    }
  }
})
