






























































import {
  defineComponent,
  ref,
} from '@vue/composition-api';
import useModal from '/@/compositions/useModal';
import useStateReset from '/@/compositions/useStateReset';
import { fetchShipperInvoiceGenerationRequirements, sendShipperInvoices } from '/@/services/invoices-service';
import { PrimitiveDropdownField, Form, Dialog, AlertMessage } from '@sennder/plankton'
import DateTimeInputField from '/@/components/shared/DateTimeInputField.vue'
import { requiredValidator, translateValidators } from '/@/field-validators'
import { isServiceError } from '/@/services'
import moment from 'moment'


export default defineComponent({
  components: { Dialog, DateTimeInputField, PrimitiveDropdownField, Form, AlertMessage }, 
  setup(_, context) {
    const form = ref(null);
    const language = ref<string>();
    const languageOptions = ref<{ label: string; value: string }[]>([]);
    const dueDate = ref<string>();
    const orderIds = ref<number[]>([])
    const errorMessage = ref<string>()
    const hasRequirementsLoaded = ref<boolean>(false)
    const dateFormat = 'DD.MM.YYYY'
    const serviceFormat = 'YYYY-MM-DD'
    const $t = context.root.$t.bind(context.root)

    const resetState = useStateReset([language, dueDate, orderIds, errorMessage, hasRequirementsLoaded]);
    const modal = useModal({
      isInvalid: () => form.value.submit().hasErrors,
      onConfirm: async () => {
        try {
          await sendShipperInvoices(
            orderIds.value,
            language.value,
            moment(dueDate.value).format(serviceFormat)
          )
        } catch (error) {
          if (isServiceError(error)) {
            errorMessage.value = error.errorMessage
          }
          throw error
        }
      }
    })

    const open = (_orderIds: number[] = []) => {
      orderIds.value = _orderIds
      fetchGenerationRequirements(_orderIds)
      modal.open()
    }

    const fetchGenerationRequirements = async (orderIds: number[]) => {
      try {
        const requirements = await fetchShipperInvoiceGenerationRequirements(orderIds);
        dueDate.value = requirements.paymentDate;
        languageOptions.value =  generateLanguageOptions(requirements.languages)
        hasRequirementsLoaded.value = true
      } catch(error) {
        if (isServiceError(error)) {
          errorMessage.value = error.errorMessage
        }
      }
    }

    const generateLanguageOptions = (languages: string[]): {label: string, value: string}[] => {
      return languages.map((lang) => {
        return {
          label: context.root.$t(
            `finance/send-shipper-invoices-modal-language-${lang}`
          ) as string,
          value: lang,
        }
      })

    }

    return {
      form,
      modal,
      resetState,
      languageOptions,
      language,
      dueDate,
      dateFormat,
      open,
      errorMessage,
      hasRequirementsLoaded,
      languageValidators: translateValidators($t, [requiredValidator]),
      dueDateValidators: translateValidators($t, [requiredValidator])
    }
  },
})
