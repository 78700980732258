var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LabeledText", {
    staticClass: "order-header__info-el",
    class: _vm.marginPercentageClass,
    attrs: { label: _vm.label, text: _vm.formattedMarginPercentage }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }