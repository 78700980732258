var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "claim-card",
      class: "claim-card--" + _vm.status.toLowerCase()
    },
    [
      _c("header", { staticClass: "claim-card__header" }, [
        _c(
          "h3",
          {
            staticClass: "claim-card__header-title subsection-title",
            attrs: { "data-test": "claim-card-title" }
          },
          [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "claim-card__value subsection-title",
            attrs: { "data-test": "claim-card-value" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.formatMoneyWithZeroForNull(_vm.amount)) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "claim-card__body" }, [
        _c("div", { staticClass: "label-text claim-card__label" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("finance/claim-card-description-label")) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "claim-card__description paragraph",
            class: {
              "claim-card__description--empty": !_vm.description
            },
            attrs: { "data-test": "claim-card-desc" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.description ||
                    _vm.$t("finance/claim-card-no-description-label")
                ) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "footer",
        { staticClass: "claim-card__footer paragraph" },
        [
          _c("ClaimStatusView", {
            attrs: {
              status: _vm.status,
              statusDescription: _vm.statusDescriptionWithAmount,
              transitions: _vm.statusTransitions,
              editable: _vm.canUpdateStatus,
              notes: _vm.notes,
              "data-test": "claim-card-status"
            },
            on: {
              "update-status": function($event) {
                return _vm.$emit("update-status", $event)
              }
            }
          }),
          _vm._v(" "),
          _vm.editable
            ? _c(
                "div",
                {
                  staticClass: "claim-card__actions",
                  attrs: { "data-test": "claim-card-actions" }
                },
                [
                  _vm.mainAction
                    ? _c("Button", {
                        staticClass: "claim-card__action",
                        attrs: {
                          category: "link",
                          text: _vm.mainAction,
                          "data-test": "claim-card-action"
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("action-click", _vm.mainAction)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }