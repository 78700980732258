var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-test": "customer-charges-breakdown" } },
    [
      _c("ChargesBreakdown", {
        attrs: {
          closedTimestamp: _vm.customerPriceClosedTimestamp,
          isClosed: _vm.isCustomerPriceClosed,
          baseAmount: _vm.basePrice,
          totalNetAmount: _vm.totalNetPrice,
          baseTitle: _vm.$t("finance/customer-charges-breakdown-base-title"),
          totalTitle: _vm.$t("finance/customer-charges-breakdown-total-title"),
          charges: _vm.customerCharges,
          title: _vm.$t("finance/customer-charges-breakdown-title"),
          compact: _vm.compact,
          canAddSurcharge: _vm.canAddSurcharge,
          canCloseBreakdown: _vm.canCloseBreakdown
        },
        on: {
          "add-charge": _vm.addExtraChargeOrCorrection,
          "close-breakdown": _vm.closePriceAfterConfirmation
        }
      }),
      _vm._v(" "),
      _c(
        "ConfirmationModal",
        {
          ref: "closePriceModal",
          attrs: {
            title: _vm.$t(
              "finance/customer-charges-breakdown-close-price-title"
            ),
            confirmButtonText: _vm.$t(
              "finance/customer-charges-breakdown-close-price-confirm-button"
            ),
            "data-test": "close-breakdown-modal"
          }
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t(
                  "finance/customer-charges-breakdown-close-price-description"
                )
              ) +
              "\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("ExtraChargeCorrectionModal", {
        ref: "extraChargeCorrectionModal",
        attrs: {
          orderId: _vm.orderId,
          refreshInvoicesOnNewCharges: _vm.refreshInvoicesOnNewCharges,
          type: "CUSTOMER",
          "data-test": "surcharge-correction-modal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }