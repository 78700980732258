


















import SidebarCard from '/@/components/shared/SidebarCard.vue'
import LabeledText from '/@/components/shared/LabeledText.vue'
import { computed, toRef, defineComponent, Ref, PropType } from '@vue/composition-api'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import { FinanceOrder, VehicleType } from '/@/app-options'
import { VEHICLE_TYPES, LOAD_TYPES } from '@sennder/octopus-constants'

type specificationType = {
  value: string | number
  suffix?: string
}

export default defineComponent({
  components: { SidebarCard, LabeledText },
  props: {
    order: {
      type: Object as PropType<FinanceOrder>,
      required: true
    }
  },
  setup(props, context) {
    const order = toRef(props, 'order')

    const getVehicleLabel = (value: VehicleType) => VEHICLE_TYPES[value]

    const vehicleTypes = computed(() => {
      if (!order.value.allowedVehicleTypes) return '-'
      return order.value.allowedVehicleTypes.map(getVehicleLabel).join(', ')
    })
    const joinSpecificationValues = (values: (number | string)[], separator = 'x') => {
      return values.filter(v => !isNil(v)).join(` ${separator} `)
    }
    const loadTypeLabel = computed(() => {
      if (!order.value.loadUnitType) return null
      const type = find(LOAD_TYPES, { value: order.value.loadUnitType })
      return type?.label
    })
    const loadQuantityAndType = computed(() => {
      return joinSpecificationValues([order.value.loadQuantity, loadTypeLabel.value])
    })
    const loadDimensions = computed(() => {
      return joinSpecificationValues([
        order.value.loadLength,
        order.value.loadHeight,
        order.value.loadWidth
      ])
    })
    const loadTemperatures = computed(() => {
      if (!order.value.isTemperatureControlled) return null
      return joinSpecificationValues(
        [order.value.loadMinimumTemperature, order.value.loadMaximumTemperature],
        context.root.$t('finance/load-info-range-limit-prep')
      )
    })

    const specifications: Ref<specificationType[]> = computed(() => {
      return [
        {
          value: vehicleTypes.value,
          suffix: ''
        },
        {
          value: loadQuantityAndType.value,
          suffix: ''
        },
        {
          value: order.value.loadWeight,
          suffix: ' t'
        },
        {
          value: loadDimensions.value,
          suffix: ' cm'
        },
        {
          value: loadTemperatures.value,
          suffix: ' °C'
        }
      ]
    })

    const specificationsString = computed(() => {
      return specifications.value
        .filter(({ value }) => value)
        .map(({ value, suffix }) => `${value}${suffix}`)
        .join(' | ')
    })
    return {
      specificationsString
    }
  }
})
