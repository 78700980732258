



































import useModal from '/@/compositions/useModal'
import { defineComponent, ref } from '@vue/composition-api'
import { RejectClaimPayload } from '/@/services/claim-service'
import { Dialog, Form, LimitedTextAreaField } from '@sennder/plankton'

export default defineComponent({
  components: { Dialog, Form, LimitedTextAreaField },
  setup() {
    const form = ref(null)
    const rejectionNote = ref(null)
    let respond: (value: RejectClaimPayload) => void = null

    const isInvalid = () => Boolean(form.value.submit().hasErrors)

    const modal = useModal({
      onBeforeClose() {
        rejectionNote.value = null
        respond(null)
      },
      onConfirm() {
        respond({
          note: rejectionNote.value
        })
        rejectionNote.value = null
      },
      isInvalid
    })
    const requestRejection = () => {
      modal.open()
      return new Promise<RejectClaimPayload>(resolve => {
        respond = resolve
      })
    }

    return {
      modal,
      requestRejection,
      rejectionNote,
      form
    }
  }
})
