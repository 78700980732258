var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass: "breakdown-row",
      class:
        ((_obj = {}),
        (_obj["breakdown-row--" + _vm.modifier] = _vm.modifier),
        (_obj.subtitle = _vm.modifier !== "strong"),
        (_obj["subsection-title"] = _vm.modifier === "strong"),
        (_obj["breakdown-row--border"] = _vm.hasBorder),
        _obj),
      attrs: { "data-test": "breakdown-row" }
    },
    [
      _c(
        "td",
        {
          staticClass: "breakdown-row__title",
          attrs: { "data-test": "row-title" }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
          _vm.note
            ? _c(
                "span",
                {
                  staticClass: "breakdown-row__title-note label-text",
                  attrs: { "data-test": "row-note" }
                },
                [_vm._v("\n      " + _vm._s(_vm.note) + "\n    ")]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticClass: "breakdown-row__value",
          attrs: { "data-test": "row-value" }
        },
        [_vm._v("\n    " + _vm._s(_vm.value) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }