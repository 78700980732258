










































































































import { computed, watch, PropType, defineComponent } from '@vue/composition-api'
import SidebarCard from '/@/components/shared/SidebarCard.vue'
import getAlphabetEquivalent from '/@/compositions/useStopLetter'
import LabeledText from '/@/components/shared/LabeledText.vue'
import { hasHadState } from '/@/order-states'
import { appOptions, FinanceOrder } from '/@/app-options'
import { VerticalStepper } from '@sennder/plankton'

export default defineComponent({
  components: { SidebarCard, LabeledText, VerticalStepper },
  props: {
    order: { type: Object as PropType<FinanceOrder>, required: true },
    stops: { type: Array, required: true }
  },
  setup(props) {
    const isOrderDispatched = computed(() => {
      return hasHadState(props.order.state, 'DISPATCHED')
    })

    const { steps, loadSteps } = appOptions.libraries.useSteps()
    watch(
      () => props.order,
      order => {
        loadSteps(order?.transferId)
      },
      { immediate: true }
    )
    const hasStepsLoaded = computed(() => {
      return steps?.value?.length === props.stops.length * 2
    })

    return {
      getAlphabetEquivalent,
      isOrderDispatched,
      hasStepsLoaded,
      steps
    }
  }
})
