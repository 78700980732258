import { Ref, ref } from '@vue/composition-api'
import range from 'lodash/range'

export enum WizardStep {
  ClaimDetails = 0,
  SurchargeAndDocuments
}

type Form = {
  submit(): { hasErrors: boolean }
}

export const useWizardController = ({ onLastStep }: { onLastStep(): void }) => {
  const formCount = 2
  const activeStep = ref<WizardStep>(WizardStep.ClaimDetails)
  const wizardStepForms: Ref<Form>[] = range(formCount).map(() => ref(null))

  const handleNext = (isLastStep: boolean) => {
    const currentForm = wizardStepForms[activeStep.value].value
    if (currentForm.submit && currentForm.submit().hasErrors) return

    if (isLastStep) onLastStep()
    else activeStep.value += 1
  }

  const handleBack = () => {
    activeStep.value -= 1
  }

  return {
    handleNext,
    handleBack,
    activeStep,
    wizardStepForms
  }
}