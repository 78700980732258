var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "Dialog",
        {
          staticClass: "add-invoice-modal",
          attrs: {
            show: _vm.visible,
            title: _vm.$t("finance/add-invoice-modal-title"),
            confirmButtonText: _vm.$t(
              "finance/add-invoice-modal-confirm-label"
            ),
            cancelButtonText: _vm.$t("finance/add-invoice-modal-cancel-label"),
            "data-test": "add-invoice-modal",
            confirmButtonDisabled: !_vm.canCreate,
            inPlace: ""
          },
          on: {
            cancel: _vm.close,
            close: _vm.close,
            confirm: _vm.addInvoice,
            hidden: _vm.clearFormInputs
          }
        },
        [
          _c("p", { staticClass: "subtitle add-invoice-modal__text" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("finance/add-invoice-modal-description")) +
                "\n  "
            )
          ]),
          _vm._v(" "),
          _c(
            "Form",
            {
              ref: "form",
              staticClass: "plankton-grid",
              attrs: { columns: "12" },
              on: {
                "validated-submit": _vm.addInvoice,
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  _vm.canCreate && _vm.form.submit()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "plankton-cell", attrs: { span: "5" } },
                [
                  _c("DropdownField", {
                    staticClass: "add-invoice-modal__dropdown",
                    attrs: {
                      validators: _vm.invoiceIsCNValidator,
                      options: _vm.creditNoteOptions,
                      optionLabel: "label",
                      optionKey: "label",
                      placeholder: _vm.$t(
                        "finance/add-invoice-modal-type-field-placeholder"
                      ),
                      title: _vm.$t(
                        "finance/add-invoice-modal-type-field-title"
                      ),
                      name: "invoice-type",
                      "data-test": "invoice-type-dropdown"
                    },
                    model: {
                      value: _vm.isCreditNote,
                      callback: function($$v) {
                        _vm.isCreditNote = $$v
                      },
                      expression: "isCreditNote"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "plankton-cell", attrs: { span: "7" } },
                [
                  _c("DateInputField", {
                    staticClass: "add-invoice-modal__input",
                    attrs: {
                      "data-test": "invoice-date",
                      title: _vm.$t(
                        "finance/add-invoice-modal-date-field-title"
                      ),
                      placeholder: "dd.mm.yyyy",
                      useDotAsSeparator: ""
                    },
                    model: {
                      value: _vm.invoiceDateHuman,
                      callback: function($$v) {
                        _vm.invoiceDateHuman = $$v
                      },
                      expression: "invoiceDateHuman"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "plankton-cell", attrs: { span: "8" } },
                [
                  _c("InputField", {
                    staticClass: "add-invoice-modal__input",
                    attrs: {
                      maxlength: "100",
                      fullWidth: true,
                      "data-test": "invoice-number",
                      title: _vm.$t(
                        "finance/add-invoice-modal-number-field-title"
                      ),
                      placeholder: _vm.$t(
                        "finance/add-invoice-modal-number-field-placeholder"
                      )
                    },
                    model: {
                      value: _vm.invoice.number,
                      callback: function($$v) {
                        _vm.$set(_vm.invoice, "number", $$v)
                      },
                      expression: "invoice.number"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "plankton-cell", attrs: { span: "4" } },
                [
                  _c("MoneyInputField", {
                    staticClass: "add-invoice-modal__input",
                    attrs: {
                      allowInteger: 5,
                      allowDecimal: 2,
                      allowNegative: true,
                      title: _vm.$t(
                        "finance/add-invoice-modal-amount-field-title"
                      ),
                      "data-test": "invoice-amount"
                    },
                    model: {
                      value: _vm.invoice.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.invoice, "amount", $$v)
                      },
                      expression: "invoice.amount"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "plankton-cell add-invoice-modal__files",
                  attrs: { span: "12" }
                },
                [
                  _vm.aggregateErrors.length > 0
                    ? _c(
                        "AlertMessage",
                        {
                          staticClass: "add-invoice-modal__errors",
                          attrs: {
                            type: "error",
                            "data-test": "general-errors"
                          }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "add-invoice-modal__errors-list" },
                            _vm._l(_vm.aggregateErrors, function(error) {
                              return _c("li", { key: error }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "add-invoice-modal__error-text"
                                  },
                                  [_vm._v(_vm._s(error))]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("PresignedUploadSection", {
                    staticClass: "add-invoice-modal__upload-section",
                    attrs: {
                      mimeTypes: _vm.SUPPORTED_MIME_TYPES,
                      onFileAdded: _vm.addFile,
                      fileValidatorAsync: _vm.fileValidator,
                      onFileError: _vm.handleFileError,
                      fetchPresignedUpload: _vm.fetchPresignedFileUpload,
                      onUploadProgress: _vm.handleUploadProgress,
                      onUploadSuccess: _vm.handleUploadSuccess,
                      renameFile: _vm.renameFile,
                      title: _vm.$t("finance/add-invoice-modal-upload-title"),
                      description: _vm.$t(
                        "finance/add-invoice-modal-upload-section-description",
                        { MAX_TOTAL_FILES: _vm.MAX_TOTAL_FILES }
                      ),
                      compact: "",
                      "data-test": "add-invoice-document-section"
                    }
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.completefiles, function(file) {
                    return _c("UploadedFile", {
                      key: file.id,
                      staticClass: "add-invoice-modal__file",
                      attrs: {
                        fileName: file.name,
                        fileSizeInBytes: file.sizeBytes,
                        uploadedBytes: file.uploadedBytes,
                        uploadDate: file.uploadedDateTime,
                        error: _vm.getReadableErrorMessage(file.error),
                        clickHandler: file.submitted
                          ? function() {
                              return _vm.downloadFile(file)
                            }
                          : null,
                        "data-test": "invoice-file"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "side-content",
                            fn: function() {
                              return [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons add-invoice-modal__delete-button",
                                    attrs: { "data-test": "delete-file" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFile(file)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            delete_outline\n          "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  })
                ],
                2
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }