import { render, staticRenderFns } from "./ClaimDocumentsModal.vue?vue&type=template&id=98c93d6e&"
import script from "./ClaimDocumentsModal.vue?vue&type=script&lang=ts&"
export * from "./ClaimDocumentsModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/sennder/operations-finance/finance/finance-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98c93d6e')) {
      api.createRecord('98c93d6e', component.options)
    } else {
      api.reload('98c93d6e', component.options)
    }
    module.hot.accept("./ClaimDocumentsModal.vue?vue&type=template&id=98c93d6e&", function () {
      api.rerender('98c93d6e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/claims/ClaimDocumentsModal.vue"
export default component.exports