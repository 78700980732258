var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.paymentInfo && _vm.paymentInfo.isEligibleForAutomaticCreditNotes
    ? _c(
        "AlertMessage",
        {
          staticClass: "payment-message",
          attrs: { type: "success", "data-test": "payment-message" }
        },
        [
          !_vm.paymentInfo.isCarrierCostClosed
            ? _c("span", [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("finance/payment-message-not-started")) +
                    "\n  "
                )
              ])
            : _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("finance/payment-message-started", {
                      paymentStartedDate: _vm.formattedPaymentStartedDate,
                      paymentDate: _vm.formattedPaymentDate
                    })
                  )
                }
              })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }