import { computed, ComputedRef, Ref } from '@vue/composition-api'
import useExtraCharges from './useExtraCharges'
import {
  ExtraCharge,
  IssueCategoryCode,
  ExtraChargeType,
  ISSUE_CATEGORIES_CODES
} from '/@/services/types'
import { BASE_AMOUNT_LABELS } from '/@/constants'
import { ISSUE_CATEGORY_LABELS } from '/@/services/types'
import { appOptions } from '/@/app-options'
import VueI18n from 'vue-i18n'

type DropdownOption<T> = { label: string; value: T }
interface AdjustmentStrategy {
  categoryOptions: ComputedRef<DropdownOption<string | number>[]>
  save(category: string | number, amount: number, comment: string): Promise<void>
}
export type AdjustmentType = 'EXTRA_CHARGE' | 'EXTRA_CHARGE_CORRECTION'

/**
 * Implementation of the strategy pattern to easily change the modal behavior
 * based for the creation of ExtraCharge or Corrections.
 */
export default (
  type: Ref<AdjustmentType>,
  extraChargeType: Ref<ExtraChargeType>,
  $t: VueI18n['t']
): AdjustmentStrategy => {
  const strategy = computed(() => {
    if (!type.value) return null
    return type.value === 'EXTRA_CHARGE'
      ? useExtraChargeStrategy(extraChargeType.value, $t)
      : useExtraChargeCorrectionStrategy(extraChargeType.value, $t)
  })
  const categoryOptions = computed<DropdownOption<string | number>[]>(() => {
    if (!strategy.value) return []
    return strategy.value.categoryOptions.value
  })
  const save = (category: string | number, amount: number, comment: string) => {
    if (!strategy.value) return
    return strategy.value.save(category, amount, comment)
  }

  return { categoryOptions, save }
}

const useExtraChargeStrategy = (extraChargeType: ExtraChargeType, $t: VueI18n['t']): AdjustmentStrategy => {
  const { order } = appOptions.useOrder()
  const orderId = computed(() => order.value.id)
  const { addExtraCharge } = useExtraCharges(orderId)

  const categoryOptions = computed(() => {
    /**
     * Note: Filter out AUTOMATIC_DIESEL_SURCHARGE since this kind of extra charges
     * are created automatically by the system and not manually by users.
     * DOUBLE_DRIVER_SURCHARGE,SEMI_TRAILER_SURCHARGE,FERRY_SURCHARGE are temporary filtered for sennder global.
     */

    const excludedSurcharges = ['AUTOMATIC_DIESEL_SURCHARGE']
    if (appOptions.isSennderTheme) {
      excludedSurcharges.push(
        'DOUBLE_DRIVER_SURCHARGE',
        'SEMI_TRAILER_SURCHARGE',
        'FERRY_SURCHARGE'
      )
    }

    return ISSUE_CATEGORIES_CODES.filter(code => !excludedSurcharges.includes(code)).map(
      code => ({
        label: $t(ISSUE_CATEGORY_LABELS[code]) as string,
        value: code
      })
    )
  })

  const save = (category: IssueCategoryCode, amount: number, comment: string) => {
    return addExtraCharge(
      {
        order: order.value.id,
        amount: amount,
        summary: comment,
        issueCategory: category
      },
      extraChargeType
    )
  }

  return { categoryOptions, save }
}

const useExtraChargeCorrectionStrategy = (
  extraChargeType: ExtraChargeType,
  $t: VueI18n['t']
): AdjustmentStrategy => {
  const CORRECTION_BASE_AMOUNT_OPTION = {
    label: $t(BASE_AMOUNT_LABELS[extraChargeType]) as string,
    value: -1
  }
  const { order } = appOptions.useOrder()
  const orderId = computed(() => order.value.id)
  const {
    allCharges,
    addBaseAmountCorrection,
    addExtraChargeCorrection
  } = useExtraCharges(orderId)

  const categoryOptions = computed(() => {
    return enumerateOptionLabels([
      CORRECTION_BASE_AMOUNT_OPTION,
      ...getExtraChargeCorrectionOptions(
        allCharges[extraChargeType].value?.extraCharges || []
      )
    ])
  })

  const getExtraChargeCorrectionOptions = (extraCharges: ExtraCharge[]) => {
    return extraCharges.map(extraCharge => ({
      label: $t(ISSUE_CATEGORY_LABELS[extraCharge.issueCategory]) as string,
      value: extraCharge.id
    }))
  }

  const enumerateOptionLabels = (options: { label: string; value: number }[]) => {
    const letterCode = 65 // code of the 'A' char
    return options.map((option, index) => ({
      label: `${String.fromCharCode(letterCode + index)}. ${option.label}`,
      value: option.value
    }))
  }

  const save = (value: number, amount: number, comment: string) => {
    if (value === CORRECTION_BASE_AMOUNT_OPTION.value) {
      return addBaseAmountCorrection(order.value.id, amount, comment, extraChargeType)
    } else {
      return addExtraChargeCorrection(value, amount, comment, extraChargeType)
    }
  }

  return { categoryOptions, save }
}
