import { Ref } from '@vue/composition-api'
import { ExtraChargeType } from '../services'

import { appOptions } from '/@/app-options'
import useInvoices from '/@/compositions/useInvoices'

export default (orderId: Ref<number>) => {
  const { refreshOrderLogs, useOrder } = appOptions

  const { loadOrderDetail } = useOrder()
  const { loadShipperInvoices } = useInvoices(orderId)

  const triggerChargeCorrectionSideEffects = (
    refreshType: ExtraChargeType | null
  ) => {
    // refresh order logs
    refreshOrderLogs()

    // NOTE:
    // reloads order detail since it contains the totalNetCost value.
    // We might get this value from a difference source in order to avoid reloading
    // the whole order object whenever a change is done in the price/cost.
    loadOrderDetail()

    // Refresh remaining total for invoices
    if (refreshType === 'CUSTOMER') {
      loadShipperInvoices()
    }
  }

  return {
    triggerChargeCorrectionSideEffects,
  }
}
