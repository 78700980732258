var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    _vm._b(
      {
        staticClass: "request-missing-docs-modal",
        attrs: {
          show: _vm.show,
          title: _vm.$t("finance/request-missing-document-modal-title"),
          confirmButtonText: _vm.$t(
            "finance/request-missing-document-modal-confirm-label"
          ),
          cancelButtonText: _vm.$t(
            "finance/request-missing-document-modal-cancel-label"
          ),
          inPlace: ""
        },
        on: {
          cancel: _vm.close,
          close: _vm.close,
          confirm: _vm.submitMissingDocumentRequest,
          hidden: _vm.hiddenHandler
        }
      },
      "Dialog",
      _vm.$attrs,
      false
    ),
    [
      _c("p", { staticClass: "subtitle request-missing-docs-modal__text" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("finance/request-missing-document-modal-description")
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "Form",
        { ref: "form", on: { "validated-submit": _vm.handleFormSubmitEvent } },
        [
          _c("DropdownField", {
            staticClass: "missing-document-field",
            attrs: {
              validators: _vm.selectionValidator,
              options: _vm.documentTypes,
              optionLabel: "value",
              optionKey: "key",
              placeholder: _vm.$t(
                "finance/request-missing-document-modal-doc-type-field-placeholder"
              ),
              title: _vm.$t(
                "finance/request-missing-document-modal-doc-type-field-title"
              ),
              "data-test": "document-type-select"
            },
            model: {
              value: _vm.selectedDocumentType,
              callback: function($$v) {
                _vm.selectedDocumentType = $$v
              },
              expression: "selectedDocumentType"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }