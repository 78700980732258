var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "add-extra-charge-modal",
      attrs: {
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        title: _vm.$t("finance/extra-charge-correction-modal-title"),
        confirmButtonText: _vm.$t(
          "finance/extra-charge-correction-modal-confirm-button-label"
        ),
        confirmButtonDisabled: !_vm.canAddSurcharge,
        inPlace: ""
      },
      on: {
        close: _vm.modal.close,
        confirm: _vm.modal.confirm,
        hidden: _vm.resetState
      }
    },
    [
      _c("p", { staticClass: "subtitle add-extra-charge-modal__caption" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("finance/extra-charge-correction-modal-description", {
                type: _vm.$t(_vm.LABELS[_vm.type].amount).toLowerCase()
              })
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("Form", { ref: "form" }, [
        _c("div", { staticClass: "plankton-grid" }, [
          _c(
            "div",
            { staticClass: "plankton-cell", attrs: { span: "6" } },
            [
              _c("PrimitiveDropdownField", {
                attrs: {
                  options: _vm.typeOptions,
                  validators: _vm.chargeTypeValidators,
                  optionKey: "value",
                  optionLabel: "label",
                  fullWidth: "",
                  placeholder: _vm.$t(
                    "finance/extra-charge-correction-modal-adjustment-type-field-placeholder"
                  ),
                  title: _vm.$t(
                    "finance/extra-charge-correction-modal-adjustment-type-field-label",
                    { type: _vm.$t(_vm.LABELS[_vm.type].amount) }
                  ),
                  "data-test": "extra-charge-type"
                },
                model: {
                  value: _vm.adjustment.type,
                  callback: function($$v) {
                    _vm.$set(_vm.adjustment, "type", $$v)
                  },
                  expression: "adjustment.type"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "plankton-cell", attrs: { span: "12" } },
            [
              _c("PrimitiveDropdownField", {
                attrs: {
                  options: _vm.categoryOptions,
                  validators: _vm.chargeCategoryValidators,
                  disabled: !_vm.adjustment.type,
                  optionKey: "value",
                  optionLabel: "label",
                  fullWidth: "",
                  placeholder: _vm.$t(
                    "finance/extra-charge-correction-modal-adjustment-category-field-placeholder"
                  ),
                  title: _vm.$t(
                    "finance/extra-charge-correction-modal-adjustment-category-field-label"
                  ),
                  "data-test": "extra-charge-category"
                },
                model: {
                  value: _vm.adjustment.category,
                  callback: function($$v) {
                    _vm.$set(_vm.adjustment, "category", $$v)
                  },
                  expression: "adjustment.category"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "plankton-cell",
              attrs: { span: "6", "data-test": "extra-charge-amount-parent" }
            },
            [
              _c("NumericInputField", {
                attrs: {
                  allowInteger: 5,
                  allowDecimal: 2,
                  inputValidators: _vm.chargeAmountValidators,
                  allowNegative: "",
                  title:
                    _vm.$t(
                      "finance/extra-charge-correction-modal-amount-field-label"
                    ) + "*",
                  materialIcon: "euro_symbol",
                  placeholder: "0",
                  fullWidth: "",
                  "data-test": "extra-charge-amount"
                },
                model: {
                  value: _vm.adjustment.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.adjustment, "amount", $$v)
                  },
                  expression: "adjustment.amount"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "plankton-grid" }, [
          _c(
            "div",
            {
              staticClass: "plankton-cell",
              attrs: { span: "24", "data-test": "extra-charge-comment-parent" }
            },
            [
              _c("InputField", {
                attrs: {
                  inputValidators: _vm.chargeCommentValidators,
                  title: _vm.$t(
                    "finance/extra-charge-correction-modal-comment-field-label"
                  ),
                  placeholder: _vm.$t(
                    "finance/extra-charge-correction-modal-comment-field-placeholder"
                  ),
                  maxlength: "200",
                  fullWidth: "",
                  "data-test": "extra-charge-comment"
                },
                model: {
                  value: _vm.adjustment.comment,
                  callback: function($$v) {
                    _vm.$set(_vm.adjustment, "comment", $$v)
                  },
                  expression: "adjustment.comment"
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }