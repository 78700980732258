


















































import {computed, defineComponent, ref} from '@vue/composition-api'
import debounce from 'lodash/throttle'
import useClaimBoard from '/@/compositions/useClaimBoard'
import {
  validatorWithValue,
  beforeCurrentDateTimeValidator
} from '/@/field-validators'
import moment from 'moment'
import { InputField, DateRangeInputField } from '@sennder/plankton'
import {appOptions} from '/@/app-options'

type DateRange = { startDate: string; endDate: string }

export default defineComponent({
  components: {
    InputField,
    DateRangeInputField
  },
  setup(props, context) {
    const { filter } = useClaimBoard()
    const idFilterValue = ref('')
    let search = ''
    let date = ''
    const handleIdFilterInput = debounce(
      () => {
        search = idFilterValue.value
        filter({ search, date })
      },
      400,
      {
        trailing: true,
        leading: false
      }
    )
    const dateTimeFormat = 'DD.MM.YYYY'
    const withStartDate = validatorWithValue<DateRange, string>(value => value?.startDate)
    const claimTimeValidators = [
      withStartDate(beforeCurrentDateTimeValidator(dateTimeFormat))
    ]
    const claimTimeForDateRange = ref({
      startDate: null,
      endDate: null
    })
    const handleDateInput = () => {
      date = claimTimeForDateRange.value.startDate
        ? moment(claimTimeForDateRange.value.startDate, dateTimeFormat).format(
            'YYYY-MM-DD'
          )
        : ''
      filter({ search, date })
    }

    const isEnableClaimAccessorial = computed(() => {
      return appOptions.useFeatureFlag().isActive('ENABLE_CLAIM_ACCESSORIAL').value
    })

    const searchInputPlaceHolder = computed(() => {
      return isEnableClaimAccessorial.value ?
          context.root.$t('finance/claims-board-header-search-field-title') :
          context.root.$t('finance/claims-board-header-search-field-placeholder')
    })

    return {
      dateTimeFormat,
      claimTimeForDateRange,
      claimTimeValidators,
      idFilterValue,
      handleIdFilterInput,
      handleDateInput,
      searchInputPlaceHolder,
      showHeader: !appOptions.isMicrofrontend
    }
  }
})
