











import { defineComponent, PropType } from '@vue/composition-api'
import { ClaimStatus } from '/@/services/types'

export default defineComponent({
  props: {
    status: {
      type: String as PropType<ClaimStatus>,
      default: 'SUBMITTED'
    },
    statusDescription: {
      type: String,
      default: 'Submitted'
    }
  }
})
