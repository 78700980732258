var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "claims-col" },
    [
      _c("header", { staticClass: "claims-col__header" }, [
        _c(
          "h3",
          {
            staticClass: "subsection-title claims-col__title",
            attrs: { "data-test": "claims-col-title" }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.title) +
                " (" +
                _vm._s(_vm.claimsData.count) +
                ")\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "transition-group",
        {
          ref: "claimsList",
          staticClass: "claims-col__list",
          class: { "is-loading": _vm.loading },
          attrs: { appear: "", tag: "ul", name: "claims-list" },
          on: {
            "after-leave": function($event) {
              _vm.claimsList.$el.scrollTop = 0
            }
          }
        },
        _vm._l(_vm.claimsData.results, function(item) {
          return _c(
            "li",
            { key: item.id, staticClass: "claims-col__card-wrapper" },
            [
              _c("ClaimsBoardCard", {
                attrs: {
                  claimInfo: item,
                  disabled: _vm.loading,
                  loading: _vm.loading,
                  "data-test": "claim-board-card"
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.showPagination
        ? _c("nav", { staticClass: "claims-col__nav paragraph" }, [
            _vm.claimsData.previousUrl
              ? _c(
                  "button",
                  {
                    staticClass: "claims-col__nav-btn",
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.prevPage }
                  },
                  [_vm._v("\n      <\n    ")]
                )
              : _vm._e(),
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("finance/claims-board-column-pagination", {
                    start: _vm.pagination.startOffset + 1,
                    end: _vm.pagination.endOffset,
                    total: _vm.claimsData.count
                  })
                ) +
                "\n    "
            ),
            _vm.claimsData.nextUrl &&
            _vm.pagination.endOffset < _vm.claimsData.count
              ? _c(
                  "button",
                  {
                    staticClass: "claims-col__nav-btn",
                    attrs: { disabled: _vm.loading },
                    on: { click: _vm.nextPage }
                  },
                  [_vm._v("\n      >\n    ")]
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }