






















































import { defineComponent, toRefs, onBeforeMount, ref } from '@vue/composition-api'
import {
  ClaimReporter,
  ClaimStatus,
  Claim
} from '/@/services/types'
import useClaims from '/@/compositions/useClaims'
import ClaimPanel from './ClaimPanel.vue'
import ClaimApproveModal from './ClaimApproveModal.vue'
import SubmitClaimModal from './SubmitClaimModal.vue'
import EditClaimModal from './EditClaimModal.vue'
import ClaimRejectModal from './ClaimRejectModal.vue'
import ClaimDocumentsModal from './ClaimDocumentsModal.vue'
import NoClaims from './NoClaims.vue'
import { SetClaimStatusPayload } from '/@/services/claim-service'

export default defineComponent({
  components: {
    ClaimPanel,
    ClaimApproveModal,
    SubmitClaimModal,
    EditClaimModal,
    ClaimRejectModal,
    ClaimDocumentsModal,
    NoClaims
  },
  props: {
    orderId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { orderId } = toRefs(props)
    const { loadClaims, claims, updateClaimStatus } = useClaims(orderId)
    const claimApproveModal = ref<InstanceType<typeof ClaimApproveModal>>(null)
    const submitClaimModal = ref<InstanceType<typeof SubmitClaimModal>>(null)
    const editClaimModal = ref<InstanceType<typeof EditClaimModal>>(null)
    const claimRejectModal = ref<InstanceType<typeof ClaimRejectModal>>(null)
    const claimDocumentsModal = ref<InstanceType<typeof ClaimDocumentsModal>>(null)

    onBeforeMount(loadClaims)

    const updateStatus = async (
      claim: Claim,
      { side, status }: { side: ClaimReporter; status: ClaimStatus }
    ) => {
      let payload: SetClaimStatusPayload = null
      if (status === 'APPROVED') {
        payload = await claimApproveModal.value.requestApproval(claim, side)
        if (!payload) return
      }
      if (status === 'REJECTED') {
        payload = await claimRejectModal.value.requestRejection()
        if (!payload) return
      }
      updateClaimStatus(claim.id, side, status, payload)
    }

    const editClaimHandler = ({ claim, side }: { claim: Claim; side: ClaimReporter }) => {
      editClaimModal.value.open(claim, side)
    }

    const fileClaim = (side: ClaimReporter) => {
      submitClaimModal.value.open(side)
    }

    const showClaimDocuments = async (claimId: number, allowEditingFiles: boolean) => {
      const submittedDocuments = await claimDocumentsModal.value.open(
        claimId,
        allowEditingFiles
      )
      if (submittedDocuments) loadClaims()
    }

    return {
      claims,
      updateStatus,
      claimApproveModal,
      claimRejectModal,
      editClaimHandler,
      submitClaimModal,
      editClaimModal,
      claimDocumentsModal,
      fileClaim,
      showClaimDocuments
    }
  }
})
