var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cost-centre-order" },
    [
      _c("div", [
        _c(
          "h2",
          { staticClass: "cost-centre-order__title" },
          [
            _c("Icon", {
              staticClass: "cost-centre-order__icon",
              attrs: { name: "building" }
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("finance/cost-centre-order-title")) +
                  "\n      "
              )
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "cost-centre-order__subtitle" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("finance/cost-centre-order-subtitle")) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("DropdownField", {
        staticClass: "cost-centre-order__dropdown",
        attrs: {
          value: _vm.costCentre,
          options: _vm.costCentreOptions,
          optionKey: "code",
          optionLabel: "label",
          openDirection: "bottom",
          noLabel: "",
          placeholder: _vm.$t("finance/cost-centre-order-placeholder"),
          "data-test": "cost-centre"
        },
        on: { select: _vm.handleCostCentreChange }
      }),
      _vm._v(" "),
      _c(
        "ConfirmationModal",
        {
          ref: "confirmCostCentreChangeModalRef",
          attrs: {
            title: _vm.$t(
              "finance/cost-centre-order-confirm-change-modal-title"
            ),
            confirmButtonText: _vm.$t(
              "finance/cost-centre-order-confirm-change-modal-confirm"
            ),
            cancelButtonText: _vm.$t(
              "finance/cost-centre-order-confirm-change-modal-cancel"
            ),
            "data-test": "confirm-change-modal"
          }
        },
        [
          _c("i18n", {
            attrs: {
              tag: "p",
              path: "finance/cost-centre-order-confirm-change-modal-description"
            },
            scopedSlots: _vm._u([
              {
                key: "idForStaff",
                fn: function() {
                  return [
                    _c("strong", [_vm._v("#" + _vm._s(_vm.orderIdForStaff))])
                  ]
                },
                proxy: true
              },
              {
                key: "costCentre",
                fn: function() {
                  return [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.costCentreChangeDescription))
                    ])
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }