import { captureException } from '@sentry/browser'
import {
  submitPaymentStart,
} from '/@/services/proof-of-delivery-service'
import { computed, ref, Ref } from '@vue/composition-api'
import { PaymentReadyness } from '../services'
import VueI18n from 'vue-i18n'
import {
  fetchOrderCostCentre,
  updateOrderCostCentre,
} from '/@/services/cost-centre-service'
import { appOptions } from '/@/app-options'
import useCostCentre from './useCostCentre'

export default function usePaymentActions({
  orderId,
  $t,
}: {
  orderId: Ref<number>
  $t: VueI18n['t']
}) {
  const { order } = appOptions.useOrder()
  const { suggestOrderCostCentre } = useCostCentre()
  const paymentReadinessDetails = ref<PaymentReadyness>({
    paymentCanBeStarted: false,
    billingDetailsAreFilled: false,
    documentsAreReady: false,
  })

  const isStartPaymentBtnDisabled = computed(() => {
    return !paymentReadinessDetails.value.paymentCanBeStarted
  })

  const paymentRequirements = computed(() => {
    const fillBillingInfoRequirementLabel = $t(
      'finance/use-payment-actions-requirement-fill-billing-info'
    ) as string
    const validateDocRequirementLabel = $t(
      'finance/use-payment-actions-requirement-validate-docs'
    ) as string
    return {
      [fillBillingInfoRequirementLabel]:
        paymentReadinessDetails.value.billingDetailsAreFilled,
      [validateDocRequirementLabel]:
        paymentReadinessDetails.value.documentsAreReady,
    }
  })

  const isStartPaymentBtnHovered = ref(false)

  const isPopoverVisible = computed(() => {
    return isStartPaymentBtnHovered.value && isStartPaymentBtnDisabled.value
  })

  const hoverStartPaymentBtn = () => {
    isStartPaymentBtnHovered.value = true
  }
  const unhoverStartPaymentBtn = () => {
    isStartPaymentBtnHovered.value = false
  }

  const startPayment = async () => {
    try {
      if (appOptions.costCentreEnabled) {
        await assignOrderCostCentreIfMissing(
          orderId.value,
          order.value.carrierCompany.id,
          order.value.partnerManagerId
        )
      }
    } catch (error) {
      captureException(error)
    } finally {
      await submitPaymentStart(orderId.value)
    }
  }

  const assignOrderCostCentreIfMissing = async (
    orderId: number,
    carrierId: number,
    partnerManagerId: number
  ) => {
    const costCentre = await fetchOrderCostCentre(orderId)
    if (costCentre) return

    const suggestedCostCentre = await suggestOrderCostCentre(
      orderId,
      carrierId,
      partnerManagerId
    )
    if (suggestedCostCentre) {
      await updateOrderCostCentre(orderId, suggestedCostCentre.code)
    }
  }

  return {
    paymentReadinessDetails,
    isStartPaymentBtnDisabled,
    paymentRequirements,
    isPopoverVisible,
    isStartPaymentBtnHovered,
    hoverStartPaymentBtn,
    unhoverStartPaymentBtn,
    startPayment,
  }
}
