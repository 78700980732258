import orderBy from 'lodash/orderBy'
import { ref, Ref } from '@vue/composition-api'
import {
  fetchAllProofOfDeliveryDocumentsByOrderId,
  ProofOfDelivery,
  submitMissingDocumentRequest,
} from '/@/services/proof-of-delivery-service'

export type DocumentTypeKey = 'PALLET_EXCHANGE_NOTE' | 'CMR' | 'OTHER'
export interface DocumentType {
  key: DocumentTypeKey
  value: string
}

/**
 * We keep a ref with PODs in the parent scope
 * so it would be shared between all imports of
 * the composition
 * This way we can watch for its updates
 */
const proofOfDeliveries: Ref<Array<ProofOfDelivery>> = ref([])

export default function useProofOfDeliveries (orderId: Ref<number>) {

  const fetchProofOfDeliveries = async () => {
    const pods = await fetchAllProofOfDeliveryDocumentsByOrderId(orderId.value)
    proofOfDeliveries.value = orderBy(pods, ['created'], ['desc'])
  }
  
  const requestMissingDocument = async ({
    orderId,
    documentType
  }: {
    orderId: number
    documentType: DocumentTypeKey
  }) => {
    await submitMissingDocumentRequest(orderId, documentType)
  }

  return {
    proofOfDeliveries,
    requestMissingDocument,
    fetchProofOfDeliveries
  }
}
