var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar-card" }, [
    _c(
      "h3",
      { staticClass: "subsection-title sidebar-card__title" },
      [_vm._t("title")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "sidebar-card__content",
        attrs: { "data-test": "sidebar-card__content" }
      },
      [_vm._t("content")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }