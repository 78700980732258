const DEFAULT_MODEL_OPTION = { prop: 'value', event: 'input' }

/**
 * Composition function that creates an event emitter to communicate v-model updates.
 * @param {Props} props,
 * @param {SetupContext} context,
 * @param {{prop: string, event: string}} modelOptions
 * @returns {Function}
 */
export default (props, context, modelOptions = DEFAULT_MODEL_OPTION) => {
  return (fieldName, newFieldValue) => {
    const model = props[modelOptions.prop]
    if (model[fieldName] !== newFieldValue) {
      context.emit(modelOptions.event, { ...model, [fieldName]: newFieldValue })
    }
  }
}
