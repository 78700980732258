






















import { defineComponent, PropType } from '@vue/composition-api'
import BillingInfo from './BillingInfo.vue'
import PODReviewWidget from '/@/components/microfrontends/pod-review-widget/PODReviewWidget.vue'
import PaymentMessage from './PaymentMessage.vue'
import { OrderPaymentInformation } from '/@/services/types'

export default defineComponent({
  components: { BillingInfo, PaymentMessage, PODReviewWidget },
  props: {
    orderId: { type: Number, required: true },
    carrierId: { type: Number, required: true },
    editable: { type: Boolean, default: false },
    paymentInfo: { type: Object as PropType<OrderPaymentInformation>, required: true }
  }
})
