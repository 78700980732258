






















































import { defineComponent, PropType, toRef } from '@vue/composition-api'
import { FinanceOrder } from '/@/app-options'
import CarrierBillingInfo from './info/CarrierBillingInfo.vue'
import CarrierContactInfo from './info/CarrierContactInfo.vue'
import CarrierVehicleInfo from './info/CarrierVehicleInfo.vue'
import RouteInfo from './info/RouteInfo.vue'
import LoadInfo from './info/LoadInfo.vue'

const COMPONENTS_ORDER = {
  AP: ['CarrierContactInfo', 'RouteInfo', 'CarrierVehicleInfo', 'CarrierBillingInfo'],
  AR: ['CarrierContactInfo', 'RouteInfo', 'LoadInfo', 'CarrierVehicleInfo']
}
export default defineComponent({
  components: {
    CarrierBillingInfo,
    CarrierContactInfo,
    CarrierVehicleInfo,
    RouteInfo,
    LoadInfo
  },
  props: {
    order: { type: Object as PropType<FinanceOrder>, required: true },
    stops: { type: Array, required: true },
    view: {
      type: String as PropType<'AR' | 'AP'>,
      default: 'AP'
    }
  },
  setup(props) {
    const view = toRef(props, 'view')

    const getComponentOrder = (targetComponentName: string) =>
      COMPONENTS_ORDER[view.value].findIndex(
        componentName => componentName === targetComponentName
      )

    return {
      getComponentOrder
    }
  }
})
