
























import { defineComponent } from '@vue/composition-api'
import ClaimsBoardHeader from './ClaimsBoardHeader.vue'
import ClaimsColumn from './ClaimsBoardColumn.vue'

export default defineComponent({
  components: {
    ClaimsBoardHeader,
    ClaimsColumn
  }
})
