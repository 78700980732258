
























































































import {
  defineComponent,
  computed,
  ref,
  watch,
  toRefs,
  WatchStopHandle
} from '@vue/composition-api'
import useModal from '/@/compositions/useModal'
import useStateReset from '../../compositions/useStateReset'
import {
  ClaimFileUpload,
  default as useClaims,
  SubmitClaim
} from '/@/compositions/useClaims'
import { useWizardController, WizardStep } from '/@/compositions/useWizardController'

import { ClaimReporter } from '/@/services/types'
import { CLAIM_GUIDELINES_LINK } from '/@/constants'

import WizardModal from '/@/components/shared/wizard-modal/WizardModal.vue'
import WizardModalStep from '/@/components/shared/wizard-modal/WizardModalStep.vue'
import ClaimDetailsForm from './submit-claim-forms/ClaimDetailsForm.vue'
import ClaimSurchargeForm from './submit-claim-forms/ClaimSurchargeForm.vue'
import ClaimsDocumentsForm from './submit-claim-forms/ClaimDocumentsForm.vue'
import { appOptions } from '../../app-options'


export default defineComponent({
  components: {
    WizardModal,
    WizardModalStep,
    ClaimDetailsForm,
    ClaimSurchargeForm,
    ClaimsDocumentsForm,
    RenderWithLaunchDarkly: () => Promise.resolve(appOptions.components.RenderWithLaunchDarkly)
  },
  props: {
    orderId: { type: Number, required: true }
  },
  setup(props) {
    const { orderId } = toRefs(props)
    const { createClaim, submitClaim } = useClaims(orderId)
    const claim = ref(createClaim())
    const claimFiles = ref<ClaimFileUpload[]>([])
    const currentSide = ref<ClaimReporter>(null)
    let stopReporterWatcherHandler: WatchStopHandle = null
    let stopSubtypeWatcherHandler: WatchStopHandle = null

    const modal = useModal({
      /**
       * It starts watching user's changes in the claim reporter field just before opening the modal.
       * Otherwise, when editing a claim, it would trigger the watcher
       * after assigning the editing claim object to the claim ref in this component.
       */
      onBeforeOpen: () => {
        startReporterWatcher()
        startSubtypeWatcher()
      },
      onBeforeClose: () => {
        stopReporterWatcher()
        stopSubtypeWatcher()
      },
      onConfirm: () => submitClaim(claim.value, claimFiles.value)
    })

    const { activeStep, handleNext, handleBack, wizardStepForms } = useWizardController({
      onLastStep: () => modal.confirm()
    })

    const resetState = useStateReset([claim, activeStep, claimFiles])

    const open = (side: ClaimReporter = null) => {
      claim.value = createClaim(side)
      currentSide.value = side === null ? claim.value.reporter : side
      modal.open()
    }

    const startReporterWatcher = () => {
      stopReporterWatcher()
      stopReporterWatcherHandler = watch(() => claim.value.reporter, handleReporterChange)
    }
    const stopReporterWatcher = () => {
      if (stopReporterWatcherHandler !== null) {
        stopReporterWatcherHandler()
      }
    }

    const startSubtypeWatcher = () => {
      stopSubtypeWatcher()
      stopSubtypeWatcherHandler = watch(
        () => claim.value.claimType,
        resetSubTypeOnTypeChange
      )
    }

    const stopSubtypeWatcher = () => {
      if (stopSubtypeWatcherHandler !== null) {
        stopSubtypeWatcherHandler()
      }
    }

    const handleReporterChange = (newValue: ClaimReporter, oldValue: ClaimReporter) => {
      if (newValue === oldValue) return
      // reset all fields which values depends on the claim reporter.
      setClaimFields({
        claimType: null,
        claimSubtype: null,
        stopoverId: null,
        requestedAmount: null,
        description: null,
        reporter: newValue
      })
      currentSide.value = newValue
    }

    const resetSubTypeOnTypeChange = (newClaimType: string, oldClaimType: string) => {
      if (newClaimType !== oldClaimType && oldClaimType !== null) {
        setClaimFields({ claimSubtype: null, stopoverId: null })
      }
    }

    const handleFileLoading = (isLoading: boolean) => {
      modal.loading.value = isLoading
    }

    const setClaimFields = (update: Partial<SubmitClaim>) => {
      claim.value = { ...claim.value, ...update }
    }

    // TODO ADD THE VALIDATION AFTER IMPLEMENT NEW CLAIM LOGIC
    // const isConfirmButtonDisabled = computed(() => {
    //   if (activeStep.value !== WizardStep.SurchargeAndDocuments) {
    //     const { reporter, faultyParty, claimType, incidentTime } = claim.value;
    //     // TODO: REMOVE incidentTime WHEN TYPE=CANCELLATION
    //     // TODO: claimSubtype isn't required if claim type=other
    //     return !(reporter && faultyParty && claimType && incidentTime )
    //   }
    //   else {
    //     const { requestedAmount, description, files } = claim.value;
    //     return !(requestedAmount && description && files.length )
    //   }
    // })
    const isConfirmButtonDisabled = computed(() => {
      if (activeStep.value !== WizardStep.SurchargeAndDocuments) return modal.loading.value
      return claimFiles.value?.length === 0
    })

    return {
      claim,
      claimFiles,
      modal,
      resetState,
      activeStep,
      handleNext,
      handleBack,
      handleFileLoading,
      claimDetailsForm: wizardStepForms[WizardStep.ClaimDetails],
      claimSurchargeAndDocumentsForm: wizardStepForms[WizardStep.SurchargeAndDocuments],
      open,
      currentSide,
      isConfirmButtonDisabled,
      guidanceLink: CLAIM_GUIDELINES_LINK
    }
  }
})
