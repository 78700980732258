




























import { defineComponent, onMounted, ref  } from '@vue/composition-api'
import NoCostCentrePlaceholder from './NoCostCentrePlaceholder.vue'
import CostCentreLoad from './CostCentreLoad.vue'
import CostCentreList from './CostCentreList.vue'
import { CostCentre } from '/@/services'
import CostCentreFAQModal from './CostCentreFAQModal.vue'
import { uploadCostCentre, fetchCostCentres, fetchCostCentresCSV } from '/@/services/cost-centre-service'

export default defineComponent({
  components: { 
    NoCostCentrePlaceholder,
    CostCentreList,
    CostCentreLoad,
    CostCentreFAQModal
  },
  setup() {
    const costCentres = ref<CostCentre[]>([])
    const costCentreLoadRef = ref<InstanceType<typeof CostCentreLoad>>()
    const loaded = ref(false)
    const costCentreFAQModalRef = ref<InstanceType<typeof CostCentreFAQModal>>()

    const uploadCostCentres = async () => {
      const file: File = await costCentreLoadRef.value.requestFile()
      await uploadCostCentre(file)
      loadCostCentres()
    }

    const downloadCostCentreCSV = async () => {
      const csvContent = await fetchCostCentresCSV()
      costCentreLoadRef.value.download(csvContent)
    }

    const loadCostCentres = async () => {
      costCentres.value = await fetchCostCentres()
      loaded.value = true
    }

    const showFaqModal = () => costCentreFAQModalRef.value.modal.open()

    onMounted(async () => loadCostCentres())
    
    return {
      loaded,
      costCentres,
      costCentreLoadRef,
      costCentreFAQModalRef,
      uploadCostCentres,
      downloadCostCentreCSV,
      showFaqModal
    }
  }
}) 
