











































































































import { defineComponent, toRef, computed, Ref, ref } from '@vue/composition-api'
import { formatMoneyWithZeroForNull } from '/@/filters/filters'
import { PropType } from '@vue/composition-api'
import { ParsedInvoice } from './types'
import ConfirmationModal from '/@/components/shared/ConfirmationModal.vue'
import Vue from 'vue'
import { Icon } from '@sennder/plankton'

export const truncate = (value: string, length: number, suffix: string) =>
  value != null
    ? value.slice(0, length) + (length < value.length ? suffix || '...' : '')
    : ''

export default defineComponent({
  components: {
    ConfirmationModal,
    Icon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    invoices: {
      type: Array as PropType<ParsedInvoice[]>,
      required: true
    },
    computedAmountToInvoice: {
      type: Number,
      default: 0
    },
    allowCancelation: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const computedAmountToInvoice = toRef(props, 'computedAmountToInvoice')
    const invoiceToRemove: Ref<ParsedInvoice> = ref(null)
    const confirmRemovalModal = ref(null)

    const totalNetValue = computed(() => {
      return formatMoneyWithZeroForNull(computedAmountToInvoice.value)
    })

    const isTotalNetValueNegative = computed(() => {
      return computedAmountToInvoice.value < 0
    })

    const confirmRemoval = async (invoice: ParsedInvoice) => {
      invoiceToRemove.value = invoice
      // wait until the modal is rendered
      // because we use invoiceToRemove's properties in the template
      // and it is null until next tick
      await Vue.nextTick()
      const result: boolean = await confirmRemovalModal.value.askConfirmation()
      if (result) {
        context.emit('remove-invoice', invoice)
      }
    }

    const truncatedInvoiceNumber = computed(() => {
      return truncate(invoiceToRemove.value?.number, 30, '...')
    })

    const getFileName = (filePath: string) => {
      return filePath ? filePath.replace(/^[\w-]+\//, '') : null
    }

    return {
      totalNetValue,
      isTotalNetValueNegative,
      confirmRemoval,
      invoiceToRemove,
      confirmRemovalModal,
      truncatedInvoiceNumber,
      getFileName,
    }
  }
})
