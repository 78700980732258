var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "claim-approve-modal",
      attrs: {
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        title: _vm.$t("finance/claim-approve-modal-title"),
        confirmButtonText: _vm.$t("finance/claim-approve-modal-confirm-label"),
        cancelButtonText: _vm.$t("finance/claim-approve-modal-cancel-label"),
        "data-test": "claim-approve-modal",
        inPlace: ""
      },
      on: {
        cancel: _vm.modal.close,
        close: _vm.modal.close,
        confirm: _vm.modal.confirm,
        hidden: _vm.resetState
      }
    },
    [
      _c("p", { staticClass: "subtitle claim-approve-modal__text" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("finance/claim-approve-modal-cancel-description")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "Form",
        { ref: "form", attrs: { "data-test": "claim-approve-form" } },
        [
          _c(
            "div",
            { staticClass: "plankton-grid", attrs: { columns: "12" } },
            [
              _c(
                "div",
                { staticClass: "plankton-cell", attrs: { span: "9" } },
                [
                  _c("PrimitiveDropdownField", {
                    attrs: {
                      value: _vm.claimTypeOption
                        ? _vm.claimTypeOption.code
                        : null,
                      options: _vm.claimTypeOptions,
                      optionKey: "code",
                      optionLabel: "description",
                      disabled: "",
                      fullWidth: "",
                      title: _vm.$t(
                        "finance/claim-approve-modal-type-field-title"
                      ),
                      "data-test": "claim-type"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "plankton-cell", attrs: { span: "3" } },
                [
                  _c("NumericInputField", {
                    attrs: {
                      allowInteger: 10,
                      allowDecimal: 2,
                      inputValidators: _vm.amountValidators,
                      allowNegative: "",
                      title: _vm.$t(
                        "finance/claim-approve-modal-amount-field-title"
                      ),
                      materialIcon: "euro_symbol",
                      placeholder: "0",
                      fullWidth: "",
                      "data-test": "claim-amount"
                    },
                    model: {
                      value: _vm.amountModel,
                      callback: function($$v) {
                        _vm.amountModel = $$v
                      },
                      expression: "amountModel"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("LimitedTextAreaField", {
            attrs: {
              title: _vm.$t("finance/claim-approve-modal-notes-field-title"),
              fullWidth: "",
              maxLength: 150,
              convertEmptyToNull: "",
              "data-test": "approval-notes"
            },
            model: {
              value: _vm.approvalNote,
              callback: function($$v) {
                _vm.approvalNote = $$v
              },
              expression: "approvalNote"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }