


































import {
  defineComponent,
  toRef,
  PropType,
  computed,
} from '@vue/composition-api'
import CarrierInvoices from './invoices/CarrierInvoices.vue'
import DocumentManagementSection from './document-management/DocumentManagementSection.vue'
import CostCentreOrder from './cost-centre/CostCentreOrder.vue'
import useOrderPaymentStatus from '/@/compositions/useOrderPaymentStatus'
import Claims from '/@/components/claims/Claims.vue'
import { FinanceOrder, appOptions } from '/@/app-options'
import OctopusPaymentsWidgetPortal from './microfrontends/OctopusPaymentsWidgetPortal.vue'

export default defineComponent({
  components: {
    DocumentManagementSection,
    CarrierInvoices,
    Claims,
    CostCentreOrder,
    OctopusPaymentsWidgetPortal,
  },
  props: {
    order: { type: Object as PropType<FinanceOrder>, required: true },
    compact: { type: Boolean, default: false },
  },
  setup(props) {
    const { paymentInfo, fetchPaymentInformation } = useOrderPaymentStatus(
      toRef(props.order, 'id')
    )

    fetchPaymentInformation()

    const showLegacyCarrierInvoices = computed(() => {
      return !appOptions.useFeatureFlag().isActive('ENABLE_CARRIER_INVOICES_WIDGET').value
    })

    const isEnableClaimAccessorial = computed(() => {
      return appOptions.useFeatureFlag().isActive('ENABLE_CLAIM_ACCESSORIAL').value
    })

    return {
      isEnableClaimAccessorial,
      paymentInfo,
      showLegacyCarrierInvoices,
      costCentreEnabled: appOptions.costCentreEnabled,
    }
  },
})
