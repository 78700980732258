








import { defineComponent, onMounted, onUpdated } from '@vue/composition-api'
import { injectSetTitle } from './WizardModalProvideInject'
export default defineComponent({
  name: 'WizardModalStep',
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true }
  },
  setup(props) {
    const setTitle = injectSetTitle()
    onMounted(() => setTitle(props.title))
    onUpdated(() => setTitle(props.title))
  }
})
