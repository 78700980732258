



































import SalesforceLink from '/@/components/SalesforceLink.vue'
import { defineComponent, toRef, computed, ref, onMounted, watchEffect } from '@vue/composition-api'
import PaymentMessagePortal from '/@/components/microfrontends/PaymentMessagePortal.vue';
import useOrderPaymentStatus from '/@/compositions/useOrderPaymentStatus'
import useCarrierBilling from '/@/compositions/useCarrierBilling'
import fetchDataOnMounted from '../../compositions/useFetchDataOnMounted'
import { AlertMessage } from '@sennder/plankton'
import useResizeObserver from '/@/compositions/useResizeObserver'

import PODReviewWidget from '/@/components/microfrontends/pod-review-widget/PODReviewWidget.vue'

export default defineComponent({
  components: {
    PaymentMessagePortal,
    SalesforceLink,
    AlertMessage,
    PODReviewWidget
  },
  props: {
    orderId: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    carrierId: {
      type: Number,
      default: null
    }
  },
  setup(props) {
    const orderId = toRef(props, 'orderId')

    const { paymentInfo, fetchPaymentInformation } = useOrderPaymentStatus(orderId)

    const loading = fetchDataOnMounted([fetchPaymentInformation])

    const carrierId = toRef(props, 'carrierId')
    const { billingAddress, billingEmail, billingInfo } = useCarrierBilling({
      carrierId
    })

    const missingBillingInfo = computed(() => {
      const missed = []
      if (billingAddress.value === '-') missed.push('billing address')
      if (billingEmail.value === '-') missed.push('billing email')
      const ibanBic = []
      if (!billingInfo.value?.iban) ibanBic.push('IBAN')
      if (!billingInfo.value?.bic) ibanBic.push('BIC')
      if (ibanBic.length > 0) missed.push(ibanBic.join('/'))
      return missed
    })

    const missingBillingInfoMessage = computed(() => {
      const missed = missingBillingInfo.value
      if (missed.length === 0) return null
      if (missed.length === 1) return missed[0]
      return missed.slice(0, missed.length - 1).join(', ') + ' and ' + missed.slice(-1)
    })

    const wrapperRef = ref()
    const isCompact = ref(false)
    const { resizeState } = useResizeObserver(wrapperRef)
    onMounted(() => {
      watchEffect(() => {
        const { width } = resizeState.contentRect
        isCompact.value = (width < 540)
      })
    })

    return {
      paymentInfo,
      loading,
      missingBillingInfo,
      missingBillingInfoMessage,
      billingInfo,
      isCompact,
      wrapperRef
    }
  }
})
