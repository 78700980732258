import { render, staticRenderFns } from "./ClaimPanel.vue?vue&type=template&id=34d0ccc0&scoped=true&"
import script from "./ClaimPanel.vue?vue&type=script&lang=ts&"
export * from "./ClaimPanel.vue?vue&type=script&lang=ts&"
import style0 from "./ClaimPanel.vue?vue&type=style&index=0&id=34d0ccc0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d0ccc0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/sennder/operations-finance/finance/finance-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34d0ccc0')) {
      api.createRecord('34d0ccc0', component.options)
    } else {
      api.reload('34d0ccc0', component.options)
    }
    module.hot.accept("./ClaimPanel.vue?vue&type=template&id=34d0ccc0&scoped=true&", function () {
      api.rerender('34d0ccc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/claims/ClaimPanel.vue"
export default component.exports