var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cost-centre-list" }, [
    _c(
      "div",
      { staticClass: "cost-centre-list__toolbar" },
      [
        _c("Button", {
          staticClass: "cost-centre-list__button",
          attrs: {
            text: _vm.$t("finance/const-centre-list-download-button"),
            category: "tertiary",
            materialIcon: "cloud_download"
          },
          on: {
            click: function($event) {
              return _vm.$emit("download")
            }
          }
        }),
        _vm._v(" "),
        _c("Button", {
          staticClass: "cost-centre-list__button",
          attrs: {
            text: _vm.$t("finance/const-centre-list-upload-button"),
            category: "secondary",
            materialIcon: "cloud_upload"
          },
          on: {
            click: function($event) {
              return _vm.$emit("upload")
            }
          }
        }),
        _vm._v(" "),
        _c("Button", {
          staticClass: "cost-centre-list__button",
          attrs: {
            text: _vm.$t("finance/const-centre-list-faq-button"),
            category: "tertiary",
            materialIcon: "info"
          },
          on: {
            click: function($event) {
              return _vm.$emit("showFAQ")
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "cost-centre-list__list" }, [
      _c("table", [
        _c("thead", [
          _c("th", { staticClass: "cost-centre-list__header" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.$t("finance/const-centre-list-header-code")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "cost-centre-list__header" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t("finance/const-centre-list-header-short-description")
                ) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "cost-centre-list__header" }, [
            _vm._v(
              "\n          " +
                _vm._s(
                  _vm.$t("finance/const-centre-list-header-long-description")
                ) +
                "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.costCentres, function(cc) {
              return [
                _c("tr", {
                  key: "spacer-" + cc.code,
                  staticClass: "cost-centre-list__row--spacer"
                }),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    key: cc.code,
                    staticClass: "cost-centre-list__row",
                    attrs: { "data-test": "cost-centre-list-item" }
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "cost-centre-list__cell",
                        attrs: { "data-test": "cost-centre-code" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(cc.code) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "cost-centre-list__cell",
                        attrs: { "data-test": "cost-centre-short-description" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(cc.shortDescription) +
                            "\n            "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "cost-centre-list__cell",
                        attrs: { "data-test": "cost-centre-long-description" }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(cc.longDescription) +
                            "\n            "
                        )
                      ]
                    )
                  ]
                )
              ]
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }