




























































import {
  defineComponent,
  computed,
  PropType,
  toRef,
  Ref,
} from '@vue/composition-api'
import { formatMoneyWithZeroForNull } from '/@/filters/filters'
import {
  ClaimStatus,
  ClaimTransition
} from '/@/services/types'
import ClaimStatusView from './ClaimStatusView.vue'
import { Button } from '@sennder/plankton'
import { appOptions } from '/@/app-options'

export default defineComponent({
  components: {
    Button,
    ClaimStatusView
  },
  props: {
    title: {
      type: String,
      default: 'Shipper surcharge'
    },
    status: {
      type: String as PropType<ClaimStatus>,
      default: 'SUBMITTED'
    },
    statusDescription: {
      type: String,
      default: 'Submitted'
    },
    description: {
      type: String,
      default: ''
    },
    mainAction: {
      type: String,
      default: null
    },
    amount: {
      type: Number,
      default: 0
    },
    finalAmount: {
      type: Number,
      default: 0
    },
    notes: {
      type: String,
      default: null
    },
    editable: {
      type: Boolean,
      default: false
    },
    statusTransitions: {
      type: Array as PropType<ClaimTransition[]>,
      default: () => [] as ClaimTransition[]
    }
  },
  setup(props) {
    const { currentUserIsClaimAccessorialManager } = appOptions.useCurrentUser()
    const status = toRef(props, 'status') as Ref<ClaimStatus>
    const canUpdateStatus = computed<boolean>(() => {
      return props.editable && currentUserIsClaimAccessorialManager.value
    })
    const statusDescriptionWithAmount = computed(() => {
      let text = props.statusDescription
      if (props.finalAmount && status.value === 'APPROVED') {
        text += ` (${formatMoneyWithZeroForNull(props.finalAmount)})`
      }
      return text
    })

    return {
      formatMoneyWithZeroForNull,
      statusDescriptionWithAmount,
      canUpdateStatus
    }
  }
})
