var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { display: "none" } }, [
    _c("input", {
      ref: "inputFileRef",
      attrs: { type: "file", accept: "text/csv" },
      on: { change: _vm.handleUploadedFile }
    }),
    _vm._v(" "),
    _c("a", {
      ref: "downloadLinkRef",
      attrs: {
        href:
          "data:text/csv;charset=utf-8," + encodeURIComponent(_vm.csvContent),
        download: "cost-centres.csv"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }