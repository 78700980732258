import { ICurrencyExchangeRate } from '../app-options'
import { Currency } from '../constants'
import { fetchExchangeRate } from '../services/currency-service'

const exchangeRates: {
  [key in Currency]?: ICurrencyExchangeRate
} = {}

export default function useCurrency() {
  const updateExchangeRate = async (currency: Currency) => {
    const rate = await fetchExchangeRate(currency)

    exchangeRates[currency] = rate
  }

  return { exchangeRates, updateExchangeRate }
}
