var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper order-header" }, [
    _c(
      "div",
      { staticClass: "header-wrapper" },
      [
        _c(
          "h4",
          { staticClass: "subsection-title" },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t("finance/finance-order-header-title", {
                    id: _vm.order.idForStaff
                  })
                ) +
                "\n      >\n      "
            ),
            _c(
              "SalesforceLink",
              { attrs: { companyId: _vm.order.customerId } },
              [
                _c("span", { attrs: { "data-test": "customer-name" } }, [
                  _vm._v(_vm._s(_vm.order.customerName))
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("OrderStateChip", {
          staticClass: "chip",
          attrs: {
            isCancelled: _vm.order.isCancelled,
            state: _vm.order.state,
            "data-test": "ordering-state"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "order-header__actions",
            attrs: { "data-test": "order-header-actions" }
          },
          [
            _c("Button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.bottom",
                  value: _vm.$t(
                    "finance/finance-order-header-comment-button-label"
                  ),
                  expression:
                    "\n          $t('finance/finance-order-header-comment-button-label')\n        ",
                  modifiers: { bottom: true }
                }
              ],
              attrs: {
                "data-test": "comments-and-activity",
                category: "tertiary",
                inline: "",
                icon: "comments"
              },
              on: { click: _vm.toggleSidebar }
            }),
            _vm._v(" "),
            _vm.currentUserIsOps
              ? _c("Button", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip.bottom",
                      value: _vm.$t(
                        "finance/finance-order-header-detail-button-label"
                      ),
                      expression:
                        "\n          $t('finance/finance-order-header-detail-button-label')\n        ",
                      modifiers: { bottom: true }
                    }
                  ],
                  attrs: {
                    icon: "fileText",
                    inline: "",
                    category: "tertiary",
                    "data-test": "goto-order-detail"
                  },
                  on: { click: _vm.goToOrderDetailView }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("Button", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip.bottom",
                  value: _vm.changeFinanceViewLabel,
                  expression: "changeFinanceViewLabel",
                  modifiers: { bottom: true }
                }
              ],
              attrs: {
                icon: "switch",
                inline: "",
                category: "tertiary",
                "data-test": "change-finance-view"
              },
              on: { click: _vm.changeFinanceView }
            }),
            _vm._v(" "),
            _vm.canCancelCurrentOrder && _vm.order.state !== "NEW"
              ? _c("CancelOrderButton", {
                  attrs: {
                    orderId: _vm.order.id,
                    "data-test": "order-cancellation-incident"
                  },
                  on: { submit: _vm.reportCancellationIncident }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "order-header__info" },
      [
        _c("LabeledText", {
          staticClass: "order-header__info-el",
          attrs: {
            id: "contactPerson",
            label: _vm.$t("finance/finance-order-header-contact-person-label"),
            text: _vm.get(_vm.order, "customerContact.name"),
            "data-test": "customer-contact"
          }
        }),
        _vm._v(" "),
        _c("LabeledText", {
          staticClass: "order-header__info-el",
          attrs: {
            id: "contactEmail",
            label: _vm.$t("finance/finance-order-header-contact-email-label"),
            text: _vm.get(_vm.order, "customerContact.email")
          }
        }),
        _vm._v(" "),
        _c("LabeledText", {
          staticClass: "order-header__info-el",
          attrs: {
            id: "contactPhone",
            label: _vm.$t("finance/finance-order-header-contact-phone-label"),
            text: _vm.get(_vm.order, "customerContact.phone")
          }
        }),
        _vm._v(" "),
        _c("LabeledText", {
          staticClass: "order-header__info-el",
          attrs: {
            id: "referenceNumber",
            label: _vm.$t("finance/finance-order-header-ref-label"),
            text: _vm.order.referenceNumber,
            "data-test": "ordering-reference-number"
          }
        }),
        _vm._v(" "),
        _c("LabeledText", {
          staticClass: "order-header__info-el",
          attrs: {
            label: _vm.$t("finance/finance-order-header-net-price-label"),
            text: _vm.formatMoneyWithDashForNull(
              _vm.get(_vm.order, "totalNetPrice")
            ),
            "data-test": "net-price"
          }
        }),
        _vm._v(" "),
        _c("LabeledText", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.top",
              value: _vm.isCostConverted
                ? _vm.costInCarrierCurrency
                  ? _vm.$t(
                      "ordering-share-header/order-information-header-converted-cost-tooltip-with-converted-currency",
                      {
                        CONVERTED_COST: _vm.costInCarrierCurrency
                      }
                    )
                  : _vm.$t(
                      "ordering-share-header/order-information-header-converted-cost-tooltip",
                      {
                        CURRENCY: _vm.order.carrierPaymentCurrency
                      }
                    )
                : null,
              expression:
                "\n        isCostConverted\n          ? costInCarrierCurrency\n            ? $t(\n              'ordering-share-header/order-information-header-converted-cost-tooltip-with-converted-currency',\n              {\n                CONVERTED_COST: costInCarrierCurrency,\n              }\n            )\n            : $t(\n              'ordering-share-header/order-information-header-converted-cost-tooltip',\n              {\n                CURRENCY: order.carrierPaymentCurrency,\n              }\n            )\n          : null\n      ",
              modifiers: { top: true }
            }
          ],
          class: {
            "order-header__info-el": true,
            "highlighted-cost": _vm.isCostConverted
          },
          attrs: {
            label: _vm.$t("finance/finance-order-header-net-cost-label"),
            text: _vm.formatMoneyWithDashForNull(_vm.order.totalNetCost),
            "data-test": "net-cost"
          }
        }),
        _vm._v(" "),
        _c("LabeledMargin", {
          staticClass: "order-header__info-el",
          attrs: {
            label: _vm.$t("finance/finance-order-header-margin-label"),
            marginPercentage: _vm.order.marginPercentage,
            "data-test": "margin"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }