import { ICurrencyExchangeRate } from '../app-options'
import { Currency } from '../constants'
import { exchangeServiceApi } from './api'

export const fetchExchangeRate = async (
  currency: Currency
): Promise<ICurrencyExchangeRate> => {
  const response = await exchangeServiceApi.get<ICurrencyExchangeRate>(`/${currency}`)

  return response.data
}
