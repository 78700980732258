import { camelCase, snakeCase, isPlainObject, isArray } from 'lodash-es'
import { CamelCase, SnakeCase } from 'type-fest'

export type SnakeCasedObject<T> = {
  [K in keyof T as SnakeCase<K>]: T[K]
}

export type CamelCasedObject<T> = {
  [K in keyof T as CamelCase<K>]: T[K]
}

type AnyObject = Record<string, unknown>

type TransformationFunction = (arg: string) => string

interface Options {
  includeStringValues: boolean
}

/** Converts the keys of an object to snake case */
export const transformKeysToSnakeCase = <T>(
  camelCaseObject: unknown,
  { includeStringValues = false }: Options = { includeStringValues: false }
): T => transformCasing(camelCaseObject, snakeCase, { includeStringValues }) as T

/** Converts the keys of an object to camel case */
export const transformKeysToCamelCase = <T>(
  snake_case_object: unknown,
  { includeStringValues = false }: Options = { includeStringValues: false }
): T => transformCasing(snake_case_object, camelCase, { includeStringValues }) as T

const transformCasing = (
  value: unknown,
  transformationFn: TransformationFunction,
  options: Options
): unknown => {
  if (isPlainObject(value))
    return transformCasingInObject(value as AnyObject, transformationFn, options)
  else if (isArray(value)) return transformCasingInArray(value, transformationFn, options)
  else if (options.includeStringValues && typeof value === 'string')
    return transformationFn(value)
  return value
}

const transformCasingInObject = (
  obj: AnyObject,
  transformationFn: TransformationFunction,
  options: Options
) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    result[transformationFn(key)] = transformCasing(value, transformationFn, options)
    return result
  }, {} as AnyObject)
}

const transformCasingInArray = (
  array: unknown[],
  transformationFn: TransformationFunction,
  options: Options
) => array.map((v) => transformCasing(v, transformationFn, options))
