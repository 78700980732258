var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stepper" }, [
    _c("p", { staticClass: "stepper__summary paragraph" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.$t("finance/stepper-description", {
              currentStep: _vm.currentStep + 1,
              total: _vm.stepsLength
            })
          ) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stepper__progress" },
      _vm._l(_vm.steps, function(step, index) {
        return _c("span", {
          key: index,
          class: { stepper__item: true, "stepper__item--active": step }
        })
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }