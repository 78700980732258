




































import useProofOfDeliveries from '../compositions/useProofOfDeliveries'
import { Ref, toRef, ref, defineComponent } from '@vue/composition-api'
import { DocumentType } from '../compositions/useProofOfDeliveries'
import { Dialog, Form, DropdownField } from '@sennder/plankton'
import { appOptions } from '/@/app-options'
import { requiredValidator, translateValidators } from '../field-validators'

export default defineComponent({
  components: { Dialog, Form, DropdownField },
  props: {
    orderId: { type: Number, required: true }
  },
  setup(props, context) {
    const orderId = toRef(props, 'orderId')
    const { refreshOrderLogs } = appOptions.libraries.useOrderActivitySidebar()
    const { requestMissingDocument } = useProofOfDeliveries(orderId)
    const documentTypes: DocumentType[] = [
      { key: 'PALLET_EXCHANGE_NOTE', value: context.root.$t('finance/global-missing-document-type-pallet') as string },
      { key: 'CMR', value: context.root.$t('finance/global-missing-document-type-CMR') as string },
      { key: 'OTHER', value: context.root.$t('finance/global-missing-document-type-other') as string }
    ]
    const selectedDocumentType: Ref<DocumentType> = ref(null)
    const show = ref(false)
    const form = ref()

    const open = () => {
      show.value = true
    }
    const close = () => {
      show.value = false
    }
    const hiddenHandler = () => {
      selectedDocumentType.value = null
    }

    const handleFormSubmitEvent = async () => {
      if (selectedDocumentType.value) {
        await requestMissingDocument({
          orderId: orderId.value,
          documentType: selectedDocumentType.value?.key
        })
        refreshOrderLogs()
        close()
      }
    }

    const $t = context.root.$t.bind(context.root)
    const selectionValidator = translateValidators($t, [requiredValidator])

    const submitMissingDocumentRequest = async () => {
      await form.value.submit()
    }

    return {
      handleFormSubmitEvent,
      open,
      close,
      show,
      hiddenHandler,
      documentTypes,
      selectionValidator,
      form,
      submitMissingDocumentRequest,
      selectedDocumentType
    }
  }
})
