var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Form",
    {
      ref: "form",
      staticClass: "claim-surcharge-form",
      attrs: { "data-test": "claim-surcharge-form" }
    },
    [
      _c("div", { staticClass: "plankton-grid" }, [
        _c(
          "div",
          { staticClass: "plankton-cell", attrs: { span: "12" } },
          [
            _c("NumericInputField", {
              attrs: {
                value: _vm.amount,
                allowInteger: 10,
                allowDecimal: 2,
                allowNegative: "",
                title: _vm.$t(
                  "finance/claim-surcharge-form-amount-field-title"
                ),
                materialIcon: "euro_symbol",
                placeholder: "0",
                fullWidth: "",
                "data-test": "claim-surcharge-amount"
              },
              on: {
                input: function($event) {
                  return _vm.emitValueUpdate("requestedAmount", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { "data-test": "claim-description-parent" } },
        [
          _c("TextAreaField", {
            staticClass: "claim-surcharge-form__description",
            attrs: {
              value: _vm.description,
              validators: _vm.descriptionValidators,
              title: _vm.$t(
                "finance/claim-surcharge-form-description-field-title"
              ),
              maxlength: "200",
              convertEmptyToNull: "",
              "data-test": "claim-description"
            },
            on: {
              input: function($event) {
                return _vm.emitValueUpdate("description", $event)
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }