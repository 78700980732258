import { render, staticRenderFns } from "./ClaimStatusView.vue?vue&type=template&id=7cdf392b&scoped=true&"
import script from "./ClaimStatusView.vue?vue&type=script&lang=ts&"
export * from "./ClaimStatusView.vue?vue&type=script&lang=ts&"
import style0 from "./ClaimStatusView.vue?vue&type=style&index=0&id=7cdf392b&lang=scss&scoped=true&"
import style1 from "./ClaimStatusView.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cdf392b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/sennder/operations-finance/finance/finance-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cdf392b')) {
      api.createRecord('7cdf392b', component.options)
    } else {
      api.reload('7cdf392b', component.options)
    }
    module.hot.accept("./ClaimStatusView.vue?vue&type=template&id=7cdf392b&scoped=true&", function () {
      api.rerender('7cdf392b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/claims/ClaimStatusView.vue"
export default component.exports