<template>
  <Dialog
    v-bind="$attrs"
    :show="showDialog"
    class="confirmation-dialog"
    inPlace
    @close="cancel"
    @confirm="confirm"
    @cancel="cancel"
  >
    <slot />
  </Dialog>
</template>

<script>
import { Dialog } from '@sennder/plankton'

export default {
  components: { Dialog },
  data() {
    return { showDialog: false }
  },
  methods: {
    askConfirmation() {
      return new Promise(resolve => {
        this.resolve = resolve
        this.showDialog = true
      })
    },
    cancel() {
      this.showDialog = false
      this.resolve && this.resolve(false)
      this.resolve = null
    },
    confirm() {
      this.showDialog = false
      this.resolve && this.resolve(true)
      this.resolve = null
    }
  }
}
</script>
<style lang="scss" scoped>
.confirmation-dialog {
  &::v-deep .modal-body {
    color: $color-neutral-main;

    strong {
      font-weight: normal;
      color: $color-neutral-darkest;
    }
  }
}
</style>
