<template>
  <div class="no-claims text-center pt-4 pb-4">
    <div v-if="canSubmitShipperClaim">
      <img
        src="@/assets/images/no_claims.svg"
        class="no-claims__pic"
      >
      <h5 class="page-header">
        {{ $t('finance/no-claims-shipper-side-title') }}
      </h5>
      <p class="subtitle">
        {{ $t('finance/no-claims-description') }}
      </p>
      <Button
        category="tertiary"
        :text="$t('finance/no-claims-add-claim-label')"
        class="mt-2"
        @click="fileShipperClaim"
      />
    </div>
    <div v-if="canSubmitCarrierClaim">
      <img
        src="@/assets/images/no_claims_carrier.svg"
        class="no-claims__pic"
      >
      <h5 class="page-header">
        {{ $t('finance/no-claims-carrier-side-title') }}
      </h5>
      <p class="subtitle">
        {{ $t('finance/no-claims-description') }}
      </p>
      <Button
        category="tertiary"
        :text="$t('finance/no-claims-add-claim-label')"
        class="mt-2"
        @click="fileCarrierClaim"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { Button } from '@sennder/plankton'
import { appOptions } from '/@/app-options'

export default defineComponent({
  components: { Button },
  setup(props, context) {
    const { currentUserIsAccountManager, currentUserIsPartnerManager } = appOptions.useCurrentUser()
    const canSubmitShipperClaim = computed(() => currentUserIsAccountManager.value)
    const canSubmitCarrierClaim = computed(() => currentUserIsPartnerManager.value)

    const fileShipperClaim = () => context.emit('file-claim', 'SHIPPER')
    const fileCarrierClaim = () => context.emit('file-claim', 'CARRIER')

    return {
      canSubmitShipperClaim,
      canSubmitCarrierClaim,
      fileShipperClaim,
      fileCarrierClaim
    }
  }
})
</script>

<style scoped lang="scss">
.no-claims {
  display: flex;
  justify-content: space-around;

  &__pic {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 32px;
  }

  h5 {
    color: $color-neutral-darkest;
  }

  p {
    color: $color-neutral-main;
  }
}
</style>
