(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@sennder/incident-management"), require("@sennder/plankton"), require("@vue/composition-api"), require("moment"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["@sennder/incident-management", "@sennder/plankton", "@vue/composition-api", "moment", "vue"], factory);
	else if(typeof exports === 'object')
		exports["finance-frontend"] = factory(require("@sennder/incident-management"), require("@sennder/plankton"), require("@vue/composition-api"), require("moment"), require("vue"));
	else
		root["finance-frontend"] = factory(root["@sennder/incident-management"], root["@sennder/plankton"], root["@vue/composition-api"], root["moment"], root["vue"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__71241__, __WEBPACK_EXTERNAL_MODULE__75983__, __WEBPACK_EXTERNAL_MODULE__47253__, __WEBPACK_EXTERNAL_MODULE__33192__, __WEBPACK_EXTERNAL_MODULE__70748__) {
return 