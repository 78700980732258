var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("div", { staticClass: "cost-centre-page" }, [
        _c("div", { staticClass: "cost-centre-page__header" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.$t("finance/cost-centre-title")) + "\n  "
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.costCentres.length > 0
              ? _c("CostCentreList", {
                  attrs: {
                    costCentres: _vm.costCentres,
                    "data-test": "const-centre-list"
                  },
                  on: {
                    upload: _vm.uploadCostCentres,
                    download: _vm.downloadCostCentreCSV,
                    showFAQ: _vm.showFaqModal
                  }
                })
              : _c("NoCostCentrePlaceholder", {
                  attrs: { "data-test": "cost-centre-placeholder" },
                  on: {
                    upload: _vm.uploadCostCentres,
                    showFAQ: _vm.showFaqModal
                  }
                }),
            _vm._v(" "),
            _c("CostCentreLoad", { ref: "costCentreLoadRef" }),
            _vm._v(" "),
            _c("CostCentreFAQModal", { ref: "costCentreFAQModalRef" })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }