


















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { DropzoneFile } from 'dropzone'
import { UploadSection } from '@sennder/plankton'

interface PresignedUpload {
  url: string
  fields: { [key: string]: string }
}

export default defineComponent({
  components: { UploadSection },
  props: {
    fetchPresignedUpload: {
      type: Function as PropType<(file: DropzoneFile) => Promise<PresignedUpload>>,
      required: true
    },
    fileValidatorAsync: {
      type: Function as PropType<(file: DropzoneFile) => Promise<void>>,
      default: () => () => Promise.resolve()
    },
    onBeforeSend: {
      type: Function as PropType<
        (file: DropzoneFile, xhr: XMLHttpRequest, formData: FormData) => void
      >,
      default: () => () => {}
    },
    renameFile: {
      type: Function as PropType<(file: DropzoneFile) => string>,
      default: null
    },
    ...UploadSection.props
  },
  setup(props) {
    const presignedUploadsByFileId: { [key: string]: PresignedUpload } = {}

    const savePresignedUpload = async (file: DropzoneFile) => {
      const presignedUpload = await props.fetchPresignedUpload(file)
      presignedUploadsByFileId[file.upload.uuid] = presignedUpload
    }

    const assignPresignedUploadBeforeSend = (
      file: DropzoneFile,
      xhr: XMLHttpRequest,
      formData: FormData
    ) => {
      const presignedUpload = presignedUploadsByFileId[file.upload.uuid]
      xhr.open('POST', presignedUpload.url)
      Object.entries(presignedUpload.fields).map(([key, value]) =>
        formData.append(key, value)
      )
    }

    const attrs = computed(() => {
      return {
        ...props,
        url: 'http://fake-url-replaced-by-presigned-url.com',
        // extends fileValidatorAsync by including the fetching of the presigned upload.
        fileValidatorAsync: async (file: DropzoneFile) => {
          await props.fileValidatorAsync(file)
          await savePresignedUpload(file)
        },
        // extends onBeforeSend by including function that assign the presigned upload information
        // before starting the upload process.
        onBeforeSend: (file: DropzoneFile, xhr: XMLHttpRequest, formData: FormData) => {
          assignPresignedUploadBeforeSend(file, xhr, formData)
          props.onBeforeSend(file, xhr, formData)
        }
      }
    })

    return { attrs }
  }
})
