var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "claims", attrs: { "data-test": "claims-section" } },
    [
      _c(
        "h2",
        {
          staticClass: "subsection-title",
          attrs: { "data-test": "claims-section-title" }
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("finance/claims-title")) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.claims && _vm.claims.length > 0
        ? [
            _vm._l(_vm.claims, function(claim) {
              return _c("ClaimPanel", {
                key: claim.id,
                staticClass: "claims__item",
                attrs: {
                  claim: claim,
                  editable: _vm.editable,
                  "data-test": "claims-panel"
                },
                on: {
                  "update-status": function($event) {
                    return _vm.updateStatus(claim, $event)
                  },
                  "edit-claim": _vm.editClaimHandler,
                  "view-documents": function($event) {
                    return _vm.showClaimDocuments(
                      claim.id,
                      claim.allowEditingFiles
                    )
                  }
                }
              })
            }),
            _vm._v(" "),
            _vm.editable
              ? _c("ClaimApproveModal", { ref: "claimApproveModal" })
              : _vm._e(),
            _vm._v(" "),
            _vm.editable
              ? _c("ClaimRejectModal", { ref: "claimRejectModal" })
              : _vm._e()
          ]
        : _c("NoClaims", {
            attrs: { "data-test": "no-claims" },
            on: { "file-claim": _vm.fileClaim }
          }),
      _vm._v(" "),
      _c("SubmitClaimModal", {
        ref: "submitClaimModal",
        attrs: { orderId: _vm.orderId, "data-test": "submit-claim-modal" }
      }),
      _vm._v(" "),
      _c("EditClaimModal", {
        ref: "editClaimModal",
        attrs: { orderId: _vm.orderId, "data-test": "edit-claim-modal" }
      }),
      _vm._v(" "),
      _c("ClaimDocumentsModal", {
        ref: "claimDocumentsModal",
        attrs: { canDelete: false }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }