


























































































import {
  defineComponent,
  ref,
  toRef,
  watch,
  reactive,
  PropType,
  Ref,
  computed
} from '@vue/composition-api'
import { useModal, useStateReset } from '/@/compositions'
import useExtraCharges from '/@/compositions/useExtraCharges'
import {
  AdjustmentType,
  default as useChargeAdjustmentStrategy
} from '/@/compositions/useChargeAdjustmentStrategy'
import { ExtraChargeType } from '/@/services/types'
import {
  requiredValidatorWhen,
  requiredValidator,
  nonZeroValueValidator,
  translateValidators
} from '/@/field-validators'
import { hasHadState } from '/@/order-states'
import { appOptions } from '/@/app-options'
import { Dialog, Form, PrimitiveDropdownField, NumericInputField, InputField } from '@sennder/plankton'
import useChargeCorrectionSideEffects from '/@/compositions/useChargeCorrectionSideEffects'

type DropdownOption<T> = { label: string; value: T }

interface Adjustment {
  type: AdjustmentType
  amount: number
  comment: string
  category: string | number
}
const useChargeAdjustment = () => {
  const adjustment = reactive<Adjustment>({
    type: null,
    category: null,
    amount: null,
    comment: null
  })

  watch(
    () => adjustment.type,
    (newType, oldType) => {
      if (newType === oldType) return
      adjustment.category = null
    }
  )

  return adjustment
}

const LABELS = {
  CARRIER: {
    amount: 'finance/extra-charge-correction-modal-cost'
  },
  CUSTOMER: {
    amount: 'finance/extra-charge-correction-modal-price'
  }
}

export default defineComponent({
  components: { Dialog, Form, PrimitiveDropdownField, NumericInputField, InputField },
  props: {
    orderId: { type: Number, required: true },
    type: { type: String as PropType<ExtraChargeType>, default: 'CARRIER' },
    refreshInvoicesOnNewCharges: { type: Boolean, default: false }
  },
  setup(props, context) {
    const form = ref(null)
    const orderId = toRef(props, 'orderId')
    const type = toRef(props, 'type') as Ref<ExtraChargeType>
    const adjustment = useChargeAdjustment()
    const adjustmentType = toRef(adjustment, 'type')
    const $t = context.root.$t.bind(context.root)
    const { categoryOptions, save } = useChargeAdjustmentStrategy(adjustmentType, type, $t)
    const { loadOrderDetail, order } = appOptions.useOrder()
    const { loadCharges } = useExtraCharges(orderId, [type.value])
    const resetState = useStateReset(adjustment)
    const { triggerChargeCorrectionSideEffects } = useChargeCorrectionSideEffects(orderId)
    const modal = useModal({
      isInvalid: () => Boolean(form.value.submit().hasErrors),
      onConfirm: async () => {
        await save(adjustment.category, adjustment.amount, adjustment.comment)
        loadCharges(type.value)

        // trigger side effects to refresh order data
        triggerChargeCorrectionSideEffects(props.refreshInvoicesOnNewCharges ? type.value : null)
      }
    })

    if (!order.value) loadOrderDetail()

    const canAddSurcharge = computed(() => {
      if (type.value === 'CUSTOMER') return true
      if (!order.value) return false
      // Do not let adding carrier side surcharges until
      // the order has a base cost (it is in the state CARRIER_LOCKED)
      if (!hasHadState(order.value.state, 'CARRIER_LOCKED')) return false
      return true
    })

    const CHARGE_TYPE_OPTIONS: DropdownOption<AdjustmentType>[] = [
      {
        label: context.root.$t('finance/extra-charge-correction-modal-type-option-surcharge') as string,
        value: 'EXTRA_CHARGE'
      },
      {
        label: context.root.$t('finance/extra-charge-correction-modal-type-option-correction') as string,
        value: 'EXTRA_CHARGE_CORRECTION' 
      }
    ]


    return {
      LABELS,
      form,
      modal,
      adjustment,
      typeOptions: CHARGE_TYPE_OPTIONS,
      categoryOptions,
      resetState,
      chargeTypeValidators: translateValidators($t, [requiredValidator]),
      chargeCategoryValidators: translateValidators($t, [requiredValidator]),
      chargeAmountValidators: translateValidators($t, [requiredValidator, nonZeroValueValidator]),
      chargeCommentValidators: translateValidators($t, [
        requiredValidatorWhen(() => adjustment.category === 'OTHER_SURCHARGE')
      ]),
      canAddSurcharge
    }
  }
})
