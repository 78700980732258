var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "data-test": "claim-documents-form" } },
    [
      _vm.aggregateErrors.length > 0
        ? _c(
            "AlertMessage",
            {
              staticClass: "claim-documents-form__errors",
              attrs: { type: "error", "data-test": "general-errors" }
            },
            [
              _c(
                "ul",
                { staticClass: "claim-documents-form__errors-list" },
                _vm._l(_vm.aggregateErrors, function(error) {
                  return _c("li", { key: error }, [
                    _c(
                      "span",
                      { staticClass: "claim-documents-form__error-text" },
                      [_vm._v(_vm._s(error))]
                    )
                  ])
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.disabled
        ? _c("PresignedUploadSection", {
            staticClass: "claim-documents-form__upload-section",
            attrs: {
              mimeTypes: _vm.SUPPORTED_MIME_TYPES,
              onFileAdded: _vm.addFile,
              fileValidatorAsync: _vm.fileValidator,
              onFileError: _vm.handleFileError,
              fetchPresignedUpload: _vm.fetchPresignedClaimUpload,
              onUploadProgress: _vm.handleUploadProgress,
              onUploadSuccess: _vm.handleUploadSuccess,
              renameFile: _vm.renameFile,
              title: _vm.$t(
                "finance/claim-documents-form-upload-section-title"
              ),
              description: _vm.$t(
                "finance/claim-documents-form-upload-section-description",
                { MAX_TOTAL_FILES: _vm.MAX_TOTAL_FILES }
              ),
              compact: "",
              "data-test": "claim-upload-document-section"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.completeClaimFiles, function(file) {
        return _c("UploadedFile", {
          key: file.id,
          staticClass: "claim-documents-form__file",
          attrs: {
            fileName: file.name,
            fileSizeInBytes: file.sizeBytes,
            uploadedBytes: file.uploadedBytes,
            uploadDate: file.uploadedDateTime,
            error: _vm.getReadableErrorMessage(file.error),
            clickHandler: file.submitted
              ? function() {
                  return _vm.downloadFile(file)
                }
              : null,
            "data-test": "claim-file"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "side-content",
                fn: function() {
                  return [
                    !_vm.disabled && _vm.canDelete
                      ? _c(
                          "i",
                          {
                            staticClass:
                              "material-icons claim-documents-form__delete-button",
                            attrs: { "data-test": "delete-file" },
                            on: {
                              click: function($event) {
                                return _vm.deleteFile(file)
                              }
                            }
                          },
                          [_vm._v("\n        delete_outline\n      ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }