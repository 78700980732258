var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WizardModal",
    {
      attrs: {
        activeStep: _vm.activeStep,
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        confirmButtonDisabled: _vm.isConfirmButtonDisabled,
        lazy: ""
      },
      on: {
        nextStep: _vm.handleNext,
        prevStep: _vm.handleBack,
        close: _vm.modal.close,
        hidden: _vm.resetState
      }
    },
    [
      _c(
        "WizardModalStep",
        {
          attrs: {
            title: _vm.$t(
              "finance/submit-claim-modal-step-claim-details-title"
            ),
            description: _vm.$t(
              "finance/submit-claim-modal-step-claim-details-description"
            )
          }
        },
        [
          _c(
            "AlertMessage",
            { staticClass: "documents-alert", attrs: { type: "info" } },
            [
              _c("p", {
                staticClass: "documents-alert-description",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("finance/submit-claim-modal-step-surcharges-info", {
                      guidanceLink: _vm.guidanceLink
                    })
                  )
                }
              })
            ]
          ),
          _vm._v(" "),
          _c("ClaimDetailsForm", {
            ref: "claimDetailsForm",
            attrs: { orderId: _vm.orderId },
            model: {
              value: _vm.claim,
              callback: function($$v) {
                _vm.claim = $$v
              },
              expression: "claim"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "WizardModalStep",
        {
          attrs: {
            title: _vm.$t("finance/submit-claim-modal-step-surcharges-title"),
            description: _vm.$t(
              "finance/submit-claim-modal-step-surcharges-description"
            )
          }
        },
        [
          _c(
            "AlertMessage",
            { staticClass: "documents-alert", attrs: { type: "info" } },
            [
              _c("p", {
                staticClass: "documents-alert-description",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("finance/submit-claim-modal-step-documents-info", {
                      guidanceLink: _vm.guidanceLink
                    })
                  )
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "RenderWithLaunchDarkly",
            { attrs: { hasPermission: "show-accessorial-feature" } },
            [
              _c(
                "div",
                [
                  _vm.claim
                    ? _c("ClaimSurchargeForm", {
                        ref: "claimSurchargeAndDocumentsForm",
                        attrs: { side: _vm.currentSide },
                        model: {
                          value: _vm.claim,
                          callback: function($$v) {
                            _vm.claim = $$v
                          },
                          expression: "claim"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ClaimsDocumentsForm", {
                    ref: "claimSurchargeAndDocumentsForm",
                    on: { loading: _vm.handleFileLoading },
                    model: {
                      value: _vm.claimFiles,
                      callback: function($$v) {
                        _vm.claimFiles = $$v
                      },
                      expression: "claimFiles"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "RenderWithLaunchDarkly",
            { attrs: { doesNotHavePermission: "show-accessorial-feature" } },
            [
              _c(
                "div",
                [
                  _vm.claim
                    ? _c("ClaimSurchargeForm", {
                        ref: "claimSurchargeAndDocumentsForm",
                        attrs: { side: _vm.currentSide },
                        model: {
                          value: _vm.claim,
                          callback: function($$v) {
                            _vm.claim = $$v
                          },
                          expression: "claim"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ClaimsDocumentsForm", {
                    ref: "claimSurchargeAndDocumentsForm",
                    on: { loading: _vm.handleFileLoading },
                    model: {
                      value: _vm.claimFiles,
                      callback: function($$v) {
                        _vm.claimFiles = $$v
                      },
                      expression: "claimFiles"
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }