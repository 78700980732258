import {computed, reactive, ref, toRefs} from '@vue/composition-api'
import { ClaimBoardColumnData, ClaimBoardColumnType } from '/@/services/types'
import { CLAIM_BOARD_COLUMNS } from '/@/constants'
import {
  fetchClaimBoardColumn,
  fetchClaimBoardColumnByUrl,
  searchClaimBoard
} from '/@/services/claim-service'
import { AlertModal } from '@sennder/plankton'
import Swal from 'sweetalert2'
import axios from 'axios'
import { searchClaimBoardClaimsAccesorials } from '/@/services/claims-accesorials-service'
import { appOptions } from '/@/app-options'

const state = reactive({
  submitted: <ClaimBoardColumnData>{
    count: 0,
    nextUrl: null,
    previousUrl: null,
    results: []
  },
  processing: <ClaimBoardColumnData>{
    count: 0,
    nextUrl: null,
    previousUrl: null,
    results: []
  },
  completed: <ClaimBoardColumnData>{
    count: 0,
    nextUrl: null,
    previousUrl: null,
    results: []
  }
})

const pagination = reactive({
  submitted: {
    startOffset: 0,
    endOffset: 0
  },
  processing: {
    startOffset: 0,
    endOffset: 0
  },
  completed: {
    startOffset: 0,
    endOffset: 0
  }
})

const loading = reactive({
  submitted: false,
  processing: false,
  completed: false
})

const searchOrderIdString = ref('')
const searchDateString = ref('')

export default function useClaimBoard() {

  const loadColumn = async (columnType: ClaimBoardColumnType) => {
    loading[columnType] = true

    if (isEnableClaimAccessorial.value) {
      state[columnType] = await searchClaimBoardClaimsAccesorials(columnType, '1', '10', {})
    } else {
      state[columnType] = await fetchClaimBoardColumn(columnType)
    }

    pagination[columnType].endOffset = state[columnType].results?.length
    loading[columnType] = false
  }

  const isEnableClaimAccessorial = computed(() => {
    return appOptions.useFeatureFlag().isActive('ENABLE_CLAIM_ACCESSORIAL').value
  })

  const reloadColumns = async () => {
    searchOrderIdString.value = ''
    searchDateString.value = ''

    CLAIM_BOARD_COLUMNS.forEach(col => {
      pagination[col] = { startOffset: 0,  endOffset: 0 }
      loading[col] = true
    })

    await Promise.all(CLAIM_BOARD_COLUMNS.map(loadColumn))

    CLAIM_BOARD_COLUMNS.forEach(col => (loading[col] = false))
  }

  const filter = async ({
    search = '',
    date = ''
  }: {
    search?: string
    date?: string
  }) => {
    if (search === '' && date === '') return await reloadColumns()

    searchOrderIdString.value = search
    searchDateString.value = date

    CLAIM_BOARD_COLUMNS.forEach(col => (loading[col] = true))

    try {
      if (isEnableClaimAccessorial.value ) {
        await Promise.all(CLAIM_BOARD_COLUMNS.map(async (columnType) => {
          state[columnType] = await searchClaimBoardClaimsAccesorials(columnType, '1', '10', { search, date })
          pagination[columnType].endOffset = state[columnType].results?.length
        }))
      } else {
        const result = await searchClaimBoard({
          search,
          date
        })

        CLAIM_BOARD_COLUMNS.forEach(columnType => {
          state[columnType] = {
            count: result[columnType]?.length,
            results: result[columnType]
          }
        })
      }

    } catch (error) {
      if (axios.isAxiosError(error)) {
        const alertModal = AlertModal(Swal)
        alertModal.showErrorMessage(
          `${error?.response?.status} ${error?.response?.statusText}`,
          `${error?.response?.data.slice(0, 100)}...`
        )
      }
    } finally {
      CLAIM_BOARD_COLUMNS.forEach(col => (loading[col] = false))
    }
  }

  const nextPage = async (columnType: ClaimBoardColumnType) => {
    if (!state[columnType].nextUrl) return

    loading[columnType] = true

    if (isEnableClaimAccessorial.value) {
      state[columnType] = await searchClaimBoardClaimsAccesorials(
        columnType,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state[columnType].nextUrl?.page,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state[columnType].nextUrl?.size,
        {
          search: searchOrderIdString.value,
          date: searchDateString.value
        }
      )
    } else {
      state[columnType] = await fetchClaimBoardColumnByUrl(
        state[columnType].nextUrl as string,
        columnType
      )
    }

    pagination[columnType].startOffset = pagination[columnType].endOffset
    pagination[columnType].endOffset =
      pagination[columnType].endOffset + state[columnType].results.length
    loading[columnType] = false
  }

  const prevPage = async (columnType: ClaimBoardColumnType) => {
    if (!state[columnType].previousUrl) return

    loading[columnType] = true

    if (isEnableClaimAccessorial.value) {
      state[columnType] = await searchClaimBoardClaimsAccesorials(
        columnType,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state[columnType].previousUrl?.page,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state[columnType].previousUrl?.size,
        {
          search: searchOrderIdString.value,
          date: searchDateString.value
        }
      )
    } else {
      state[columnType] = await fetchClaimBoardColumnByUrl(
        state[columnType].previousUrl as string,
        columnType
      )
    }

    pagination[columnType].endOffset = pagination[columnType].startOffset
    pagination[columnType].startOffset =
      pagination[columnType].startOffset - state[columnType].results.length
    loading[columnType] = false
  }

  return {
    ...toRefs(state),
    filter,
    reloadColumns,
    loadColumn,
    pagination: toRefs(pagination),
    nextPage,
    prevPage,
    loading: toRefs(loading)
  }
}
