import axios from 'axios'
import { fleetServiceApi } from './api'

export const fetchOrderAssignmentTruck = async (orderId: number) => {
  try {
    const response = await fleetServiceApi.get(
      `/api/assignments/${orderId}/truck-id`,
    )
    return response.data.data as number
  } catch(error) {
    if (axios.isAxiosError(error) && error.response.status === 404) {
      return null
    }
    throw error
  }
}