var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SidebarCard", {
    staticClass: "carrier-contacts",
    attrs: { "data-test": "carrier-contact-info" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(
              "\n    Carrier contact information\n    " +
                _vm._s(_vm.$t("finance/carrier-contact-info-title")) +
                "\n  "
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "plankton-grid", attrs: { columns: "12" } },
              [
                _c(
                  "LabeledText",
                  {
                    staticClass: "carrier-contacts__block plankton-cell",
                    attrs: {
                      span: "12",
                      label: _vm.$t(
                        "finance/carrier-contact-info-company-label"
                      ),
                      "data-test": "carrier-company"
                    }
                  },
                  [
                    _vm.order.carrierCompanyId
                      ? _c(
                          "SalesforceLink",
                          {
                            staticClass: "carrier-contacts__company-name",
                            class: {
                              "carrier-contacts__company-name--orcas":
                                _vm.hasOrcasAccess
                            },
                            attrs: {
                              "data-test": "labeled-text--text",
                              companyId: _vm.order.carrierCompanyId
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.companyName) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("LabeledText", {
                  staticClass: "carrier-contacts__block plankton-cell",
                  attrs: {
                    span: "7",
                    label: _vm.$t(
                      "finance/carrier-contact-info-contact-name-label"
                    ),
                    text: _vm.contactName,
                    "data-test": "carrier-contact-name"
                  }
                }),
                _vm._v(" "),
                _c("LabeledText", {
                  staticClass: "carrier-contacts__block plankton-cell",
                  attrs: {
                    span: "5",
                    label: _vm.$t(
                      "finance/carrier-contact-info-contact-phone-label"
                    ),
                    text: _vm.contactPhone,
                    "data-test": "carrier-contact-phone"
                  }
                }),
                _vm._v(" "),
                _c("LabeledText", {
                  staticClass: "carrier-contacts__block plankton-cell",
                  attrs: {
                    span: "12",
                    label: _vm.$t(
                      "finance/carrier-contact-info-contact-email-label"
                    ),
                    text: _vm.contactEmail,
                    "data-test": "carrier-contact-email"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }