var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Form",
    {
      ref: "form",
      staticClass: "claim-details-form",
      attrs: { "data-test": "claim-details-form" }
    },
    [
      _c("div", { staticClass: "plankton-grid" }, [
        _c(
          "div",
          { staticClass: "plankton-cell", attrs: { span: "12" } },
          [
            _c("PrimitiveDropdownField", {
              attrs: {
                value: _vm.claim.reporter,
                options: _vm.reporterOptions,
                validators: _vm.reporterValidators,
                optionKey: "value",
                optionLabel: "label",
                disabled: _vm.isReporterFieldDisabled,
                fullWidth: "",
                placeholder: _vm.$t(
                  "finance/claim-details-form-reporter-field-placeholder"
                ),
                title: _vm.$t(
                  "finance/claim-details-form-reporter-field-title"
                ),
                "data-test": "claim-reporter"
              },
              on: {
                select: function($event) {
                  return _vm.emitValueUpdate("reporter", $event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plankton-cell", attrs: { span: "12" } },
          [
            _c("PrimitiveDropdownField", {
              attrs: {
                value: _vm.claim.faultyParty,
                options: _vm.faultyPartyOptions,
                validators: _vm.faultyPartyValidators,
                disabled: _vm.disabled,
                optionKey: "value",
                optionLabel: "label",
                fullWidth: "",
                placeholder: _vm.$t(
                  "finance/claim-details-form-faulty-party-field-placeholder"
                ),
                title: _vm.$t(
                  "finance/claim-details-form-faulty-party-field-title"
                ),
                "data-test": "claim-faulty-party"
              },
              on: {
                select: function($event) {
                  return _vm.emitValueUpdate("faultyParty", $event)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "plankton-grid" }, [
        _c(
          "div",
          { staticClass: "plankton-cell", attrs: { span: "8" } },
          [
            _c("PrimitiveDropdownField", {
              attrs: {
                value: _vm.claim.claimType,
                options: _vm.claimTypeOptions,
                validators: _vm.claimTypeValidators,
                disabled: _vm.disabled,
                optionKey: "value",
                optionLabel: "label",
                fullWidth: "",
                placeholder: _vm.$t(
                  "finance/claim-details-form-type-field-placeholder"
                ),
                title: _vm.$t("finance/claim-details-form-type-field-title"),
                "data-test": "claim-type"
              },
              on: {
                select: function($event) {
                  return _vm.emitValueUpdate("claimType", $event)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plankton-cell", attrs: { span: "8" } },
          [
            _c("DropdownField", {
              attrs: {
                options: _vm.claimSubtypeOptions,
                validators: _vm.claimSubtypeValidators,
                disabled: _vm.disabled,
                optionLabel: "description",
                fullWidth: "",
                placeholder: _vm.$t(
                  "finance/claim-details-form-subtype-field-placeholder"
                ),
                title: _vm.$t("finance/claim-details-form-subtype-field-title"),
                "data-test": "claim-sub-type"
              },
              model: {
                value: _vm.claimSubtypeForDropdown,
                callback: function($$v) {
                  _vm.claimSubtypeForDropdown = $$v
                },
                expression: "claimSubtypeForDropdown"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plankton-cell", attrs: { span: "8" } },
          [
            _c("DateTimeInputField", {
              staticClass: "claim-details-form__incident-time-field",
              attrs: {
                value: _vm.claim.incidentTime,
                validators: _vm.incidentTimeValidators,
                dateTimeFormat: _vm.dateTimeFormat,
                disabled: _vm.disabled,
                title: _vm.$t(
                  "finance/claim-details-form-incident-time-field-title"
                ),
                timePicker: "",
                placeholder: _vm.dateTimeFormat.toLowerCase(),
                clearSelectionLabel: _vm.$t(
                  "finance/claim-details-form-incident-time-field-clear-label"
                ),
                "data-test": "claim-date-time"
              },
              on: {
                input: function($event) {
                  return _vm.emitValueUpdate("incidentTime", $event)
                }
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }