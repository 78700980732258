































import SidebarCard from '/@/components/shared/SidebarCard.vue'
import LabeledText from '/@/components/shared/LabeledText.vue'
import { computed, defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { appOptions } from '/@/app-options'

export default defineComponent({
  components: { SidebarCard, LabeledText },
  props: {
    transferId: {
      type: Number,
      required: true
    }
  },
  setup(props, { root }) {
    let transfer = ref(null)
    const loading = ref(true)
    onBeforeMount(async () => {
      transfer.value = await appOptions.orderingService.fetchTransferById(props.transferId)
      loading.value = false
    })
    const vehicleLicensePlate = computed(() => {
      if (loading.value) return root.$t('finance/carrier-vehicle-info-loading')
      return transfer?.value?.licensePlate || '-'
    })
    const trailerLicensePlate = computed(() => {
      if (loading.value) return root.$t('finance/carrier-vehicle-info-loading')
      return transfer?.value?.trailerLicensePlate || '-'
    })
    return {
      transfer,
      vehicleLicensePlate,
      trailerLicensePlate
    }
  }
})
