










import { toRef, defineComponent, computed, ref, watchEffect } from '@vue/composition-api'
import { getSalesforceUrl } from '/@/services/finance-service'

export default defineComponent({
  props: {
    companyId: { type: Number, required: true }
  },
  setup(props) {
    const companyId = toRef(props, 'companyId')
    const salesforceUrl = ref<string>(null)
    watchEffect(async () => {
      const response = await getSalesforceUrl(companyId.value)
      salesforceUrl.value = response?.salesforceLink
    })

    const tag = computed(() => {
      if (salesforceUrl.value) {
        return 'a'
      }
      return 'span'
    })

    return {
      salesforceUrl,
      tag
    }
  }
})
