import './initCompositionApi'
import packageJson from '../package.json'
import { VueConstructor } from 'vue/types/umd'
import { Options, setAppOptions } from './app-options'
import {
  submitDieselFloater,
  fetchFinanceOrderLogs,
  fetchOrdersAggregation,
  fetchCarrierBillingInformation
} from './services/finance-service'
import IncidentManagement from '@sennder/incident-management'
import Vue from 'vue'
import PortalVue from 'portal-vue'

export default {
  install: (_Vue: VueConstructor, options: Options): void => {
    Vue.use(IncidentManagement, options.incidentManagementParams)
    Vue.use(PortalVue)
    setAppOptions(options)
  }
}

export const VERSION = packageJson.version

export { default as SidebarCard } from './components/shared/SidebarCard.vue'
export { default as ClosingOrder } from './components/ClosingOrder.vue'
export { default as SubmitClaimModal } from './components/claims/SubmitClaimModal.vue'
export { default as FinanceOrderPage } from './components/FinanceOrderPage.vue'
export { default as ClaimsBoard } from './components/claims/board/ClaimsBoard.vue'
export { default as SendShipperInvoicesModal } from './components/invoices/SendShipperInvoicesModal.vue'
export { default as CostCentrePage } from './components/cost-centre/CostCentrePage.vue'

// TODO: Investigate whether it's possible to avoid Octopus depends on composition functions.
export { default as useOrderPaymentStatus } from './compositions/useOrderPaymentStatus'
// These services are used in Finance Board and C&A sidebar of Octopus
export const financeService = {
  submitDieselFloater,
  fetchFinanceOrderLogs,
  fetchOrdersAggregation,
  fetchCarrierBillingInformation
}
export { CLAIM_STATUSES_LABELS } from './constants'

export { default as languages } from './languages'
