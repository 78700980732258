var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dl", { staticClass: "billing-info paragraph" }, [
    _c("div", { staticClass: "billing-info__row" }, [
      _c("dt", { staticClass: "billing-info__title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("finance/billing-info-billing-address-label")) +
            ":\n    "
        )
      ]),
      _vm._v(" "),
      _c("dd", { attrs: { "data-test": "billing-address" } }, [
        _vm._v("\n      " + _vm._s(_vm.billingAddress) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "billing-info__row" }, [
      _c("dt", { staticClass: "billing-info__title" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("finance/billing-info-billing-email-label")) +
            ":\n    "
        )
      ]),
      _vm._v(" "),
      _c("dd", { attrs: { "data-test": "billing-email" } }, [
        _vm._v("\n      " + _vm._s(_vm.billingEmail) + "\n    ")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "billing-info__row" }, [
      _c("dt", { staticClass: "billing-info__title" }, [
        _vm._v("\n      IBAN/BIC:\n    ")
      ]),
      _vm._v(" "),
      _c("dd", { attrs: { "data-test": "billing-iban-bic" } }, [
        _vm._v("\n      " + _vm._s(_vm.ibanBic) + "\n    ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }