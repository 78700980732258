import { Currency } from '/@/constants'
import { Amount } from '/@/services/claims-accessorial-types'
/**
 * We need to know the user locale to be able to format money values with browser API.
 * If we want to respect language selection, we need to pass locale from the Octopus shell.
 */
const DEFAULT_LOCALE = 'en-GB'

export const getFormattedAmount = (amount: Amount) => {
  return new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: 'currency',
    currency: amount.currency,
    currencyDisplay: 'narrowSymbol',
  }).format(Number(amount.value))
}

export const getCurrencySign = (currency: Currency = Currency.EUR) => {
  return (
    new Intl.NumberFormat(DEFAULT_LOCALE, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
    })
      .formatToParts(1)
      .find((part) => part.type === 'currency')?.value || ''
  )
}
