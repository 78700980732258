










































































import { defineComponent, PropType } from '@vue/composition-api'
import { CostCentre } from '/@/services'
import { Button } from '@sennder/plankton'

export default defineComponent({
  components: { Button },
  props: {
    costCentres: { type: Array as PropType<CostCentre[]>, required: true }
  }
})
