export const BASE_AMOUNT_LABELS = {
  CARRIER: 'finance/global-carrier-base-amount-label',
  CUSTOMER: 'finance/global-shipper-base-amount-label'
}
export const DEFAULT_TIMEZONE = 'Europe/Berlin'

export const CLAIM_BOARD_COLUMNS = [
  'submitted',
  'processing',
  'completed',
] as const

export const ORDER_STATUSES = {
  NEW: { label: 'New', category: 'secondary' },
  REGISTERED: { label: 'Registered', category: 'primary' },
  CARRIER_LOCKED: { label: 'Carrier locked', category: 'red' },
  DISPATCHED: { label: 'Dispatched', category: 'primary' },
  EXECUTED: { label: 'Executed', category: 'green' },
  OPERATIONS_COMPLETED: { label: 'Ops completed', category: 'green' },
  CANCELLED_OPERATIONS_COMPLETED: {
    label: 'Cancelled (closed)',
    category: 'red',
  },
  CANCELLED_NOT_OPERATIONS_COMPLETED: {
    label: 'Cancelled (open)',
    category: 'red',
  },
}

export const CLAIM_STATUSES_LABELS = {
  SUBMITTED: 'Submitted',
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  MORE_INFORMATION_REQUIRED: 'More information required',
  MORE_INFO_REQUIRED: 'More information required',
} as const

export const CLAIM_STATUSES = [
  'SUBMITTED',
  'PENDING',
  'APPROVED',
  'REJECTED',
  'MORE_INFORMATION_REQUIRED',
  'MORE_INFO_REQUIRED',
  'NOT_SUBMITTED'
] as const

export const CLAIM_GUIDELINES_LINK = 'https://docs.google.com/document/d/1Mk1Tk07F2fnJpJ42d8YMSADFlsYN6aGSOXs96TachFY/edit'

export enum Currency {
  EUR = 'EUR',
  PLN = 'PLN'
}
