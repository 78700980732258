var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.claim
    ? _c("div", { staticClass: "claim-panel" }, [
        _c("header", { staticClass: "claim-panel__header" }, [
          _c(
            "div",
            {
              staticClass: "claim-panel__header-title subsection-title",
              attrs: { "data-test": "claim-title" }
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "claim-panel__header-info paragraph",
              attrs: { "data-test": "claim-info" }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.headerInfo) + " |\n      "),
              _c("Button", {
                staticClass: "claim-card__action",
                attrs: {
                  disabled:
                    _vm.claim.filesCount === 0 && !_vm.claim.allowEditingFiles,
                  category: "link",
                  text: _vm.$t("finance/claim-panel-documents-button-label", {
                    count: _vm.claim.filesCount
                  }),
                  "data-test": "documents-button"
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("view-documents")
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "claim-panel__cards" },
          [
            _c("ClaimCard", {
              staticClass: "claim-panel__card",
              attrs: {
                description: _vm.claim.shipper.description,
                title: _vm.$t("finance/claim-panel-shipper-card-title"),
                mainAction: _vm.shipperActionName,
                amount: _vm.claim.shipper.requestedAmount,
                finalAmount: _vm.claim.shipper.finalAmount,
                status: _vm.claim.shipper.status,
                statusDescription: _vm.$t(
                  _vm.statusTranslationKeys[_vm.claim.shipper.status]
                ),
                statusTransitions: _vm.claim.shipper.statusAvailableTransitions,
                editable: _vm.editable,
                notes: _vm.shipperNotes,
                "data-test": "claim-shipper-card"
              },
              on: {
                "update-status": function($event) {
                  return _vm.updateStatus("SHIPPER", $event)
                },
                "action-click": function($event) {
                  return _vm.actionHandler("SHIPPER", $event)
                }
              }
            }),
            _vm._v(" "),
            _c("ClaimCard", {
              staticClass: "claim-panel__card",
              attrs: {
                description: _vm.claim.carrier.description,
                title: _vm.$t("finance/claim-panel-carrier-card-title"),
                mainAction: _vm.carrierActionName,
                amount: _vm.claim.carrier.requestedAmount,
                finalAmount: _vm.claim.carrier.finalAmount,
                status: _vm.claim.carrier.status,
                statusDescription: _vm.$t(
                  _vm.statusTranslationKeys[_vm.claim.carrier.status]
                ),
                statusTransitions: _vm.claim.carrier.statusAvailableTransitions,
                editable: _vm.editable,
                notes: _vm.carrierNotes,
                "data-test": "claim-carrier-card"
              },
              on: {
                "update-status": function($event) {
                  return _vm.updateStatus("CARRIER", $event)
                },
                "action-click": function($event) {
                  return _vm.actionHandler("CARRIER", $event)
                }
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }