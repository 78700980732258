














import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    tooltipOptions() {
      return { content: this.text, delay: { show: 500 } }
    }
  }
})
