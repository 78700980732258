



























































import { computed, defineComponent, PropType, watch } from '@vue/composition-api'
import { DropzoneFile } from 'dropzone'
import { ClaimFileUpload } from '/@/compositions/useClaims'
import {
  FileUpload,
  InProgressFileUpload,
  default as useUploadingFiles
} from '/@/compositions/useUploadingFiles'
import PresignedUploadSection from '/@/components/shared/PresignedUploadSection.vue'
import { PresignedUpload } from '/@/services/types'
import {
  fetchPresignedUpload,
  fetchClaimDocumentUrl
} from '/@/services/claim-service'
import { AlertMessage, UploadedFile } from '@sennder/plankton'
import { BYTES_IN_ONE_MB, formatBytesToMB } from '/@/utils/formatBytesToMB'
import toASCII from '/@/utils/toASCII'

const modelOptions = { prop: 'claimFiles', event: 'input' }
const MAX_TOTAL_FILES = 20
const MAX_TOTAL_SIZE_BYTES = 50 * BYTES_IN_ONE_MB
const MAX_FILE_NAME_LENGTH = 128
const SUPPORTED_MIME_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/gif',
  '.pdf',
  '.png',
  '.jpeg',
  '.gif'
]

export default defineComponent({
  components: { UploadedFile, AlertMessage, PresignedUploadSection },
  model: modelOptions,
  props: {
    claimFiles: { type: Array as PropType<ClaimFileUpload[]>, required: true },
    disabled: { type: Boolean, default: false },
    canDelete: {type: Boolean, default: true }
  },
  setup(props, context) {
    const presignedUploads: { [id: string]: PresignedUpload } = {}
    const {
      inProgressFiles,
      isUploading,
      addFile,
      removeFile,
      handleFileError,
      handleUploadProgress,
      handleUploadSuccess
    } = useUploadingFiles({ onFileUploaded: file => addUploadedFile(file) })

    const completeClaimFiles = computed(() => [
      ...inProgressFiles.value,
      ...props.claimFiles
    ])

    const deleteFile = (file: ClaimFileUpload) => {
      if (isInProgressFile(file)) removeFile(file)
      else removeUploadedFile(file.id)
    }

    const removeUploadedFile = (id: string) => {
      const file = props.claimFiles.find(f => f.id === id)
      const newUploadedFiles = props.claimFiles.filter(f => f.id !== id)
      context.emit(modelOptions.event, newUploadedFiles)
      context.emit('file-removed', file)
    }

    const addUploadedFile = (uploadedFile: FileUpload) => {
      const presignedUpload = presignedUploads[uploadedFile.id]
      const claimFileUpload: ClaimFileUpload = {
        ...uploadedFile,
        submitted: false,
        uploadDirectory: presignedUpload ? presignedUpload.uploadDirectory : null
      }
      context.emit(modelOptions.event, [claimFileUpload, ...props.claimFiles])
    }

    const downloadFile = async (file: ClaimFileUpload) => {
      const downloadUrl = await fetchClaimDocumentUrl(parseInt(file.id))
      window.open(downloadUrl)
    }

    const isInProgressFile = (
      file: ClaimFileUpload | InProgressFileUpload
    ): file is InProgressFileUpload => {
      return (file as InProgressFileUpload).uploadedBytes !== undefined
    }

    const fetchPresignedClaimUpload = async (file: DropzoneFile) => {
      const presignedUpload = await fetchPresignedUpload(file.upload.filename)
      presignedUploads[file.upload.uuid] = presignedUpload

      return {
        url: presignedUpload.url,
        fields: presignedUpload.fields
      }
    }

    const getReadableErrorMessage = (error: string) => {
      if (!error) return null
      if (error === 'INVALID_FILE_TYPE') return context.root.$t('finance/claim-documents-form-error-invalid-file')
      return error.toString()
    }

    const fileValidator = (file: DropzoneFile) => {
      if (file.upload.filename.length > MAX_FILE_NAME_LENGTH) {
        return Promise.reject(
          context.root.$t(
            'finance/claim-documents-form-error-exceeded-file-name',
            { nameLen: file.upload.filename.length, maxNameLen: MAX_FILE_NAME_LENGTH }
          )
        )
      }
      return Promise.resolve()
    }

    const renameFile = (file: DropzoneFile) => {
      return toASCII(file.name)
    }

    const aggregateErrors = computed(() => {
      const errors = []
      if (props.claimFiles.length > MAX_TOTAL_FILES) {
        errors.push(
          context.root.$t(
            'finance/claim-documents-form-error-exceeded-total-files',
            { filesCount: props.claimFiles.length, maxFilesCount: MAX_TOTAL_FILES }
          )
        )
      }
      const totalSizeBytes = props.claimFiles.reduce((acc, f) => acc + f.sizeBytes, 0)
      if (totalSizeBytes > MAX_TOTAL_SIZE_BYTES) {
        errors.push(
          context.root.$t(
            'finance/claim-documents-form-error-exceeded-total-size',
            { totalSize: formatBytesToMB(totalSizeBytes) }
          )
        )
      }

      return errors
    })

    const submit = () => {
      const hasAnyFileError = props.claimFiles.some(f => f.error)
      return { hasErrors: hasAnyFileError || aggregateErrors.value.length > 0 }
    }

    watch(isUploading, isUploading => context.emit('loading', isUploading))

    return {
      SUPPORTED_MIME_TYPES,
      MAX_TOTAL_FILES,
      completeClaimFiles,
      addFile,
      handleFileError,
      handleUploadProgress,
      handleUploadSuccess,
      fetchPresignedClaimUpload,
      downloadFile,
      deleteFile,
      getReadableErrorMessage,
      aggregateErrors,
      fileValidator,
      submit,
      renameFile
    }
  }
})
