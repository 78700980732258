

























































import SidebarCard from '/@/components/shared/SidebarCard.vue'
import LabeledText from '/@/components/shared/LabeledText.vue'
import SalesforceLink from '/@/components/SalesforceLink.vue'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { FinanceOrder } from '/@/app-options'

export default defineComponent({
  components: { SidebarCard, LabeledText, SalesforceLink },
  props: {
    order: {
      type: Object as PropType<FinanceOrder>,
      required: true
    }
  },
  setup(props) {
    const companyName = computed(() => {
      return props.order.carrierCompany?.name || '-'
    })
    const contactName = computed(() => {
      return props.order.carrierContact?.name || '-'
    })
    const contactPhone = computed(() => {
      return props.order.carrierContact?.phone || '-'
    })
    const contactEmail = computed(() => {
      return props.order.carrierContact?.email || '-'
    })

    const hasOrcasAccess = computed(() => {
      return props.order?.carrierCompany?.hasOrcasAccess
    })

    return {
      companyName,
      contactName,
      contactPhone,
      contactEmail,
      hasOrcasAccess
    }
  }
})
