var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    _vm._b(
      {
        attrs: {
          show: _vm.show,
          title: _vm.title,
          confirmButtonText:
            _vm.$attrs.confirmButtonText ||
            _vm.$t("finance/start-payment-modal-confirm-label"),
          cancelButtonText: _vm.$t("finance/start-payment-modal-cancel-label"),
          "data-test": "start-payment-modal",
          inPlace: ""
        },
        on: {
          confirm: function($event) {
            return _vm.$emit("confirmed")
          },
          close: _vm.close,
          cancel: _vm.close
        }
      },
      "Dialog",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }