import {
  Claim,
  ClaimType,
  ClaimStatus,
  PresignedUpload,
  ClaimDocument,
  CreateClaimFile,
  ClaimReporter,
  EditClaimParams,
  CreateClaim
} from './types'
import {
  transformKeysToSnakeCase,
  transformKeysToCamelCase
} from './casing'
import { api } from './api'
import { AxiosResponse } from 'axios'
import { ClaimBoardColumnDataAll, ClaimBoardColumnData, ClaimBoardResponse } from './types'
import { ClaimBoardColumnType } from '/@/services/types'

export const fetchShipperClaimTypes = async (orderId: number): Promise<ClaimType[]> => {
  const response = await api.get(
    '/finance/queries/get-shipper-claim-types',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  if (!response) return null
  return transformKeysToCamelCase<ClaimType[]>(response.data.claim_types)
}

export const fetchCarrierClaimTypes = async (orderId: number): Promise<ClaimType[]> => {
  const response = await api.get(
    '/finance/queries/get-carrier-claim-types',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  if (!response) return null
  return transformKeysToCamelCase<ClaimType[]>(response.data.claim_types)
}

export const createClaim = async (claim: CreateClaim) => {
  await api.post(
    '/finance/commands/create-claim',
    transformKeysToSnakeCase({ ...claim, side: claim.reporter })
  )
}

export const updateClaim = async (
  params: EditClaimParams
) => {
  const url = '/finance/commands/edit-claim'
  await api.post(url, transformKeysToSnakeCase(params))
}

export const approveClaim = async ({
  claimId,
  side,
  amount = null,
  notes
}: {
  claimId: number
  side: ClaimReporter
  amount: number
  notes: string
}) => {
  const url = '/finance/commands/set-claim-status/approved'
  const params = {
    claimId,
    finalAmount: amount,
    approvalNote: notes,
    side
  }
  return await api.post(url, transformKeysToSnakeCase(params))
}

export const rejectClaim = async ({
  claimId,
  side,
  note = ''
}: {
  claimId: number
  side: ClaimReporter
  note: string
}) => {
  const url = '/finance/commands/set-claim-status/rejected'
  const params = {
    claimId,
    rejectionNote: note,
    side
  }
  return await api.post(url, transformKeysToSnakeCase(params))
}

export type ApproveClaimPayload = {
  amount: number
  notes: string
}

export type RejectClaimPayload = {
  note: string
}

export type SetClaimStatusPayload = ApproveClaimPayload | RejectClaimPayload

export const setClaimStatus = async ({
  claimId,
  side,
  status = null,
  payload
}: {
  claimId: number
  side: ClaimReporter
  status?: ClaimStatus
  payload?: SetClaimStatusPayload
}) => {
  if (status === 'APPROVED') {
    const { amount, notes } = payload as ApproveClaimPayload
    return await approveClaim({
      claimId,
      side,
      amount,
      notes
    })
  }
  if (status === 'REJECTED') {
    const { note } = payload as RejectClaimPayload
    return await rejectClaim({
      claimId,
      side,
      note
    })
  }
  const url = `/finance/commands/set-claim-status/${status.toLowerCase()}`
  const params = {
    claimId,
    side
  }
  return await api.post(url, transformKeysToSnakeCase(params))
}

export const getClaims = async (orderId: number) => {
  const response = await api.get(
    '/finance/queries/get-claims',
    { params: transformKeysToSnakeCase({ orderId }) }
  )
  if (!response) return null
  return transformKeysToCamelCase<Claim[]>(response.data.claims)
}

export const fetchPresignedUpload = async (
  fileName: string
): Promise<PresignedUpload> => {
  const response = await api.get(
    '/finance/queries/get-claim-file-presigned-upload-url',
    { params: transformKeysToSnakeCase({ fileName }) }
  )
  if (!response) return null

  // Dont transform fields object to camelCase because those are metadata for S3
  return {
    url: response.data.url,
    fields: response.data.fields,
    uploadDirectory: response.data.upload_directory
  }
}

export const fetchClaimDocuments = async (claimId: number) => {
  const response = await api.get(
    '/finance/queries/get-claim-files',
    { params: transformKeysToSnakeCase({ claimId }) }
  )
  if (!response) return null
  return transformKeysToCamelCase<ClaimDocument[]>(response.data.claim_files)
}

export const deleteClaimFiles = async (ids: number[]) => {
  await api.post(
    '/finance/commands/delete-claim-files',
    transformKeysToSnakeCase({ claimFileIds: ids })
  )
}

export const addClaimFiles = async (claimId: number, files: CreateClaimFile[]) => {
  await api.post(
    '/finance/commands/add-claim-files',
    transformKeysToSnakeCase({ claimId, files })
  )
}

export const fetchClaimDocumentUrl = async (claimFileId: number): Promise<string> => {
  const response = await api.get(
    '/finance/queries/get-claim-file',
    { params: transformKeysToSnakeCase({ claimFileId }) }
  )
  if (!response) return null
  return response.data.url
}

export const fetchClaimBoardColumn = async (
  columnType: ClaimBoardColumnType
): Promise<ClaimBoardColumnData> => {
  const response = (await api.get('/finance/queries/get-claim-board', {
    params: transformKeysToSnakeCase({ column: columnType })
  })) as AxiosResponse<ClaimBoardResponse>
  const unifiedResponse = {
    count: response.data.count,
    nextUrl: response.data.next_url,
    previousUrl: response.data.previous_url,
    results: response.data[columnType],
  }
  return transformKeysToCamelCase<ClaimBoardColumnData>(unifiedResponse)
}

export const searchClaimBoard = async ({
  search,
  date,
}: {
  search?: string
  date?: string
}): Promise<ClaimBoardColumnDataAll> => {
  const params: {
    search?: string
    date?: string
  } = {}
  if (search) {
    const upperCasedSearch = search.toUpperCase()
    params.search = upperCasedSearch
  }
  if (date) {
    params.date = date
  }
  const response = (await api.get(
    '/finance/queries/get-claim-board',
    { params }
  )) as AxiosResponse<unknown>
  return transformKeysToCamelCase(response?.data)
}

export const filterClaimBoardByDate = async (
  date: string
): Promise<ClaimBoardColumnDataAll> => {
  const response = (await api.get(
    '/finance/queries/get-claim-board',
    { params: date },
  )) as AxiosResponse<unknown>

  return transformKeysToCamelCase(response.data)
}

export const fetchClaimBoardColumnByUrl = async (
  url: string,
  columnType: ClaimBoardColumnType
): Promise<ClaimBoardColumnData> => {
  const response = (await api.get(url)) as AxiosResponse<ClaimBoardResponse>
  const unifiedResponse = {
    count: response.data.count,
    nextUrl: response.data.next_url,
    previousUrl: response.data.previous_url,
    results: response.data[columnType],
  }
  return transformKeysToCamelCase(unifiedResponse)
}
