var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        title: _vm.$t("finance/edit-claim-modal-title"),
        confirmButtonText: _vm.$t("finance/edit-claim-modal-confirm-label"),
        cancelButtonText: _vm.$t("finance/edit-claim-modal-cancel-label"),
        inPlace: "",
        lazy: ""
      },
      on: {
        confirm: _vm.modal.confirm,
        cancel: _vm.modal.close,
        close: _vm.modal.close,
        hidden: _vm.resetState
      }
    },
    [
      _c("p", { staticClass: "subtitle" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("finance/edit-claim-modal-description")) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("ClaimSurchargeForm", {
        ref: "claimSurchargeForm",
        attrs: { side: _vm.editingSide },
        model: {
          value: _vm.claim,
          callback: function($$v) {
            _vm.claim = $$v
          },
          expression: "claim"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }