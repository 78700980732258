var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.modal.loading.value
    ? _c(
        "Dialog",
        {
          staticClass: "claim-reject-modal",
          attrs: {
            show: _vm.modal.visible.value,
            title: _vm.$t("finance/claims-reject-modal-title"),
            confirmButtonText: _vm.$t(
              "finance/claims-reject-modal-confirm-label"
            ),
            cancelButtonText: _vm.$t(
              "finance/claims-reject-modal-cancel-label"
            ),
            "data-test": "claim-reject-modal",
            inPlace: ""
          },
          on: {
            cancel: _vm.modal.close,
            close: _vm.modal.close,
            confirm: _vm.modal.confirm
          }
        },
        [
          _c("p", { staticClass: "subtitle claim-approve-modal__text" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("finance/claims-reject-modal-description")) +
                "\n  "
            )
          ]),
          _vm._v(" "),
          _c(
            "Form",
            { ref: "form", attrs: { "data-test": "claim-approve-form" } },
            [
              _c("LimitedTextAreaField", {
                staticClass: "claim-surcharge-form__note",
                attrs: {
                  title: _vm.$t(
                    "finance/claims-reject-modal-notes-field-title"
                  ),
                  placeholder: _vm.$t(
                    "finance/claims-reject-modal-notes-field-placeholder"
                  ),
                  fullWidth: "",
                  maxLength: 150,
                  convertEmptyToNull: "",
                  "data-test": "claim-reject-note"
                },
                model: {
                  value: _vm.rejectionNote,
                  callback: function($$v) {
                    _vm.rejectionNote = $$v
                  },
                  expression: "rejectionNote"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }