

















import { defineComponent, toRef, computed } from '@vue/composition-api'
import { formatDate } from '/@/filters/filters'
import { AlertMessage } from '@sennder/plankton'
export default defineComponent({
  components: { AlertMessage },
  props: {
    paymentInfo: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const paymentInfo = toRef(props, 'paymentInfo')
    const formattedPaymentStartedDate = computed(() =>
      formatDate(paymentInfo.value?.automaticPaymentStartTimestamp)
    )
    const formattedPaymentDate = computed(() =>
      formatDate(paymentInfo.value?.paymentDate)
    )
    return {
      formattedPaymentStartedDate,
      formattedPaymentDate
    }
  }
})
