
























import { defineComponent, ref, toRef } from '@vue/composition-api'
import useModal from '/@/compositions/useModal'
import useStateReset from '/@/compositions/useStateReset'
import useClaims, { SubmitClaim } from '/@/compositions/useClaims'
import { Claim, ClaimReporter, ClaimReporterLowerCase } from '/@/services/types'
import ClaimSurchargeForm from './submit-claim-forms/ClaimSurchargeForm.vue'
import { Dialog } from '@sennder/plankton'

export default defineComponent({
  components: { Dialog, ClaimSurchargeForm },
  props: {
    orderId: { type: Number, required: true }
  },
  setup(props) {
    const claim = ref<SubmitClaim>(null)
    const editingSide = ref<ClaimReporter>(null)
    const claimSurchargeForm = ref<InstanceType<typeof ClaimSurchargeForm>>(null)

    const modal = useModal({
      onConfirm: () => handleConfirm(),
      isInvalid: () => claimSurchargeForm.value.submit().hasErrors
    })
    const resetState = useStateReset([claim, editingSide])
    const { updateClaim } = useClaims(toRef(props, 'orderId'))

    const open = async (claimToEdit: Claim, side: ClaimReporter) => {
      claim.value = {
        ...claimToEdit,
        requestedAmount: claimToEdit[side.toLowerCase() as ClaimReporterLowerCase].requestedAmount,
        description: claimToEdit[side.toLowerCase() as ClaimReporterLowerCase].description
      }
      editingSide.value = side
      return modal.open()
    }

    const handleConfirm = () => {
      updateClaim(claim.value, editingSide.value)
    }

    return { open, modal, claim, editingSide, resetState, claimSurchargeForm }
  }
})
