































import { defineComponent } from '@vue/composition-api'
import { Button } from '@sennder/plankton'

export default defineComponent({
  components: { Button },
})
