import { render, staticRenderFns } from "./ClaimRejectModal.vue?vue&type=template&id=536735ec&"
import script from "./ClaimRejectModal.vue?vue&type=script&lang=ts&"
export * from "./ClaimRejectModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/sennder/operations-finance/finance/finance-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('536735ec')) {
      api.createRecord('536735ec', component.options)
    } else {
      api.reload('536735ec', component.options)
    }
    module.hot.accept("./ClaimRejectModal.vue?vue&type=template&id=536735ec&", function () {
      api.rerender('536735ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/claims/ClaimRejectModal.vue"
export default component.exports