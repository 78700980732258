var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SidebarCard", {
    staticClass: "carrier-vehicle-info",
    attrs: { "data-test": "carrier-vehicle-info" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("finance/carrier-vehicle-info-title")) +
                "\n  "
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "plankton-grid", attrs: { columns: "12" } },
              [
                _c("LabeledText", {
                  staticClass: "carrier-vehicle-info__block plankton-cell",
                  attrs: {
                    span: "4",
                    label: _vm.$t(
                      "finance/carrier-vehicle-info-license-plate-label"
                    ),
                    text: _vm.vehicleLicensePlate,
                    "data-test": "license-plate"
                  }
                }),
                _vm._v(" "),
                _c("LabeledText", {
                  staticClass: "carrier-vehicle-info__block plankton-cell",
                  attrs: {
                    span: "4",
                    label: _vm.$t(
                      "finance/carrier-vehicle-info-trailer-license-plate-label"
                    ),
                    text: _vm.trailerLicensePlate,
                    "data-test": "trailer-license-plate"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }