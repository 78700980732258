









































































































import { defineComponent, computed, PropType, ref } from '@vue/composition-api'
import {
  UnifiedCharges,
  IssueCategoryCode,
  ISSUE_CATEGORY_LABELS
} from '/@/services'
import {
  formatDate,
  formatTime,
  formatMoneyWithZeroForNull
} from '/@/filters/filters'
import BreakdownRow from './BreakdownRow.vue'
import useResizeObserver from '/@/compositions/useResizeObserver'
import { watchEffect, onMounted } from '@vue/composition-api'
import { Button, Chip } from '@sennder/plankton'

export default defineComponent({
  components: { BreakdownRow, Button, Chip },
  props: {
    closedTimestamp: { type: String, default: null },
    isClosed: { type: Boolean, default: false },
    baseAmount: { type: Number, required: true },
    totalNetAmount: { type: Number, required: true },
    totalTitle: { type: String, required: true },
    baseTitle: { type: String, required: true },
    charges: { type: Object as PropType<UnifiedCharges>, default: null },
    editable: { type: Boolean, default: false },
    title: { type: String, default: 'Charges breakdown' },
    compact: { type: Boolean, default: false },
    canAddSurcharge: { type: Boolean, default: false },
    canCloseBreakdown: { type: Boolean, default: false }
  },
  setup(props) {
    const formattedDate = computed(() => formatDate(props.closedTimestamp))
    const formattedTime = computed(() => formatTime(props.closedTimestamp))

    const baseCharge = computed(() => props.charges?.baseCharge)
    const baseChargeCorrections = computed(
      () => props.charges?.baseCharge?.chargeCorrections
    )

    const extraCharges = computed(() => props.charges?.extraCharges)
    const getIssueCategoryTitle = (code: IssueCategoryCode) => ISSUE_CATEGORY_LABELS[code]

    const breakdownModalVisible = ref(false)
    const breakdownRef = ref()

    const isCompact = ref(props.compact)
    const { resizeState } = useResizeObserver(breakdownRef)
    onMounted(() => {
      watchEffect(() => {
        const { width } = resizeState.contentRect
        isCompact.value = (props.compact || width < 540)
      })
    })

    return {
      isCompact,
      breakdownRef,
      getIssueCategoryTitle,
      formattedDate,
      formattedTime,
      baseCharge,
      baseChargeCorrections,
      extraCharges,
      formatMoneyWithZeroForNull,
      breakdownModalVisible
    }
  }
})
