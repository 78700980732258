







































import { defineComponent, PropType, ref, computed } from '@vue/composition-api'
import { ClaimReporter } from '/@/services/types'
import useModelUpdateEmitter from '/@/compositions/useModelUpdateEmitter'
import {
  applyValidatorWhen,
  requiredValidator,
  minimumCharsValidator,
  Validator,
  translateValidators
} from '/@/field-validators'
import { Form, NumericInputField, TextAreaField } from '@sennder/plankton'
import { SubmitClaim } from '../../../compositions/useClaims'

const modelOptions = { prop: 'claim', event: 'input' }
const DESCRIPTION_MIN_CHARS = 20

export default defineComponent({
  components: { Form, NumericInputField, TextAreaField },
  model: modelOptions,
  props: {
    claim: { type: Object as PropType<SubmitClaim>, required: true },
    side: {
      type: String as PropType<ClaimReporter>,
      required: true
    }
  },
  setup(props, context) {
    const form = ref(null)
    const emitValueUpdate = useModelUpdateEmitter(props, context, modelOptions)
    const submit = () => form.value.submit()
    const validateWhenTypeIsOther = <T,>(validator: Validator<T>) => {
      return applyValidatorWhen<T>(
        () => props.claim.claimType === 'OTHER',
      )(validator)
    }
    const sideLowerCase = computed(() => props.side?.toLowerCase())
    const amount = computed(() => props.claim?.requestedAmount)
    const description = computed(() => props.claim?.description)
    const $t = context.root.$t.bind(context.root)

    return {
      form,
      submit,
      emitValueUpdate,
      sideLowerCase,
      amount,
      description,
      descriptionValidators: translateValidators($t, [
        validateWhenTypeIsOther(requiredValidator),
        validateWhenTypeIsOther(minimumCharsValidator(DESCRIPTION_MIN_CHARS))
      ])
    }
  }
})
