<template>
  <div
    v-tooltip.bottom="tooltipOptions"
    class="info-indicator"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    tooltip: { type: String, default: '' }
  },
  computed: {
    tooltipOptions() {
      return { content: this.tooltip, delay: { show: 500 } }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $color-layout-white;
  border: 1px solid $color-layout-divider;
  width: 30px;
  height: 30px;
  font-size: 10px;
  font-weight: bold;
  color: $color-neutral-main;
}
</style>
