





























































import { defineComponent, ref, computed, Ref, PropType } from '@vue/composition-api'
import ClaimsBoardCard from './ClaimsBoardCard.vue'
import useClaimBoard from '/@/compositions/useClaimBoard'
import { ClaimBoardColumnData, ClaimBoardColumnType } from '/@/services/types'

export default defineComponent({
  components: {
    ClaimsBoardCard
  },
  props: {
    title: {
      type: String,
      default: 'Submitted'
    },
    colName: {
      type: String as PropType<ClaimBoardColumnType>,
      required: true
    }
  },
  setup(props) {
    const claimBoard = useClaimBoard()
    claimBoard.loadColumn(props.colName)
    const claimsData: Ref<ClaimBoardColumnData> = claimBoard[props.colName]
    const claimsList = ref(null)
    const showPagination = computed(() => {
      return claimsData.value?.count > claimsData.value?.results?.length
    })
    const pagination = claimBoard.pagination[props.colName]

    const prevPage = async () => {
      await claimBoard.prevPage(props.colName)
    }
    const nextPage = async () => {
      await claimBoard.nextPage(props.colName)
    }

    const loading = computed(() => claimBoard.loading[props.colName].value)

    return {
      loading,
      claimsData,
      pagination,
      showPagination,
      claimsList,
      prevPage,
      nextPage
    }
  }
})
