



































import SidebarCard from '/@/components/shared/SidebarCard.vue'
import LabeledText from '/@/components/shared/LabeledText.vue'
import useCarrierBilling from '../../compositions/useCarrierBilling'
import { toRef, defineComponent, PropType, Ref } from '@vue/composition-api'

export default defineComponent({
  components: { SidebarCard, LabeledText },
  props: {
    carrierId: {
      type: Number as PropType<number>,
      default: null
    }
  },
  setup(props) {
    const carrierId: Ref<number> = toRef(props, 'carrierId')
    const { billingAddress, billingEmail, ibanBic } = useCarrierBilling({
      carrierId
    })

    return {
      billingAddress,
      billingEmail,
      ibanBic
    }
  }
})
