
































import { defineComponent, PropType } from '@vue/composition-api'
export default defineComponent({
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    note: {
      type: String,
      default: ''
    },
    modifier: {
      type: String as PropType<'top' | 'correction' | 'strong'>,
      default: 'top'
    },
    hasBorder: {
      type: Boolean,
      default: true
    }
  }
})
