var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.claimInfo
    ? _c(
        "router-link",
        {
          staticClass: "claim-board-card",
          class: {
            "is-disabled": _vm.disabled,
            "is-loading": _vm.loading
          },
          attrs: { to: "/ordering/" + _vm.claimInfo.orderIdForStaff }
        },
        [
          _c("div", { staticClass: "claim-board-card__content" }, [
            _c(
              "header",
              {
                staticClass: "claim-board-card__header",
                attrs: { "data-test": "claim-board-card-header" }
              },
              [
                !_vm.isEnableClaimAccessorial
                  ? [
                      _vm._v(
                        "\n        " + _vm._s(_vm.claimInfo.id) + ".\n      "
                      )
                    ]
                  : _vm._e(),
                _vm._v(
                  " " +
                    _vm._s(_vm.claimTypeDescription) +
                    "\n      " +
                    _vm._s(
                      _vm.claimSubtypeDescription
                        ? " | " + _vm.claimSubtypeDescription
                        : ""
                    ) +
                    "\n    "
                )
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "claim-board-card__chips" },
              [
                _vm.claimInfo.createdByFullName
                  ? _c(
                      "ClaimChip",
                      {
                        staticClass: "claim-board-card__chip",
                        attrs: {
                          text:
                            _vm.$t("finance/claims-board-card-reporter-label") +
                            ": " +
                            _vm.claimInfo.createdByFullName
                        }
                      },
                      [
                        _c("Icon", {
                          staticClass: "claim-board-card__chip-icon",
                          attrs: { name: "user" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.claimInfo.created
                  ? _c(
                      "ClaimChip",
                      {
                        staticClass: "claim-board-card__chip",
                        attrs: {
                          text:
                            _vm.$t("finance/claims-board-card-date-label") +
                            ": " +
                            _vm.dateFromIsoString(_vm.claimInfo.created)
                        }
                      },
                      [
                        _c("Icon", {
                          staticClass: "claim-board-card__chip-icon",
                          attrs: { name: "calendar" }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.claimInfo.filesCount > 0
                  ? _c(
                      "ClaimChip",
                      {
                        staticClass: "claim-board-card__chip",
                        attrs: {
                          text: "" + _vm.claimInfo.filesCount,
                          "data-test": "claim-chip-files"
                        }
                      },
                      [
                        _c("Icon", {
                          staticClass: "claim-board-card__chip-icon",
                          staticStyle: { "stroke-width": "1.5px" },
                          attrs: { name: "fileText" }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "claim-board-card__info" }, [
              _c(
                "div",
                { staticClass: "claim-board-card__side paragraph" },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("finance/claims-board-card-shipper-label")
                          ) +
                          ":\n          "
                      ),
                      _vm.isEnableClaimAccessorial
                        ? [
                            _c(
                              "span",
                              { staticClass: "claim-board-card__surcharge" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatMoneyWithDashForNull(
                                        _vm.claimInfo.shipper.requestedAmount
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        : [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.formatMoneyWithZeroForNull(
                                    _vm.claimInfo.shipper.requestedAmount
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isEnableClaimAccessorial
                    ? [
                        _c("Chip", {
                          attrs: {
                            text: _vm.shipperStatusLabel,
                            category: _vm.getCategoryByStatus(
                              _vm.claimInfo.shipper.status
                                ? _vm.claimInfo.shipper.status
                                : "NOT_SUBMITTED"
                            )
                          }
                        })
                      ]
                    : [
                        _vm.claimInfo.shipper.status
                          ? _c("Chip", {
                              attrs: {
                                text: _vm.shipperStatusLabel,
                                category: _vm.getCategoryByStatus(
                                  _vm.claimInfo.shipper.status
                                )
                              }
                            })
                          : _vm._e()
                      ]
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "claim-board-card__side paragraph" },
                [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t("finance/claims-board-card-carrier-label")
                          ) +
                          ":\n          "
                      ),
                      _vm.isEnableClaimAccessorial
                        ? [
                            _c(
                              "span",
                              { staticClass: "claim-board-card__surcharge" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatMoneyWithDashForNull(
                                        _vm.claimInfo.carrier.requestedAmount
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        : [
                            _c(
                              "span",
                              { staticClass: "claim-board-card__surcharge" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatMoneyWithZeroForNull(
                                        _vm.claimInfo.carrier.requestedAmount
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.isEnableClaimAccessorial
                    ? [
                        _c("Chip", {
                          attrs: {
                            text: _vm.carrierStatusLabel,
                            category: _vm.getCategoryByStatus(
                              _vm.claimInfo.carrier.status
                                ? _vm.claimInfo.carrier.status
                                : "NOT_SUBMITTED"
                            )
                          }
                        })
                      ]
                    : [
                        _vm.claimInfo.carrier.status
                          ? _c("Chip", {
                              attrs: {
                                text: _vm.carrierStatusLabel,
                                category: _vm.getCategoryByStatus(
                                  _vm.claimInfo.carrier.status
                                )
                              }
                            })
                          : _vm._e()
                      ]
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("footer", { staticClass: "claim-board-card__footer" }, [
            _c(
              "div",
              { staticClass: "claim-board-card__footer-state paragraph" },
              [
                _c("span", { staticClass: "link" }, [
                  _vm._v(_vm._s(_vm.claimInfo.orderIdForStaff))
                ]),
                _vm._v(" "),
                !_vm.isEnableClaimAccessorial
                  ? [
                      _vm._v("\n        •\n        "),
                      _c("span", [_vm._v(_vm._s(_vm.orderStateLabel))])
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            !_vm.isEnableClaimAccessorial
              ? _c(
                  "div",
                  { staticClass: "claim-board-card__indicators" },
                  [
                    _vm.fullName(_vm.accountManager)
                      ? _c(
                          "InfoIndicator",
                          {
                            attrs: {
                              tooltip:
                                _vm.$t(
                                  "finance/claims-board-card-account-manager-label"
                                ) +
                                ": " +
                                _vm.fullName(_vm.accountManager)
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.initials(_vm.accountManager)) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fullName(_vm.carrierManager)
                      ? _c(
                          "InfoIndicator",
                          {
                            attrs: {
                              tooltip:
                                _vm.$t(
                                  "finance/claims-board-card-carrier-manager-label"
                                ) +
                                ": " +
                                _vm.fullName(_vm.carrierManager)
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.initials(_vm.carrierManager)) +
                                "\n      "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }