import { ref, onBeforeUnmount, computed } from '@vue/composition-api'
import { DropzoneFile } from 'dropzone'
import omit from 'lodash/omit'

const byId = (id: string) => (f: FileUpload) => f.id === id
const byNotId = (id: string) => (f: FileUpload) => f.id !== id

export interface FileUpload {
  id: string
  name: string
  sizeBytes: number
  uploadedDateTime: Date
  error: string
}
export interface FileWithDirectory extends FileUpload {
  uploadDirectory: string
}

export interface InProgressFileUpload extends FileUpload {
  uploadedBytes: number
  cancel(): void
}

// Extended Dropzone object generated from Plankton
export interface ExtendedDropzoneFile extends DropzoneFile {
  cancelUpload(): void
}

/**
 * Composition function for managing the state of file uploads.
 * It exports functions to track files uploaded, progress, upload errors, and reports successful or failed uploads.
 * Components need to bind the `inProgressFiles` array to its view to show updates on the upload processes.
 *
 * @param {Function} options.onFileUploaded - Callback to emit successful or failed uploads.
 */
export default (options: { onFileUploaded: (file: FileUpload) => void }) => {
  const inProgressFiles = ref<InProgressFileUpload[]>([])
  const isUploading = computed(() => inProgressFiles.value.length > 0)

  const find = (id: string) => {
    return inProgressFiles.value.find(byId(id))
  }

  const addFile = (file: ExtendedDropzoneFile) => {
    const newInprogress = createUploadingFile(file)
    inProgressFiles.value = [...inProgressFiles.value, newInprogress]
  }

  const removeFile = (file: InProgressFileUpload) => {
    inProgressFiles.value = inProgressFiles.value.filter(byNotId(file.id))
    file.cancel()
  }

  const handleFileError = (file: DropzoneFile, error: string) => {
    const inProgressFile = find(file.upload.uuid)
    if (!inProgressFile) return

    inProgressFile.error = error
    inProgressFile.uploadedDateTime = new Date()
    emitNewFile(inProgressFile)
  }

  const handleUploadProgress = (
    file: DropzoneFile,
    _progress: number,
    bytesSent: number
  ) => {
    const inProgressFile = find(file.upload.uuid)
    inProgressFile.uploadedBytes = bytesSent
  }

  const handleUploadSuccess = (file: DropzoneFile) => {
    const newFile = find(file.upload.uuid)
    newFile.uploadedDateTime = new Date()
    emitNewFile(newFile)
  }

  const emitNewFile = (file: InProgressFileUpload) => {
    removeFile(file)
    options.onFileUploaded(omit(file, ['uploadedBytes', 'cancel']))
  }

  const createUploadingFile = (file: ExtendedDropzoneFile): InProgressFileUpload => {
    return {
      id: file.upload.uuid,
      name: file.upload.filename,
      sizeBytes: file.size,
      uploadedDateTime: null,
      error: null,
      uploadedBytes: 0,
      cancel: file.cancelUpload
    }
  }

  // Cancel in progress uploads before component is destroyed.
  onBeforeUnmount(() => inProgressFiles.value.forEach(f => f.cancel()))

  return {
    inProgressFiles,
    isUploading,
    addFile,
    removeFile,
    handleFileError,
    handleUploadProgress,
    handleUploadSuccess
  }
}
