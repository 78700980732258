var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SidebarCard", {
    staticClass: "load-info",
    attrs: { "data-test": "load-info" },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("finance/load-info-title")) + "\n  "
            )
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c("LabeledText", {
              staticClass: "load-info__block",
              attrs: {
                label: _vm.$t("finance/load-info-especifications-label"),
                text: _vm.specificationsString,
                "data-test": "load-specifications"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }