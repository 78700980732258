import { ref, Ref } from '@vue/composition-api'
import {
  fetchOrderPaymentInformation,
} from '/@/services/finance-service'
import { OrderPaymentInformation } from '/@/services/types'
import { appOptions } from '/@/app-options'

const paymentInfo: Ref<OrderPaymentInformation> = ref(null)
export default (orderId: Ref<number>) => {
  const fetchPaymentInformation = async (): Promise<void> => {
    paymentInfo.value = await fetchOrderPaymentInformation(orderId.value)
  }

  const closeCustomerPrice = async (orderId: number) => {
    await appOptions.orderingService.closeCustomerPrice(orderId)
    await fetchPaymentInformation()
  }

  const closeCarrierCost = async (orderId: number) => {
    await appOptions.orderingService.closeCarrierCost(orderId)
    await fetchPaymentInformation()
  }

  return {
    paymentInfo,
    fetchPaymentInformation,
    closeCustomerPrice,
    closeCarrierCost
  }
}
