































































import {
  defineComponent,
  PropType,
  computed,
  Ref,
  toRef,
} from '@vue/composition-api'
import {
  Claim,
  ClaimReporter,
  ClaimStatus
} from '/@/services'
import ClaimCard from './ClaimCard.vue'
import { humanizeIsoDateTimeWithDots } from '@sennder/plankton'
import { isClaimStatusWIP, reporterTranslationKeys, faultyPartyTranslationKeys, ClaimTypeTranslationKeys, claimSubtypeTranslationKeys } from '/@/compositions/useClaims'
import { Button } from '@sennder/plankton'
import { appOptions } from '/@/app-options'
import { statusTranslationKeys } from '/@/compositions/useClaims'

export default defineComponent({
  components: {
    Button,
    ClaimCard
  },
  props: {
    claim: {
      type: Object as PropType<Claim>,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const claim: Ref<Claim> = toRef(props, 'claim')
    const claimTypeDescription = root.$t(ClaimTypeTranslationKeys[claim.value.claimType])
    const claimSubtypeDescription = root.$t(
      claimSubtypeTranslationKeys[claim.value.claimSubtype],
      { stopoverDescription: claim.value.stopoverDescription }
    )
    const title = computed(() => {
      let cardTitle = `${claim.value.id}. ${claimTypeDescription}`
      if (claimSubtypeDescription) cardTitle += ` | ${claimSubtypeDescription}`
      return cardTitle
    })
    const { currentUserIsAccountManager, currentUserIsPartnerManager } = appOptions.useCurrentUser()
    const headerInfo = computed(() => {
      if (!claim.value) return ''

      const reporterDescription = root.$t(reporterTranslationKeys[claim.value.reporter])
      const faultyPartyDescription = root.$t(faultyPartyTranslationKeys[claim.value.faultyParty])
      const dataToDisplay = [
        [root.$t('finance/claim-panel-reporter-label'), reporterDescription],
        [root.$t('finance/claim-panel-faulty-party-label'), faultyPartyDescription],
        [root.$t('finance/claim-panel-incident-time-label'), humanizeIsoDateTimeWithDots(claim.value.incidentTime)]
      ]
      return dataToDisplay.map(pair => pair.join(': ')).join(' | ')
    })

    const carrierActionName = computed<string>(() => {
      if (!props.editable) return null
      if (
        (currentUserIsPartnerManager.value || currentUserIsAccountManager.value) &&
        isClaimStatusWIP(claim.value.carrier.status)
      ) {
        return root.$t('finance/claim-panel-edit-label')
      }
      return null
    })
    const shipperActionName = computed<string>(() => {
      if (!props.editable) return null
      if (
        currentUserIsAccountManager.value &&
        isClaimStatusWIP(claim.value.shipper.status)
      ) {
        return root.$t('finance/claim-panel-edit-label')
      }
      return null
    })

    const actionHandler = (side: ClaimReporter, action: string) => {
      // TODO: refactor code to avoid depending on text content.
      if (action === root.$t('finance/claim-panel-edit-label')) {
        emit('edit-claim', {
          claim: claim.value,
          side
        })
      }
    }

    const updateStatus = (side: ClaimReporter, status: ClaimStatus) => {
      emit('update-status', {
        side,
        status
      })
    }

    const shipperNotes = computed(() => {
      return claim.value.shipper.approvalNote || claim.value.shipper.rejectionNote
    })

    const carrierNotes = computed(() => {
      return claim.value.carrier.approvalNote || claim.value.carrier.rejectionNote
    })

    return {
      shipperNotes,
      carrierNotes,
      carrierActionName,
      shipperActionName,
      title,
      headerInfo,
      updateStatus,
      actionHandler,
      statusTranslationKeys
    }
  }
})
