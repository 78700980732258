import { OrderState, FinanceOrder, appOptions  } from '/@/app-options'
import { computed } from '@vue/composition-api'

const STATES_ALLOWING_CANCELLATION: OrderState[] = [
  'REGISTERED',
  'CARRIER_LOCKED',
  'DISPATCHED',
  'OPERATIONS_COMPLETED'
]

export default () => {
  const orderingService = appOptions.orderingService
  const { order, loadOrderDetail } = appOptions.useOrder()

  const canCancelOrder = (order: FinanceOrder) => {
    return !order.isCancelled && STATES_ALLOWING_CANCELLATION.includes(order.state)
  }

  const canCancelCurrentOrder = computed(() => canCancelOrder(order.value))

  const cancelCurrentOrder = async () => {
    const { id, state } = order.value
    const cancelService = getCancelOrderService(state)
    await cancelService(id)
    await loadOrderDetail()
  }

  const getCancelOrderService = (orderState: OrderState) => {
    return orderState === 'OPERATIONS_COMPLETED'
      ? orderingService.cancelOpsCompletedOrder
      : orderingService.cancelNotOpsCompletedOrder
  }

  return { canCancelOrder, canCancelCurrentOrder, cancelCurrentOrder }
}
