import { fetchCarrierBillingInformation } from '/@/services/finance-service'
import { ref, computed, onBeforeMount, Ref } from '@vue/composition-api'
import { CarrierBilling } from '/@/services/types'

export default function useCarrierBilling({ carrierId }: { carrierId: Ref<number> }) {
  const billingInfo: Ref<CarrierBilling> = ref(null)

  onBeforeMount(async () => {
    if (carrierId.value === null) return
    billingInfo.value = await fetchCarrierBillingInformation(carrierId.value)
  })

  const billingAddress = computed(() => {
    if (!billingInfo.value) return '-'
    const { streetAndNumber, locality, postalCode, country } = billingInfo.value
    if (streetAndNumber && locality && postalCode && country) {
      return `${streetAndNumber}, ${locality} ${postalCode}, ${country}`
    }
    return '-'
  })
  const billingEmail = computed(() => {
    return billingInfo.value?.billingEmail || '-'
  })
  const ibanBic = computed(() => {
    return (
      [billingInfo.value?.iban, billingInfo.value?.bic].filter(Boolean).join('/') || '-'
    )
  })

  return {
    billingInfo,
    billingAddress,
    billingEmail,
    ibanBic
  }
}
