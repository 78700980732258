











































































































































import { defineComponent, PropType, computed } from '@vue/composition-api'
import {
  GetClaimBoardClaimInfo,
  ClaimStatus
} from '/@/services/types'
import ClaimChip from './ClaimChip.vue'
import InfoIndicator from './InfoIndicator.vue'
import { ORDER_STATUSES } from '/@/constants'
import { initials, fullName } from '/@/filters/person-filters'
import {formatMoneyWithDashForNull, formatMoneyWithZeroForNull} from '/@/filters/filters'
import { dateFromIsoString, Chip, Icon } from '@sennder/plankton'
import { ClaimTypeTranslationKeys, claimSubtypeTranslationKeys } from '/@/compositions/useClaims'
import getAlphabetEquivalent from '/@/compositions/useStopLetter'
import {appOptions} from '/@/app-options'

const getCategoryByStatus = (status: ClaimStatus) => {
  switch (status) {
    case 'SUBMITTED':
      return 'secondary'
    case 'PENDING':
    case 'MORE_INFORMATION_REQUIRED':
      return 'primary'
    case 'MORE_INFO_REQUIRED':
      return 'primary'
    case 'APPROVED':
      return 'green'
    case 'REJECTED':
      return 'red'
    case 'NOT_SUBMITTED':
      return 'neutral'
    default:
      return 'secondary'
  }
}

const claimStatusTranslationKeys = {
  'SUBMITTED': 'finance/claims-board-card-status-submitted',
  'PENDING': 'finance/claims-board-card-status-pending',
  'APPROVED': 'finance/claims-board-card-status-approved',
  'REJECTED': 'finance/claims-board-card-status-rejected',
  'MORE_INFORMATION_REQUIRED': 'finance/claims-board-card-status-info-requested',
  'MORE_INFO_REQUIRED': 'finance/claims-board-card-status-info-requested',
  'NOT_SUBMITTED': 'finance/claims-board-card-status-info-not-submitted'

} as Record<ClaimStatus, string>

export default defineComponent({
  components: {
    ClaimChip,
    InfoIndicator,
    Chip,
    Icon
  },
  props: {
    claimInfo: {
      type: Object as PropType<GetClaimBoardClaimInfo>,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const carrierManager = computed(() => ({
      firstName: props.claimInfo.partnerManagerFirstName,
      lastName: props.claimInfo.partnerManagerLastName
    }))
    const accountManager = computed(() => ({
      firstName: props.claimInfo.accountManagerFirstName,
      lastName: props.claimInfo.accountManagerLastName
    }))
    const orderStateLabel = computed(
      () => ORDER_STATUSES[props.claimInfo.orderState].label
    )
    const carrierStatusLabel = computed(
      () => getClaimStatusLabel(props.claimInfo.carrier.status)
    )
    const shipperStatusLabel = computed(
      () => getClaimStatusLabel(props.claimInfo.shipper.status)
    )
    const claimTypeDescription = computed(
      () => context.root.$t(ClaimTypeTranslationKeys[props.claimInfo.claimType])
    )
    const claimSubtypeDescription = computed(() => {
      if (isEnableClaimAccessorial.value && props.claimInfo.claimType === 'WAITING_TIME') {
        return getWaitingTimeSubtype(props.claimInfo)
      }
      return context.root.$t(
          claimSubtypeTranslationKeys[props.claimInfo.claimSubtype],
          { stopoverDescription: props.claimInfo.stopoverDescription }
      )
    })

    const isEnableClaimAccessorial = computed(() => {
      return appOptions.useFeatureFlag().isActive('ENABLE_CLAIM_ACCESSORIAL').value
    })

    const getWaitingTimeSubtype = (claim: GetClaimBoardClaimInfo) => {
      const { stop: { index, countryCode, postalCode } } = claim
      return `Stop ${getAlphabetEquivalent(index)} (${countryCode}-${postalCode})`
    }

    const getClaimStatusLabel = (status: ClaimStatus) => {
      if (isEnableClaimAccessorial.value) {
        return status ?
            context.root.$t(claimStatusTranslationKeys[status]) :
            context.root.$t(claimStatusTranslationKeys['NOT_SUBMITTED'])
      }
      return context.root.$t(claimStatusTranslationKeys[status])
    }

    return {
      orderStateLabel,
      carrierManager,
      accountManager,
      initials,
      fullName,
      formatMoneyWithZeroForNull,
      formatMoneyWithDashForNull,
      dateFromIsoString,
      getCategoryByStatus,
      carrierStatusLabel,
      shipperStatusLabel,
      claimTypeDescription,
      claimSubtypeDescription,
      isEnableClaimAccessorial
    }
  }
})
