var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "cost-centre-faq-modal",
      attrs: {
        show: _vm.modal.visible.value,
        disabled: _vm.modal.loading.value,
        title: _vm.$t("finance/cost-centre-faq-modal-title"),
        cancelButtonText: _vm.$t("finance/cost-centre-faq-modal-cancel-button"),
        inPlace: ""
      },
      on: {
        confirm: _vm.modal.confirm,
        cancel: _vm.modal.close,
        close: _vm.modal.close,
        hidden: _vm.resetState
      }
    },
    _vm._l(_vm.FAQs, function(faq, index) {
      return _c(
        "div",
        {
          key: index,
          class: {
            "cost-centre-faq-modal__faq": true,
            "cost-centre-faq-modal__faq--open": _vm.openFAQ === index
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "cost-centre-faq-modal__title",
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.expandFAQ(index)
                }
              }
            },
            [
              _c("h2", { staticClass: "cost-centre-faq-modal__question" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(index + 1) +
                    ". " +
                    _vm._s(faq.question) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("Icon", {
                staticClass: "cost-centre-faq-modal__expand-icon",
                attrs: { materialIcon: "expand_more" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "cost-centre-faq-modal__answer" }, [
            _vm._v("\n      " + _vm._s(faq.answer) + "\n    ")
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "cost-centre-faq-modal__separator" })
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }