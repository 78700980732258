import {GetClaimAccessorialsBoardClaimInfoResponse} from '/@/services/types'

export const transformClaims = (claims: GetClaimAccessorialsBoardClaimInfoResponse[]) => {
  return claims.map((claim: GetClaimAccessorialsBoardClaimInfoResponse) => {
    return {
      ...claim,
      shipper: {
        requested_amount: claim.shipper?.requested_amount?.value || null,
        final_amount: claim.shipper?.final_amount?.value,
        status: claim.shipper?.status,
        currency: claim.shipper?.requested_amount?.currency
      },
      carrier: {
        requested_amount: claim.carrier?.requested_amount?.value || null,
        final_amount: claim.carrier?.final_amount?.value,
        status: claim.carrier?.status,
        currency: claim.carrier?.requested_amount?.currency
      }
    }
  })
}
