<template>
  <div
    :span="span"
    class="labeled-text"
    :class="{ 'plankton-cell': span }"
  >
    <p
      v-if="label"
      class="paragraph labeled-text__label"
    >
      {{ label }}
    </p>
    <p
      v-if="text || $slots.default"
      class="subtitle labeled-text__text"
      data-test="labeled-text--text"
    >
      <slot>{{ text }}</slot>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined },
    text: { type: String, default: undefined },
    span: { type: String, default: undefined }
  }
}
</script>

<style lang="scss" scoped>
.labeled-text {
  &__label {
    margin-bottom: 2px;
    color: $color-neutral-main;
  }

  &__text {
    margin-bottom: 0;
  }
}
</style>
