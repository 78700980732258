









































import { defineComponent, ref } from '@vue/composition-api'
import { useModal, useStateReset } from '/@/compositions'
import { Dialog, Icon } from '@sennder/plankton'

export default defineComponent({
  components: { Dialog, Icon },
  setup(_props, context) {
    const FAQCount = 5
    const FAQs = new Array(FAQCount).fill(0).map((_, index) => {
      return {
        question: context.root.$t(`finance/cost-centre-faq-modal-faq-${index + 1}-question`),
        answer: context.root.$t(`finance/cost-centre-faq-modal-faq-${index + 1}-answer`)
      }
    })
    const openFAQ = ref<number>(null)
    const modal = useModal()
    const resetState = useStateReset({ openFAQ })
    const expandFAQ = (index: number) => {
      openFAQ.value = (openFAQ.value === index ? null : index)
    }

    return { modal, FAQs, openFAQ, resetState, expandFAQ }
  },
})
