import { render, staticRenderFns } from "./CostCentreOrder.vue?vue&type=template&id=676372f2&scoped=true&"
import script from "./CostCentreOrder.vue?vue&type=script&lang=ts&"
export * from "./CostCentreOrder.vue?vue&type=script&lang=ts&"
import style0 from "./CostCentreOrder.vue?vue&type=style&index=0&id=676372f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676372f2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/sennder/operations-finance/finance/finance-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('676372f2')) {
      api.createRecord('676372f2', component.options)
    } else {
      api.reload('676372f2', component.options)
    }
    module.hot.accept("./CostCentreOrder.vue?vue&type=template&id=676372f2&scoped=true&", function () {
      api.rerender('676372f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cost-centre/CostCentreOrder.vue"
export default component.exports