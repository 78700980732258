








import { computed, defineComponent } from '@vue/composition-api'
import LabeledText from './LabeledText.vue'

export default defineComponent({
  components: { LabeledText },
  props: {
    label: { type: String, default: null },
    marginPercentage: { type: Number, default: null }
  },
  setup(props) {
    const formattedMarginPercentage = computed(() => {
      const sign = props.marginPercentage > 0 ? '+' : ''
      return props.marginPercentage || props.marginPercentage === 0
        ? `${sign}${Math.round(props.marginPercentage)}%`
        : '-'
    })
    const marginPercentageClass = computed(() => {
      if (props.marginPercentage === null) {
        return ''
      }
      const roundedPercentage = Math.round(props.marginPercentage)
      if (roundedPercentage >= 5) {
        return 'margin-ok'
      }
      if (roundedPercentage > 0) {
        return 'margin-warning'
      }
      return 'margin-error'
    })

    return { formattedMarginPercentage, marginPercentageClass }
  }
})
